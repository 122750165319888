import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from './locales/en/translation.json';
import lt from './locales/lt/translation.json';
import lv from './locales/lv/translation.json';
import et from './locales/et/translation.json';
import ru from './locales/ru/translation.json';

// WARNING
// IF YOU EVER UNCOMMENT THIS, MAKE SURE THE WINDOW USAGES ARE SAFE - THE CODE FOR THIS APPLICATION RUNS ON THE SERVER AS WELL.

//i18next.on('languageChanged', function (lng) {    
//   // i18next.changeLanguage(lng);
//    console.log('test');
//    console.log(lng);
//    language = lng;
//   // i18next.changeLanguage(lng);
//   // i18next.lng = lng;
//    // if the language we switched to is the default language we need to remove the /en from URL
//    //if (lng === i18n.options.fallbackLng[0]) {
//    //    if (window.location.pathname.includes('/' + i18n.options.fallbackLng[0])) {
//    //        const newUrl = window.location.pathname.replace('/' + i18n.options.fallbackLng[0], '')
//    //        window.location.replace(newUrl)
//    //    }
//    //} 

//i18next.on('languageChanged', (lng) => setLng(`${lng}@${Date.now()}`));
//i18next.on('loaded', () => setLng(`${lng.split('@')[0]}@${Date.now()}`));

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    resources: {
      en,
      lt,
      lv,
      et,
      ru
    },
    react: {
      bindI18n: 'loaded languageChanged',
      bindI18nStore: 'added',
      useSuspense: true,
    },
    //transSupportBasicHtmlNodes: true,
    // interpolation: {
    //     escapeValue: false,
    //   }
  });

export default i18next;