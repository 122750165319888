import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/components/Footer.jsx";
import ConsentLinks from "../components/Consents/ConsentLinks.jsx";
import TranslationText from "../components/Translation/TranslationText.jsx";
import { Trans, withTranslation } from 'react-i18next';
import RimiLogoHeaderButton from "../components/HeaderComponents/RimiLogoHeaderButton.jsx";
import AutoHideableHeader from "../components/HeaderComponents/AutoHideableHeader.jsx";
import StepBackHeader from "../components/HeaderComponents/StepBackHeader.jsx";
import Tippy from "@tippyjs/react";

class ExistingCard extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="card">
            <AutoHideableHeader>
              <RimiLogoHeaderButton initialData={this.props.initialData} />
              <StepBackHeader
                pathName="/login"
                buttonText="step_back_button_label" />
            </AutoHideableHeader>
            <div className="card-content no-padding-bottom">
              <div className="form-group">
                <div className="boolean-dialog">
                  <div className="form-col">
                    <Tippy content={<TranslationText text="existing_card_page_virtual_card_option_tooltip" />}>
                      <span className="help-tip">
                        <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" transform="translate(1 1)"><g stroke="currentColor" strokeWidth="2"><path d="m11.9497609 2.0502513c2.7336704 2.73367044 2.7336704 7.16583913 0 9.8994913-2.73367047 2.7336704-7.16583916 2.7336704-9.89949133 0-2.73367044-2.73367043-2.73367044-7.16583912 0-9.8994913 2.73367043-2.73367043 7.16583913-2.73367043 9.89949133 0" /><path d="m7 8.82608696v-.48695653c0-.63913043.33478261-1.24782608.91304348-1.58260869.88260869-.51739131 1.18695652-1.61304348.66956522-2.49565217-.51739131-.8826087-1.61304348-1.18695653-2.49565218-.66956522-.54782609.33478261-.91304348.91304348-.91304348 1.58260869" /></g><path d="m7.82533029 10.0601891c.43297264.4329726.43297264 1.1349602 0 1.5679334-.43297265.4329726-1.13496021.4329726-1.5679334 0-.43297265-.4329727-.43297265-1.1349602 0-1.5679334.43297264-.43297268 1.1349602-.43297268 1.5679334 0" fill="#a4afb3" /></g></svg>
                      </span>
                    </Tippy>
                    <Link className="button -secondary" to={{ pathname: '/registration/NoAccount/CreateNewMyRimiAccount' }}>
                      <Trans i18nKey="existing_card_page_virtual_card_option"
                        components={{ bold: <strong/> }}>
                        <bold>I do not have</bold> My Rimi plastic card
                      </Trans>
                    </Link>
                  </div>
                  <div className="form-col">
                    <Tippy content={<TranslationText text="existing_card_page_plastic_card_option_tooltip" />}>
                      <span className="help-tip">
                        <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" transform="translate(1 1)"><g stroke="currentColor" strokeWidth="2"><path d="m11.9497609 2.0502513c2.7336704 2.73367044 2.7336704 7.16583913 0 9.8994913-2.73367047 2.7336704-7.16583916 2.7336704-9.89949133 0-2.73367044-2.73367043-2.73367044-7.16583912 0-9.8994913 2.73367043-2.73367043 7.16583913-2.73367043 9.89949133 0" /><path d="m7 8.82608696v-.48695653c0-.63913043.33478261-1.24782608.91304348-1.58260869.88260869-.51739131 1.18695652-1.61304348.66956522-2.49565217-.51739131-.8826087-1.61304348-1.18695653-2.49565218-.66956522-.54782609.33478261-.91304348.91304348-.91304348 1.58260869" /></g><path d="m7.82533029 10.0601891c.43297264.4329726.43297264 1.1349602 0 1.5679334-.43297265.4329726-1.13496021.4329726-1.5679334 0-.43297265-.4329727-.43297265-1.1349602 0-1.5679334.43297264-.43297268 1.1349602-.43297268 1.5679334 0" fill="#a4afb3" /></g></svg>
                      </span>
                    </Tippy>
                    <Link className="button -secondary" to={{ pathname: '/registration/HasAccount/EnterExistingCard' }}>
                      <Trans i18nKey="existing_card_page_plastic_card_option"
                        components={{ bold: <strong /> }}>
                        <bold>I have</bold> My Rimi plastic card
                      </Trans>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <footer className="card-footer no-border">
              <div className="footer-text">
                <ConsentLinks />
              </div>
            </footer>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(ExistingCard);


