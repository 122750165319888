export function resolveVerificationCodeInput(e) {
  let value = e.value.replace(/[^0-9]/g, '0').charAt(0);
  let inputValue = value === '' ? '0' : value;
  let fieldIntIndex = parseInt(e.prop.slice(-1));

  if (value.length > 0) {
    if (fieldIntIndex < 4) {
      fieldIntIndex = fieldIntIndex + 1;
    }
  }
  refocusInput(fieldIntIndex);

  return inputValue;
}

function refocusInput(index) {
  let name = 'input[name="field-' + index + '"]';
  const input = document.querySelector(name);
  if (input !== null) {
    input.focus();
    input.select();
  }
}