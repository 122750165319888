import React, { Component } from "react";
import fetcherApi from '../../components/FetcherApi.jsx';
import InputText from "../../components/InputText.jsx";
import validator from "../../components/FieldValidator.jsx";
import "regenerator-runtime/runtime";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer.jsx"
import ConfirmationModalDialog from "../../components/ConfirmationModalDialog.jsx";
import Spinner from "../Information/Spinner.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import i18n from "translations/i18n.jsx";
import { withTranslation } from "react-i18next";
import AutoHideableTitle from "../../components/HeaderComponents/AutoHideableTitle.jsx";
import urlTools from "../../tools/urlTools.jsx";

class SeniorsClub extends Component {

  state = {
    errors: {},
    dirtyfields: {},
    redirectBack: false,
    seniorIdNumber: {},
    seniorsClub: false,
    showPopup: false,
    loaded: false
  };

  setRedirectBack = () => {
    this.props.resetRedirects();

    event.preventDefault();
    event.stopPropagation();

    this.setState({
      redirectBack: true
    })
  }

  showPopup = () => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showPopup: true })
  }

  hidePopup = () => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showPopup: false })
  }

  async componentDidMount() {
    const result = (await fetcherApi.get(urlTools.constructRoute("api/profile/Clubs/seniors/"))).response.data;
    this.setState({ seniorsClub: result.resultObject, loaded: true });
  }

  onChange = async e => {
    const { seniorIdNumber } = this.state
    seniorIdNumber.value = e.value;

    this.setState({ seniorIdNumber });
  };

  hasErrors = () => {
    const { errors } = this.state;

    for (var error in errors) {
      if (errors[error] && errors[error].length) {
        return true;
      }
    }
    return false;
  };

  leaveClub = async (e) => {

    await fetcherApi.delete(urlTools.constructRoute("api/profile/Clubs/seniors/"));
    this.setState({ showPopup: false, seniorsClub: false, seniorIdNumber: {} })
  };

  save = async e => {
    e.preventDefault();

    const { seniorIdNumber, errors } = this.state;

    errors['seniorIdNumber'] = validator.validateSeniorIdNumber(seniorIdNumber.value);
    this.setState({ errors });

    if (this.hasErrors()) {
      return;
    }

    const joinResult = await fetcherApi.post(`${urlTools.constructRoute("api/profile/Clubs/seniors/")}${seniorIdNumber.value}`);
    console.log(joinResult)
    this.setState({ seniorsClub: true, seniorIdNumber: {} })
  };

  render() {
    const { seniorsClub, seniorIdNumber, loaded, errors } = this.state;

    return loaded ? (

      <React.Fragment>
        <div className="container page-container club-page senior-club-page">

          <div>
            {(this.state.showPopup) ?
              <ConfirmationModalDialog
                titleText={"senior_club_popup_title"}
                bodyText={"senior_club_popup_info"}
                yesButtonText={"pets_club_popup_page_yes_btn"}
                noButtonText={"pets_club_popup_page_no_btn"}
                onYesButtonClick={this.leaveClub.bind(this)}
                hidePopup={this.hidePopup.bind(this)} />
              : null}
          </div>

          <Link className="link-button -with-left-icon" to={{ pathname: '/clubs' }}><svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m15 6-6 6 6 6" fill="none" stroke="#a12971" strokeWidth="2" /></svg>
            <TranslationText text='back_to_clubs_btn_label' />
          </Link>
          <AutoHideableTitle text={'senior_club_page_title'} />
          <div className="card card-full-width">
            <div className="card-content">
              <div className="club-header">
                <div className="icon">
                  <img src={require("assets/img/senior-club.jpg")} alt="senior-club" />
                </div>
                <div className="page-description-row">
                  <p>
                    <TranslationText text='senior_club_page_info_title' />
                  </p>
                  <p>
                    <TranslationText text='senior_club_page_info' />
                  </p>
                </div>
              </div>

              <hr className="no-margin-top" />

              {seniorsClub ? (<div className="leave-club">
                <p>
                  <a onClick={this.showPopup} href="#">
                    <TranslationText text='senior_club_page_leave_club_btn' />
                  </a>
                </p>
                <p>
                  <TranslationText text='senior_club_page_leave_club_info' />
                </p>
              </div>
              ) :
                (
                  <div className="group-row">
                    <div className="row varied-label-height">
                      <div className="column-desktop-5 column-mobile-12 column-tablet-12 column-min-width-2">
                        <div className={errors['seniorIdNumber'] !== undefined && errors['seniorIdNumber'].length > 0 ? "form-field  -has-error" : "form-field"} >
                          <label htmlFor="seniorIdNumber" className="form-field__label">
                            <TranslationText text='senior_club_page_id_field_title' />
                          </label>
                          <InputText
                            onChange={this.onChange}
                            prop="seniorIdNumber"
                            value={seniorIdNumber.value}
                            maxLength="10"
                            placeholder={i18n.t('senior_club_page_number_field_hint')}
                          />
                        </div>
                      </div>

                      <div className="column-desktop-3 column-tablet-12 column-min-width-1">
                        <button
                          type="submit"
                          onClick={this.save}
                          className="button full-width"
                        >
                          <TranslationText text='senior_club_page_join_btn' />
                        </button>
                      </div>
                      <div className="column-desktop-3 column-tablet-12"></div>
                      <div className="sublabel error-msg show">
                        <span>
                          <TranslationText text={errors.seniorIdNumber} />
                        </span>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>
    ) : <Spinner />
  }
}

export default withTranslation()(SeniorsClub);