import React, { Component } from "react";

class Spinner extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="main-wrapper show-spinner">
          <div className="loading-spinner-container">
            <div className="loading-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Spinner;