import React, { Component } from "react";
import fetcherApi from '../../components/FetcherApi.jsx';
import "regenerator-runtime/runtime";
import {
  BrowserRouter as Router,
  Link,
} from "react-router-dom";
import Footer from "../../components/Footer.jsx"
import ConfirmationModalDialog from "../../components/ConfirmationModalDialog.jsx";
import Spinner from "../../profile/Information/Spinner.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import AutoHideableTitle from "../../components/HeaderComponents/AutoHideableTitle.jsx";
import urlTools from "../../tools/urlTools.jsx";

class WineClub extends Component {

  state = {
    errors: {},
    dirtyfields: {},
    redirectBack: false,
    wineClub: false,
    showPopup: false,
    loaded: false,
    forbidden: false
  };

  setRedirectBack = () => {
    this.props.resetRedirects();

    event.preventDefault();
    event.stopPropagation();

    this.setState({
      redirectBack: true
    })
  }

  showPopup = () => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showPopup: true })
  }

  hidePopup = () => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showPopup: false })
  }

  async componentDidMount() {
    const result = (await fetcherApi.get(urlTools.constructRoute("api/profile/Clubs/Wine/wineclub"))).response.data;

    if (result.success) {
      this.setState({ wineClub: result.resultObject.wineClub, loaded: true });
    } else {
      this.setState({ loaded: true, forbidden: true });
    }
    
  }

  hasErrors = () => {
    const { errors } = this.state;

    for (var error in errors) {
      if (errors[error] && errors[error].length) {
        return true;
      }
    }
    return false;
  };

  leaveClub = async (e) => {

    await fetcherApi.post(urlTools.constructRoute("api/profile/Clubs/Wine/wineclub/" + false));
    this.setState({ showPopup: false, wineClub: false })
  };

  save = async e => {
    e.preventDefault();;
    await fetcherApi.post(urlTools.constructRoute("api/profile/Clubs/Wine/wineclub/" + true));
    this.setState({ wineClub: true })
  };

  render() {
    const { wineClub, loaded, forbidden } = this.state;
    console.log(loaded);


    return loaded ? (
      <React.Fragment>
        <div className="container page-container club-page wine-club-page">

          <div>
            {(this.state.showPopup) ?
              <ConfirmationModalDialog
                titleText={"wine_club_page_leave_club_btn"}
                bodyText={"wine_club_page_leave_club_info"}
                yesButtonText={"pets_club_popup_page_yes_btn"}
                noButtonText={"pets_club_popup_page_no_btn"}
                onYesButtonClick={this.leaveClub.bind(this)}
                hidePopup={this.hidePopup.bind(this)} />
              : null}
          </div>

          <Link className="link-button -with-left-icon" to={{ pathname: '/clubs' }}><svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m15 6-6 6 6 6" fill="none" stroke="#a12971" strokeWidth="2" /></svg>
            <TranslationText text='back_to_clubs_btn_label' />
          </Link>
          <AutoHideableTitle text={'rimi_clubs_page_wine_club_title'} />
          <div className="card card-full-width">
            <div className="card-content">

              <div className="club-header">
                <div className="icon">
                  <img src={require("assets/svg/wine-club.svg").default} alt="wine-club" />
                </div>
                <div className="page-description-row">
                  <p>
                    <TranslationText text='rimi_clubs_page_wine_club_title' />
                  </p>
                  <p>
                    <TranslationText text='rimi_clubs_page_wine_club_info' />
                  </p>
                </div>
              </div>

              <hr className="no-margin-top" />
              {
                forbidden ? (
                <div className="group-row"> 
                  <div className="error-msg show">
                     <TranslationText text='wine_club_forbidden' />
                  </div>
                </div>
                ) : (
                  wineClub ? (<div className="leave-club">
                    <p>
                      <a onClick={this.showPopup} href="#">
                        <TranslationText text='wine_club_page_leave_club_btn' />
                      </a>
                    </p>
                    <p>
                      <TranslationText text='wine_club_page_leave_club_info' />
                    </p>
                  </div>    
                  ) :
                    (
                      <div className="group-row">
                        <div className="row">
    
                          <div className="column-desktop-3 column-tablet-12">
                            <button
                              type="submit"
                              onClick={this.save}
                              disabled={this.hasErrors()}
                              className="button full-width"
                            >
                              <TranslationText text='rimi_clubs_page_join_wine_club_btn' />
    
                            </button>
                          </div>
                          <div className="column-desktop-9 column-tablet-12"></div>
                        </div>
                      </div>
                    )
                )
              }
            </div>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>
    ) : <Spinner />
  }
}

export default WineClub;