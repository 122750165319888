export default {
  TranslateHouseHoldStatus(status) {
    if (status === 'waiting') {
      return "my_rimi_group_page_owner_status";
    }
    if (status === 'member') {
      return "my_rimi_group_page_approved_status";
    }
    if (status === 'pending') {
      return "my_rimi_group_page_pending_status";
    }
    if (status === 'owner') {
      return "my_rimi_group_page_owner_status";
    }
    else {
      return status[0].toUpperCase() + status.slice(1);
    }
  }
};
