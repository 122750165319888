import React, { Component, createRef } from "react";
import InputText from "../../components/InputText.jsx";
import validator from "../../components/FieldValidator.jsx";
import fetcherApi from "../../components/FetcherApi.jsx";
import "regenerator-runtime/runtime";
import Spinner from "../../profile/Information/Spinner.jsx";
import KidsClubImage from "../../components/KidsClubImage.jsx";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer.jsx";
import ConfirmationModalDialog from "../../components/ConfirmationModalDialog.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import { withTranslation } from "react-i18next";
import i18n from "translations/i18n.jsx";
import AutoHideableTitle from "../../components/HeaderComponents/AutoHideableTitle.jsx";
import { DateInput } from '../../components/DateInput.jsx';
import urlTools from "../../tools/urlTools.jsx";
import NotificationSuccess from '../../components/Notifications/NotificationSuccess.jsx';
import * as constants from "../../constants/notificationTypes.jsx";


class KidsClub extends Component {
  constructor(props) {
    super(props);
    this.notificationPopupRef = createRef();
  }
  state = {
    newKid: {},
    dirtyfields: {},
    kids: null,
    errors: {},
    validations: {},
    showPopup: false
  };

  async componentDidMount() {
    const response = (await fetcherApi.get(urlTools.constructRoute("api/profile/Clubs/KidsClub/" + this.props.initialData.legalEntityId + "/kids"))).response
      .data;
    this.setState({
      kids: response.resultObject.kids,
      validations: response.resultObject.validations,
    });
  }

  onBirthDateChange = (value) => {
    const { newKid, errors } = this.state;
    errors["birthDate"] = [];
    newKid["birthDate"] = value;
    this.setState({ newKid, errors });
  };

  onChange = (e) => {
    const { newKid, errors } = this.state;
    let value = e.value;

    errors["name"] = [];
    value = validator.blockUnwantedSymbols(e.value);
    newKid["name"] = value;

    this.setState({ newKid, errors });
  };

  hasErrors = () => {
    const { errors } = this.state;

    for (var error in errors) {
      if (errors[error] && errors[error].length) {
        return true;
      }
    }
    return false;
  };

  addKid = async (e) => {
    this.notificationPopupRef.current.closeClicked();

    const { errors } = this.state;
    const { kids, newKid, validations } = this.state;

    e.preventDefault();
    errors["birthDate"] = validator.validateBirthDate(
      newKid.birthDate,
      validations,
      "kids_club_page_incorrect_kids_age_error",
      undefined,
      "kids_club_page_empty_birthdate_error"
    );
    errors["name"] = validator.validateNameBeforeSubmit(
      newKid.name,
      kids,
      validations
    );

    this.setState({ errors });

    if (!this.hasErrors()) {
      const result = (
        await fetcherApi.put(urlTools.constructRoute("api/profile/Clubs/KidsClub/" + this.props.initialData.legalEntityId + "/kids"), {
          name: newKid.name,
          birthDate: newKid.birthDate,
        })).response.data;

      if (result.success) {
        let joined = kids.concat(result.resultObject);

        this.setState({ kids: joined, newKid: {} });
        this.notificationPopupRef.current.showPopup(constants.DATA_SAVED);
      }
      else if (result.error === "maxKidsReached") {
        errors["name"] = ["kids_club_page_max_kids_error"];
        this.setState({ errors });
      }
    }
  };

  showPopup = () => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showPopup: true });
  };

  hidePopup = () => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showPopup: false });
  };

  leaveClub = async (e) => {
    const { kids } = this.state;

    if (kids !== null && kids.length > 0) {
      const result = await fetcherApi.delete(urlTools.constructRoute("api/profile/Clubs/KidsClub/" + this.props.initialData.legalEntityId + "/kids"))
        .response;
      this.setState({ kids: [], errors: {} });
    }
    this.setState({ showPopup: false });
  };

  onRemoveKid = async (e) => {
    {
      /*
        const { kids } = this.state;
        const id = parseInt(e.target.id)      
 
        await fetcher.callWithQueryParams({ url: '/RemoveKid', baseRoute: urlTools... }, {id: id });
 
        var joined = kids.filter(function (kid) {
            return kid.id !== id
        });
        this.setState({ kids: joined, errors: {} }); 
        */
    }
  };

  render() {
    const { newKid, kids, errors, validations } = this.state;
    const children = [];

    if (kids !== null) {
      for (var i = 0; i < kids.length; i += 1) {
        children.push(
          <ChildComponent
            key={i}
            rowNumber={i}
            kid={kids[i]}
            removeKid={this.onRemoveKid}
          />
        );
      }
    }

    return kids === null ? (
      <Spinner />
    ) : (
      <React.Fragment>
        <div className="container page-container club-page">
          <div>
            {this.state.showPopup ? (
              <ConfirmationModalDialog
                titleText={"kids_club_popup_are_you_sure"}
                bodyText={"kids_club_popup_info"}
                yesButtonText={"pets_club_popup_page_yes_btn"}
                noButtonText={"pets_club_popup_page_no_btn"}
                onYesButtonClick={this.leaveClub.bind(this)}
                hidePopup={this.hidePopup.bind(this)}
              />
            ) : null}
          </div>

          <Link
            className="link-button -with-left-icon"
            to={{ pathname: "/clubs" }}
          >
            <svg
              enableBackground="new 0 0 24 24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m15 6-6 6 6 6"
                fill="none"
                stroke="#a12971"
                strokeWidth="2"
              />
            </svg>
            <TranslationText text="kids_club_page_back_btn" />
          </Link>
          <AutoHideableTitle text={'kids_club_page_title'} />
          <div className="card card-full-width">
            <div className="card-content">
              <div className="club-header">
                <div className="icon">
                  <img
                    src={KidsClubImage.getKidsClubPicture(this.props.initialData.legalEntityId)}
                    alt="kid-club"
                  />
                </div>
                <div className="page-description-row">
                  <p>
                    <TranslationText text="kids_club_page_what_offer" />
                  </p>
                  <p>
                    <TranslationText text="kids_club_page_info" options={{ maxAge: validations.maxAge }} />
                  </p>
                </div>
              </div>
              <hr className="no-margin-top" />

              <div className="group-row">
                <div className="row">
                  <div className="column-desktop-3 column-tablet-6 column-mobile-12">
                    <div
                      className={
                        errors["name"] !== undefined &&
                          errors["name"].length > 0
                          ? "form-field  -has-error"
                          : "form-field"
                      }
                    >
                      <label htmlFor="name" className="form-field__label">
                        <TranslationText text="kids_club_page_name_field_title" />
                      </label>
                      <InputText
                        onChange={this.onChange}
                        prop="name"
                        value={newKid.name}
                        placeholder={i18n.t('kids_club_page_name_field_hint')}
                        maxLength="20"
                      />
                    </div>
                    <div className="sublabel error-msg show">
                      <span>
                        <TranslationText text={errors.name} />
                      </span>
                    </div>
                  </div>
                  <div className="column-desktop-3 column-tablet-6 column-mobile-12">
                    <div
                      className={
                        errors["birthDate"] !== undefined &&
                          errors["birthDate"].length > 0
                          ? "form-field  -has-error"
                          : "form-field"
                      }
                    >
                      <label htmlFor="birth_date" className="form-field__label">
                        <TranslationText text="kids_club_page_birthday_field_title" />
                      </label>
                      <DateInput
                        initialDateString={newKid.birthDate}
                        onChange={this.onBirthDateChange}
                        errorMessage={errors.birthDate}
                        errorMessageTranslationOptions={{ maxAge: validations.maxAge }}
                      />
                    </div>
                  </div>

                  <div className="column-desktop-3 column-tablet-12"></div>
                  <div className="column-desktop-3 column-tablet-12">
                    <button
                      type="submit"
                      onClick={this.addKid}
                      disabled={this.hasErrors()}
                      className="button full-width"
                    >
                      <TranslationText text='kids_club_page_add_btn' />
                    </button>
                    <NotificationSuccess ref={this.notificationPopupRef}></NotificationSuccess>
                  </div>
                </div>
              </div>
              <hr />
              <ParentComponent>{children}</ParentComponent>
              {kids.length > 0 ? (
                <React.Fragment>
                  <hr />
                  <div className="leave-club">
                    <p>
                      <a onClick={this.showPopup} href="#">
                        <TranslationText text='kids_club_page_leave_club_btn' />
                      </a>
                    </p>
                    <p>
                      <TranslationText text='kids_club_page_leave_club_info' />
                    </p>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(KidsClub);

const ParentComponent = (props) => (
  <div className="household-members group-row">
    <div className="row">
      <div className="column-desktop-12">{props.children}</div>
    </div>
  </div>
);

const ChildComponent = (props) => (
  <React.Fragment>
    <div className="row padded-row">
      <div className="column-desktop-3 column-tablet-6 column-mobile-12">
        <div className="form-field">
          <label htmlFor="member1" className="form-field__label">
            <TranslationText text='kids_club_page_name_field_title' />
          </label>
          <input
            style={{ color: "#878380", cursor: "default" }}
            className="form-field__input"
            type="text"
            name="member1"
            id="member1"
            value={props.kid.name}
            disabled
            readOnly
          />
        </div>
      </div>
      <div className="column-desktop-3 column-tablet-6 column-mobile-12">
        <div className="form-field date-input">
          <label htmlFor="status_member1" className="form-field__label">
            <TranslationText text='kids_club_page_birthday_field_title' />
          </label>
          <DateInput
            initialDateString={props.kid.birthDate}
            readOnly={true}
          />
        </div>
      </div>
      <div className="column-desktop-3 column-tablet-12"></div>
      <div className="column-desktop-3 column-tablet-12">
      </div>
    </div>
  </React.Fragment>
);
