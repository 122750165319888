import { GaEventCategoryConstants } from "./GaEventCategoryConstants.jsx";

export const pushAccountEvent = (eventAction) => {
  pushDataLayer(GaEventCategoryConstants.Account, eventAction);
}

const pushDataLayer = (eventCategory, eventAction) => {
  window.dataLayer.push({
    event: 'uR',
    eventCategory: eventCategory,
    eventAction: eventAction,
    nonInteraction: '0'
  });
}
