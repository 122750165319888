class CookieBotAdapter {
	constructor() {
		this.overlay = '';
		this.selectionButton = '';
		this.acceptAllButton = '';
		this.declineButton = '';
		this.BUTTON_SELECTION = 'CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection';
		this.BUTTON_ACCEPT_ALL = 'CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll';
		this.BUTTON_DECLINE = 'CybotCookiebotDialogBodyButtonDecline';

		this.checkIfPresent();
	}

	checkIfPresent() {
		let interval = setInterval(() => {
			let dialog = document.getElementById('CybotCookiebotDialog');
			if (dialog != null) {
				clearInterval(interval);

				this.updateDialog();
				this.selectionButtonClick();
				this.acceptAllButtonClick();
				this.declineButtonClick();
			}
		}, 100);
	}

	updateDialog() {
		this.addCookieOverlay();

		this.selectionButton = document.getElementById(this.BUTTON_SELECTION);
		this.acceptAllButton = document.getElementById(this.BUTTON_ACCEPT_ALL);
		this.declineButton = document.getElementById(this.BUTTON_DECLINE);
	}

	selectionButtonClick() {
		this.selectionButton.addEventListener('click', () => {
			this.removeCookieOverlay();
		});
	}

	acceptAllButtonClick() {
		this.acceptAllButton.addEventListener('click', () => {
			this.removeCookieOverlay();
		});
	}

	declineButtonClick() {
		this.declineButton.addEventListener('click', () => {
			this.removeCookieOverlay();
		});
	}

	addCookieOverlay() {
		this.overlay = document.createElement('div');
		this.overlay.classList.add('cookies-overlay');

		document.body.appendChild(this.overlay);
		document.body.classList.add('modal-opened');
	}

	removeCookieOverlay() {
		this.overlay.remove();
		document.body.classList.remove('modal-opened');
	}
}

export default new CookieBotAdapter;
