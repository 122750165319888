import FetcherApi from "components/components/FetcherApi";
import urlTools from "components/tools/urlTools";
import React, { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

class Error extends Component {
  state = {
    errorMessage: "",
    errorDescription: "",
    requestId: ""
  };

  async componentDidMount() {
    const response = await FetcherApi.get(urlTools.constructRoute("/api/error" + this.props.location.search));
    const result = response.response.data;
    if (!result) {
      return;
    }

    this.setState({
      errorMessage: result.error,
      errorDescription: result.errorDescription,
      requestId: result.requestId
    });
  }

  render() {
    const { errorMessage, errorDescription, requestId } = this.state;
    return (
      <React.Fragment>
        <header className="page-header">
          <div className="container"></div>
        </header>
        <div className="container body-content">
          <div className="error-page">
            <div className="page-header">
              <h1>Error</h1>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="alert alert-danger">
                  Sorry, there was an error
                  {
                    errorMessage ? (
                      <React.Fragment>
                        <strong>
                          <em>
                            : {errorMessage}
                          </em>
                        </strong>
                        {
                          errorDescription ? (
                            <div>{errorDescription}</div>
                          ) : null
                        }
                      </React.Fragment>
                    ) : null
                  }
                </div>
              </div>
            </div>
            {
              requestId ? (
                <div className="request-id">
                  Request Id: {requestId}
                </div>
              ) : null
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Error);