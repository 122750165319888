import React, { Component } from "react";
import TranslationText from "../Translation/TranslationText.jsx";

class SendVerificationCode extends Component {

  state = {
    showDecline: false,
    isSendingDisabled: false
  }

  confirm = () => {
    this.props.confirm();
    this.setState({ isSendingDisabled: true });
  }

  render() {
    return (<React.Fragment>
      <div className="form-button full-width">
        <button className="button" onClick={this.confirm} disabled={this.state.isSendingDisabled}>
          <TranslationText text='send_verification_email_button_label' />
        </button>
      </div>
      <hr className="spacer" />
    </React.Fragment>)
  }
}
export default SendVerificationCode;