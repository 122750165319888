import { RECAPTCHA_ERROR } from "components/constants/errorCodes.jsx";
import fetcherApi from './FetcherApi.jsx';

export default {
  async validateCardReplacement(card, apiCall, recaptchaToken = null) {
    const validationResult = { isValid: false, error: '', cardStatus: undefined };
    if (card.cardPrefix.length < 11 || card.cardNumber.length < 8) {
      validationResult.error = ["card_replacement_page_incorrect_card_number_error"];
    }
    else {
      const requestSettings = { recaptchaToken };
      const payload = {
        cardPrefix: card.cardPrefix,
        cardNumber: card.cardNumber
      };

      const result = (await fetcherApi.post(apiCall + 'validate', payload, requestSettings)).response;
      if (result.data.success) {
        validationResult.isValid = true;
        validationResult.cardStatus = result.data.resultObject.cardStatus;
      }
      else if (result.data.error === 'verification_resend_limit_reached') {
        validationResult.error = ['card_replacement_page_sms_limit_error']
      } else if (result.data.error === "replacingCardWithInitialStatus") {
        validationResult.error = ['card_replacement_page_initial_card_error'];
      } else if (result.data.error === "rate_limit_reached") {
        validationResult.error = ['card_replacement_page_rate_limit_reached'];
      } else if (result.data.error === "cardNotFound") {
        validationResult.error = ['card_replacement_page_incorrect_number_field_error'];
      } else if (result.data.error === "cardNumberIsBlocked") {
        validationResult.error = ['card_replacement_page_blocked_card_error'];
      } else if (result.data.error === RECAPTCHA_ERROR) {
        validationResult.error = [RECAPTCHA_ERROR];
      }
      else {
        validationResult.error = ['enter_existing_card_page_card_not_found_error'];
      }
    }

    return validationResult;
  },
  async initalizeVirtualCard(apiCall) {
    var validationResult = { isValid: false, error: '' }

    const result = (await fetcherApi.post(apiCall + 'initializeVirtualCard')).response;
    if (result.data.success) {
      validationResult.isValid = true;
    }
    else if (result.data.error === 'verification_resend_limit_reached') {
      validationResult.error = ['card_replacement_page_sms_limit_error']
    } else if (result.data.error === "rate_limit_reached") {
      validationResult.error = ['card_replacement_page_rate_limit_reached'];
    } else if (result.data.error === "cardNotFound") {
      validationResult.error = ['card_replacement_page_card_not_found'];
    }
    return validationResult;
  }
}