import React, { Component } from "react";
import Footer from "./Footer.jsx";
import RimiLogoHeaderButton from "./HeaderComponents/RimiLogoHeaderButton.jsx";
import AutoHideableHeader from "./HeaderComponents/AutoHideableHeader.jsx";
import { Trans, withTranslation } from 'react-i18next';
import StepBackHeader from "./HeaderComponents/StepBackHeader.jsx";
import ConsentLinks from "./Consents/ConsentLinks.jsx";
import { Link } from "react-router-dom";
import fetcherApi from "./FetcherApi.jsx";
import * as errorCodes from "../constants/errorCodes.jsx";
import { TemporarySuccessDisplay } from "./SuccessDisplay/TemporarySuccessDisplay.jsx";
import TranslationText from "./Translation/TranslationText.jsx";

class VerificationEmailSent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
      isResendSuccessDisplayed: false,
      errorText: undefined
    };
  }

  sendAgain = async (e) => {
    e.preventDefault();
    this.setState({ isProcessing: true, errorText: undefined });
    const result = await fetcherApi.post(this.props.location.state.apiCall, this.props.location.state.payload);
    const response = result.response;

    if (!response.data.success) {
      this.setState({
        errorText: response.data.error === errorCodes.VERIFICATION_RESEND_LIMIT_ERROR
          ? "rate_limit_reached"
          : response.data.error
      });
    } else {
      this.setState({ isResendSuccessDisplayed: true });
    }

    this.setState({ isProcessing: false });
  }

  onResendSuccessDisplayExpiration = () => {
    this.setState({ isResendSuccessDisplayed: false });
  }

  render() {
    return <React.Fragment>
      <div className="container">
        <div className="card">
          <AutoHideableHeader>
            <RimiLogoHeaderButton initialData={this.props.initialData} />
            <StepBackHeader
              pathName={'/login'}
              buttonText={'login_button_label'}
              iconBeforeText={<svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="#a12971" strokeWidth="2" transform="translate(5 4)"><path d="m3.8 5.6v-5.6h7.7c1.2 0 2.1.9 2.1 2.1v11.8c0 1.2-.9 2.1-2.1 2.1h-7.7v-5.6" /><path d="m0 8h9" /><path d="m6.1 10.9 2.9-2.9-2.9-3" /></g></svg>}
            />
          </AutoHideableHeader>
          <div className="card-content">
            <div className="email-sent">
              <h1>
                <Trans i18nKey='verification_email_has_been_sent_page_info'>
                  E-mail verification link has been sent to your e-mail address.
                  <span>
                    <br />
                    <br />
                  </span>
                  Please check your mailbox and follow the instructions in e-mail to continue registration process.
                  <span>
                    <br />
                    <br />
                  </span>
                  Please check your spam folder if you do not see verification email in your inbox.
                </Trans>
              </h1>
            </div>
            <div className="info-text">
              <div className="message-container">
                <TemporarySuccessDisplay
                  isDisplayed={this.state.isResendSuccessDisplayed}
                  onExpiration={this.onResendSuccessDisplayExpiration}
                  message="email_resend_success_message"
                />
                <div className="form-row">
                  <div className="sublabel error-msg show">
                    <span>{<TranslationText text={this.state.errorText} />}</span>
                  </div>
                </div>
              </div>
              <p>
                <Trans i18nKey='verification_email_has_been_sent_page_try_again'>
                  Didn't receive an email?
                  <Link
                    onClick={this.sendAgain}
                    className={this.state.isProcessing ? "-disabled" : ""}
                    to="#"
                  >
                    Try again
                  </Link>
                </Trans>
              </p>
            </div>
          </div>
          <hr className="spacer" />
          <footer className="card-footer no-border">
            <div className="footer-text">
              <ConsentLinks />
            </div>
          </footer>
        </div>
      </div>
      <Footer countrySelectionEnabled={false} />
    </React.Fragment>
  }
}

export default withTranslation()(VerificationEmailSent);

