import React, { Component } from "react";
import Footer from "../components/Footer.jsx";
import TranslationText from "../components/Translation/TranslationText.jsx";
import fetcherApi from '../components/FetcherApi.jsx';
import { withTranslation } from 'react-i18next';
import urlTools from "../tools/urlTools.jsx";
import RimiLogoHeaderButton from "components/components/HeaderComponents/RimiLogoHeaderButton.jsx";

class Unsubscribe extends Component {

  state = {
    error: false,
    email: this.props.initialData.retail,
    errorText: !!this.props.initialData.error ? this.props.initialData.error : "",
    confirmed: this.props.initialData.returnEmptyView ? true : false,
    successTitle: "",
    sucessInfo: "",
  };

  handleChange = e => {

    const { checked } = e.target;
    this.setState({ email: checked });
  };

  confirm = async (e) => {
    let ubsubscribeModel = this.props.initialData;
    ubsubscribeModel.retail = this.state.email;

    const result = (await fetcherApi.post(urlTools.constructRoute("api/Unsubscribe/unsubscribe"), ubsubscribeModel)).response;
    if (result.data.success) {
      this.setState({ confirmed: true, successTitle: 'unsubscribe_page_success_title', sucessInfo: 'unsubscribe_page_success_info' });
    }
    else {
      this.setState({ error: true, errorText: "unexpected_error" });
    }
  };

  render() {

    return <React.Fragment>
      <div className="container">
        <div className="card">
          <header className="card-header">
            <RimiLogoHeaderButton initialData={this.props.initialData} />
          </header>
          <div className={this.state.error ? ("card-content") : ("card-content no-padding-bottom")}>
            <h1>

            </h1>

            {!this.state.confirmed ? (

              <React.Fragment>
                <p>
                  <TranslationText text='unsubscribe_page_title' />
                </p>
                <div className="form-group">
                  <div className="form-row">
                    <div className="form-field -checkbox">
                      <div className="form-check">
                        <input className="form-check-input" onChange={this.handleChange} checked={this.state.email} type="checkbox" value="email" id="info_email" />
                        <label className="form-check-label" htmlFor="info_email">
                          <TranslationText text='unsubscribe_page_box' />                                 <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="sublabel error-msg show">
                      <span>{<TranslationText text={this.state.errorText} />}</span>
                    </div>
                  </div>
                </div>
                <div className="form-button full-width">
                  <button className="button" onClick={this.confirm}>
                    <TranslationText text='unsubscribe_page_save_btn' />
                  </button>
                </div>
              </React.Fragment>
            )
              : (<React.Fragment>
                <div className="sublabel error-msg show">
                  <span>
                    <TranslationText text={this.state.errorText} />
                  </span>
                </div>
                <p>
                  <TranslationText text={this.state.successTitle} />
                </p>
                <p>
                  <TranslationText text={this.state.sucessInfo} />
                </p>
              </React.Fragment>)
            }

          </div>
          <footer className="card-footer no-border">
            <div className="footer-text">

            </div>
          </footer>
        </div>
      </div>
      <Footer countrySelectionEnabled={false} />
    </React.Fragment>
  }
}

export default withTranslation()(Unsubscribe);


