import React, { Component } from "react";
import TranslationText from "./Translation/TranslationText.jsx";

class CurrentUserInfo extends Component {

  state = {
    userName: this.props.userName ?? "Unset user"
  };

  componentDidMount() {

  }

  updateUserName(updatedUserName) {
    this.setState({ userName: updatedUserName });
  }

  render() {
    const { userName } = this.state;

    return (
      <React.Fragment>
        <div className="name">
          <TranslationText text='profile_information_page_hi' /><b>{userName}</b>
        </div>
      </React.Fragment>
    );
  }
}

export default CurrentUserInfo;


