import React from 'react';
import { Route } from "react-router-dom";
import urlTools from "components/tools/urlTools";

const UnauthenticatedRoute = props => (
  <Route
    path={props.path}
    component={() => !props.initialData.isUserLoggedIn ? props.component : urlTools.getDefaultRedirect(props.initialData)
    }
  />
)

export default UnauthenticatedRoute;