import React, { Component } from "react";
import { Link } from "react-router-dom";
import InputText from "../../components/InputText.jsx";
import Footer from "../../components/Footer.jsx";
import LegalEntityFunctions from "../../components/LegalEntityFunctions.jsx";
import validator from '../../components/FieldValidator.jsx';
import fetcherApi from '../../components/FetcherApi.jsx';
import * as constants from "../../constants/constants.jsx";
import ConsentLinks from "../../components/Consents/ConsentLinks.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import { Trans, withTranslation } from 'react-i18next';
import RimiLogoHeaderButton from "../../components/HeaderComponents/RimiLogoHeaderButton.jsx";
import StepBackHeader from "../../components/HeaderComponents/StepBackHeader.jsx";
import AutoHideableHeader from "../../components/HeaderComponents/AutoHideableHeader.jsx";
import urlTools from "../../tools/urlTools.jsx";
import { DateInput } from '../../components/DateInput.jsx';
import EmailAndPhoneValidator from "../../components/EmailAndPhoneValidator.jsx";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { RECAPTCHA_ERROR } from "components/constants/errorCodes.jsx";
import DeviceService from "components/services/DeviceService.js";

class EnterEmailPhonePasswordRecovery extends Component {

	state = {
		phonePrefixes: this.props.initialData.phonePrefixes.map(data => ({ label: data.value, value: data.key })),
		client: { email: "", name: "", phonePrefixId: "", phone: "", phonePrefix: "", b2bAccountSelected: false, birthDate: null },
		errors: [],
		phoneError: false,
		maxPhoneLength: "",
		selectedPrefix: "",
		email: "",
		phone: "",
		isProcessing: false,
		isBirthDateCheckDisplayed: false,
		isBusinessLogin: false,
		birthDateErrorText: '',
		isPhoneFieldExpanded: false,
		beginRecaptchaValidation: undefined
	};

	onDropDownChange = e => {
		let index = e.nativeEvent.target.selectedIndex;
		const { client } = this.state;
		let prefix = e.nativeEvent.target[index].text;
		client.phonePrefixId = e.target.value;
		this.setState({
			client,
			selectedPrefix: prefix,
			maxPhoneLength: validator.getPhoneMinLength(this.props.initialData.phonePrefixes, prefix)
		});

		if (this.state.phoneError) {
			this.setState({
				errors: [],
				phoneError: false
			});
		}
	};

	componentDidMount() {
		let maxPhoneLength = validator.getPhoneMinLength(this.props.initialData.phonePrefixes, LegalEntityFunctions.getPhonePrefix(localStorage.getItem(constants.SELECTED_COUNTRY)));
		this.setState({ maxPhoneLength: maxPhoneLength });

		const client = this.state.client;
		const country = localStorage.getItem(constants.SELECTED_COUNTRY);
		const phonePrefixId = LegalEntityFunctions.getPhonePrefixId(country);
		const phonePrefix = LegalEntityFunctions.getPhonePrefix(country);
		client.phonePrefix = phonePrefix;
		client.phonePrefixId = phonePrefixId;
		this.setState({ client });

		if (!!this.props.location && !!this.props.location.state) {
			this.setState({ isBusinessLogin: this.props.location.state.isBusinessLogin });
		}
	}


	createSelectItems() {
		const { phonePrefixes } = this.state;
		let items = [];
		for (let i = 0; i < phonePrefixes.length; i++) {
			let label = phonePrefixes[i].label;
			let key = phonePrefixes[i].value;
			items.push(<option key={label} value={key}>{label}</option>);
		}
		return items;
	}

	getPhoneFields = () => {

		const { client, errors, maxPhoneLength, phone, email, birthDateErrorText } = this.state;
		if (!phone && email) {
			this.setState({ phone: email, email: '' });
		}
		return (
			<React.Fragment>
				<label htmlFor="email" className="form-field__label">
					<TranslationText text='password_recovery_page_phone_field_title' />
				</label>

				<div className="form-group">
					<div className={(errors[0] || birthDateErrorText)
						? "form-row -phone-number -has-error" : "form-row -phone-number"}>

						<div className="form-field form-field__select">
							<select value={client.phonePrefixId} onChange={this.onDropDownChange}>
								{this.createSelectItems()}
							</select>
							<svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m22.7 12.7-6.7 6.6-6.7-6.6" fill="none" stroke="#a12971" strokeMiterlimit="10" strokeWidth="2" /></svg>
						</div>
						<div className="form-field">
							<InputText
								onChange={this.onChange}
								prop="phone"
								value={phone === "" ? email : phone}
								className="form-field__input -tel"
								type="tel"
								maxLength={maxPhoneLength}
							/>
						</div>

					</div>
					<label className="sublabel error-msg show">
						<TranslationText text={errors[0]} />
					</label>
				</div>
			</React.Fragment>
		)
	}

	onChange = e => {
		const { client } = this.state;
		if (e.prop === 'phone') {
			client['phone'] = e.value.replace(/[^0-9]/g, '');
			if (e.value == '' || e.value.match(/[0-9]/g)) {
				this.setState({ phone: client.phone });
			}
		}
		else if (e.prop === 'email') {
			client[e.prop] = validator.blockSpaceSymbol(e.value);
			this.setState({ email: client.email });
		}
		else {
			client[e.prop] = e.value;
		}
		this.setState({ client, errors: {}, birthDateErrorText: '' });
	};

	beginRecaptchaValidation = () => {
		this.setState({
			beginRecaptchaValidation: !this.state.beginRecaptchaValidation,
			isProcessing: true
		});
	}

	completeRecaptchaValidation = (recaptchaToken) => {
		this.confirm(recaptchaToken);
	}

	confirm = async recaptchaToken => {
		const { client, errors, selectedPrefix, isBusinessLogin, isBirthDateCheckDisplayed } = this.state;
		let error = false;

		if (isBirthDateCheckDisplayed && !client.birthDate) {
			this.setState({ birthDateErrorText: 'enter_email_invalid_birth_date_error' });
			this.setState({ isProcessing: false });
			return;
		}

		const phoneRegex = /\d{6,10}$/;
		const potentialPhone = this.state.email || this.state.phone;
		if (phoneRegex.test(potentialPhone) && !isBusinessLogin && !isBirthDateCheckDisplayed) {
			await this.validatePhoneExistence(potentialPhone, selectedPrefix);
			return;
		}

		if (selectedPrefix !== "") {
			let phoneError = validator.validatePhonePrefixes(this.props.initialData.phonePrefixes, selectedPrefix, this.state.phone);
			errors[0] = phoneError;

			if (!phoneError) {
				client.phone = this.state.phone;
			}
			this.setState({ phoneError: true, isPhoneFieldExpanded: true, errors, client, selectedPrefix });
		}

		if (!error) {

			if (!selectedPrefix) {
				//default to selected country prefix
				client.phonePrefix =
					LegalEntityFunctions.getPhonePrefix(localStorage.getItem(constants.SELECTED_COUNTRY));
			} else {
				client.phonePrefix = selectedPrefix;
			}
			client.b2bAccountSelected = this.state.isBusinessLogin;
			const requestSettings = { recaptchaToken };
			const payload = JSON.stringify(client);

			const isDesktop = DeviceService.isDesktop();
			const combinedResponse = (await fetcherApi.post(urlTools.constructRoute(`api/PasswordRecovery/SendToEmailOrPhone?isMobile=${!isDesktop}`), payload, requestSettings)).response.data;

			if (combinedResponse.error === 'isNotEmailOrPhone') {
				errors[0] = 'password_recovery_is_not_email_or_phone';
				this.setState({ errors });
			} else if (combinedResponse.error === 'accountTypeMismatch') {
				errors[0] = 'password_recovery_account_type_mismatch';
				this.setState({ errors });
			} else if (combinedResponse.error === 'rate_limit_reached' || combinedResponse.error === "verification_resend_limit_reached") {
				errors[0] = "password_recovery_page_email_limit_error";
				this.setState({ errors, birthDateErrorText: '' });
			} else if (combinedResponse.error === 'enter_email_invalid_birth_date_error') {
				this.setState({ birthDateErrorText: 'enter_email_invalid_birth_date_error' });
			} else if (combinedResponse.error === RECAPTCHA_ERROR) {
				errors[0] = RECAPTCHA_ERROR;
				this.setState({ errors });
			} else {
				//now we definitely can access resultObject 
				const isEmail = combinedResponse.resultObject.isEmail;
				const isPhone = combinedResponse.resultObject.isPhone;
				const isTargetEmail = combinedResponse.resultObject.isTargetEmail;
				const authorized = combinedResponse.resultObject.authorized;

				if (combinedResponse.success) {
					this.props.history.push("/PasswordRecovery/VerificationCode", {
						email: combinedResponse.resultObject.email,
						isBusiness: this.state.isBusinessLogin,
						isTargetEmail
					});
				} else if (!authorized) {

					if (isEmail) {
						client.email = '';
						errors[0] = "account_found_but_no_email_page_incorrect_email_error";
						this.setState({ errors, client });
					} else if (isPhone) {
						this.expandPhoneField();
					}
				} else {
					// authorized but problems to send sms/email, not rate limit
					errors[0] = "password_recovery_failed_to_send";
					this.setState({ errors });
				}
			}
		}

		this.setState({ isProcessing: false });
	};

	validatePhoneExistence = async (potentialPhone, selectedPrefix) => {
		const prefix = !selectedPrefix
			? LegalEntityFunctions.getPhonePrefix(localStorage.getItem(constants.SELECTED_COUNTRY))
			: selectedPrefix;

		const result = await EmailAndPhoneValidator.IsPhoneUnique(potentialPhone, prefix, constants.RETAIL);

		if (result.errorStandardOrRateLimit) {
			const { errors } = this.state;
			errors[0] = "password_recovery_page_email_limit_error";
			this.setState({ errors });
		} else if (result.data.resultObject.unque) {
			this.expandPhoneField("login_page_phone_not_found_error");
		} else {
			this.setState({
				isBirthDateCheckDisplayed: true,
				errors: [],
				phoneError: false
			});
		}

		this.setState({ isProcessing: false });
	};

	expandPhoneField = (error = 'login_with_phone_number_page_user_not_found_error') => {
		const { errors, client } = this.state;
		errors[0] = error;

		client.email = "";
		client.phone = "";

		if (!client.phonePrefixId) {
			const selectedCountry = localStorage.getItem(constants.SELECTED_COUNTRY);
			client.phonePrefix = LegalEntityFunctions.getPhonePrefix(selectedCountry);
			client.phonePrefixId = LegalEntityFunctions.getPhonePrefixId(selectedCountry);
		}

		this.setState({
			phoneError: true,
			isPhoneFieldExpanded: true,
			selectedPrefix: client.phonePrefix,
			errors,
			client,
			maxPhoneLength: validator.getPhoneMinLength(this.props.initialData.phonePrefixes, client.phonePrefix)
		});
	};

	onBirthDateChange = value => {
		const { client } = this.state;
		client.birthDate = value;
		this.setState({ client, errors: {}, error: false, birthDateErrorText: '' });
	};

	render() {
		const { errors, isBirthDateCheckDisplayed, birthDateErrorText } = this.state;

		return (
			<React.Fragment>
				<div className="container">
					<div className="card">
						<AutoHideableHeader>
							<RimiLogoHeaderButton initialData={this.props.initialData} />
							<StepBackHeader
								pathName={'/login'}
								buttonText={'login_button_label'}
								iconBeforeText={<svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="#a12971" strokeWidth="2" transform="translate(5 4)"><path d="m3.8 5.6v-5.6h7.7c1.2 0 2.1.9 2.1 2.1v11.8c0 1.2-.9 2.1-2.1 2.1h-7.7v-5.6" /><path d="m0 8h9" /><path d="m6.1 10.9 2.9-2.9-2.9-3" /></g></svg>}
							/>
						</AutoHideableHeader>
						<div className="card-content no-padding-bottom">
							<h1>
								<Trans i18nKey={isBirthDateCheckDisplayed ? 'enter_phone_page_title' : 'password_recovery_page_title'}>
									Reset your <b>password</b>
								</Trans>
							</h1>
							<div className="form-group">
								<div className="form-row">
									{this.state.isPhoneFieldExpanded ? (this.getPhoneFields()) :
										(
											<React.Fragment>
												<div className={(errors[0] || birthDateErrorText)
													? "form-field -has-error" : "form-field"}>
													<label htmlFor="email_or_phone" className="form-field__label">
														<TranslationText text="login_page_username_title" />
													</label>
													<InputText
														prop="email"
														className="form-field__input"
														value={this.state.email}
														onChange={this.onChange}
													/>
												</div>
												<label className="sublabel error-msg show">
													<TranslationText text={errors[0]} />
												</label>
											</React.Fragment>
										)
									}
									{isBirthDateCheckDisplayed ?
										(
											<div className="form-row">
												<div className={!this.state.error ? "form-field" : "form-field -has-error"}>
													<label htmlFor="birthDate" className="form-field__label">
														<TranslationText text='enter_email_birth_date_input_label' />
													</label>
													<DateInput
														initialDateString={this.state.client.birthDate}
														onChange={this.onBirthDateChange}
														errorMessage={this.state.birthDateErrorText}
													/>
												</div>
											</div>
										) : null
									}
								</div>
							</div>
							{
								this.state.beginRecaptchaValidation !== undefined
									? <GoogleReCaptcha onVerify={this.completeRecaptchaValidation} refreshReCaptcha={this.state.beginRecaptchaValidation} />
									: null
							}
							<div className="form-button full-width">
								<button
									type="submit"
									onClick={this.beginRecaptchaValidation}
									className="button"
									disabled={this.state.isProcessing}
								>
									<TranslationText text='password_recovery_page_reset_pwd_btn' />
								</button>
							</div>
							{!isBirthDateCheckDisplayed ?
								<div className="form-button full-width">
									<Link className="button -secondary" to={{ pathname: '/login' }}>
										<TranslationText text='return_to_login_page' />
									</Link>
								</div> : null
							}

							{!isBirthDateCheckDisplayed ?
								<div className="form-button full-width">
									<Link className="link-button link-button-large" to={{ pathname: '/passwordrecovery/RemindEmail/' }}>
										<TranslationText text='password_recovery_page_remind_email_btn' />
									</Link>
								</div> : null
							}


							<hr className="spacer" />
						</div>
						<footer className="card-footer no-border">
							<div className="footer-text">
								<ConsentLinks />
							</div>
						</footer>
					</div>
				</div>
				<Footer countrySelectionEnabled={false} />
			</React.Fragment>
		);
	}
}

export default withTranslation()(EnterEmailPhonePasswordRecovery);