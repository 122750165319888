import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Footer from "../../components/Footer.jsx";
import ConsentLinks from "../../components/Consents/ConsentLinks.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import { Trans, withTranslation } from 'react-i18next';
import RimiLogoHeaderButton from "../../components/HeaderComponents/RimiLogoHeaderButton.jsx";
import AutoHideableHeader from "../../components/HeaderComponents/AutoHideableHeader.jsx";
import StepBackHeader from "../../components/HeaderComponents/StepBackHeader.jsx";
import CardReplacementValidation from '../../../components/components/CardReplacementValidation.jsx';
import urlTools from "../../tools/urlTools.jsx";

class CardTypeSelection extends Component {

  state = {
    confirmed: false,
    errors: [],
    isVirtualCardSelectionLoading: false
  };

  confirm = async e => {
    this.setState({ isVirtualCardSelectionLoading: true });
    const { errors } = this.state;
    e.preventDefault();
    var validationResult = await CardReplacementValidation.initalizeVirtualCard(urlTools.constructRoute("api/profile/CardReplacement/"));

    if (validationResult.isValid) {
      this.setState({ confirmed: true });
    }
    else {
      errors['onConfirm'] = validationResult.error;
      this.setState({ errors });
    }

    this.setState({ isVirtualCardSelectionLoading: false });
  };

  handleClick = _ => {
    this.props.hideNavBar(false);
  }

  render() {

    const { errors, confirmed, isVirtualCardSelectionLoading } = this.state;

    return (
      <React.Fragment>
        <div className="container">
          <div className="card">
            <AutoHideableHeader>
              <RimiLogoHeaderButton onClick={this.handleClick.bind(this)} to="/profileInformation" />
              <StepBackHeader
                handleClick={this.handleClick.bind(this)}
                pathName={'/ProfileInformation'}
                buttonText={'step_back_button_label'} />
            </AutoHideableHeader>
            <div className="card-content no-padding-bottom">
              <h1>
                <Trans i18nKey='select_your_new_card_type'>
                  What is your new card type?
                </Trans>
              </h1>

              <div className="form-group">
                <div className="boolean-dialog">
                  <div className="form-col">
                    {!confirmed ? (
                      <button
                        type="submit"
                        onClick={this.confirm}
                        className="button -secondary positive full-width"
                        disabled={isVirtualCardSelectionLoading}
                      >
                        <span>
                          <TranslationText text='card_type_digital_btn' />
                        </span>
                      </button>
                    ) :

                      <Redirect to={{ pathname: '/verificationCode', state: { fromCardTypeSelection: true } }} />
                    }
                  </div>
                  <div className="form-col">
                    <Link className="button -secondary positive" to={{ pathname: '/cardReplacement' }}>
                      <span>
                        <TranslationText text='card_type_plastic_btn' />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="sublabel error-msg show">
                  <span>
                    {
                      (!!errors.onConfirm && errors.onConfirm.length > 0)
                        ? <TranslationText text={errors.onConfirm[0]} />
                        : null
                    }
                  </span>
                </div>
              </div>
            </div>

            <footer className="card-footer no-border">
              <div className="footer-text">
                <ConsentLinks />
              </div>
            </footer>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(CardTypeSelection);


