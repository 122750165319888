const DateInputService = {
  getSanitizedValue: (value, fixedLength, inputCount = fixedLength) => {
    if (!inputCount || !value) {
      return '';
    }

    if (value.length < fixedLength) {
      return value.padStart(fixedLength, '0');
    }

    const datePartStartIndex = value.length - fixedLength;
    const datePartEndIndex = value.length;

    return value.slice(datePartStartIndex, datePartEndIndex);
  },

  getUpdatedInputCount: (originalValue, updatedValue, currentInputCount) => {
    if (originalValue === updatedValue || !updatedValue) {
      return currentInputCount; // No changes were made => input count is unaffected.
    }

    if (originalValue && originalValue.length > updatedValue.length) {
      const offset = originalValue.length - updatedValue.length;

      // A value was partially / fully deleted => input count is decremented.
      return currentInputCount - offset > 0
        ? currentInputCount - offset
        : 0;
    }

    // A regular action was made outside of edge cases, described above => input count is incremented.
    // However, input count should never exceed the fixed length.
    return !originalValue || originalValue.length > currentInputCount + 1
      ? currentInputCount + 1
      : originalValue.length;
  },

  constructDate: (day, month, year) => {
    const parsedYear = parseInt(year);
    const numberOfDigitsInValidYears = 4;
    if (parsedYear.toString().replace(/[^0-9]/g, '').length < numberOfDigitsInValidYears) {
      return null;
    }
    const date = new Date(Number(parsedYear), Number(month) - 1, Number(day));

    if (isNaN(Number(date))) {
      return null;
    }

    const parsedMonth = date.getMonth() + 1;
    if (parsedMonth !== Number(month)) {
      return null;
    }

    return date;
  }
};

export default DateInputService;