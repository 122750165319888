import React, { Component } from "react";
import InputText from "../components/InputText.jsx";
import validator from '../components/FieldValidator.jsx';
import { Link, Redirect, withRouter } from "react-router-dom";
import Footer from "../components/Footer.jsx";
import LegalEntityFunctions from "../components/LegalEntityFunctions.jsx";
import fetcherApi from '../components/FetcherApi.jsx';
import ConsentLinks from '../components/Consents/ConsentLinks.jsx';
import TryAgainFooterButtons from "../components/Buttons/TryAgainFooterButtons.jsx";
import TryAgainReturnToLoginButtons from "../components/Buttons/TryAgainReturnToLogin.jsx";
import * as constants from "../constants/constants.jsx";
import i18n from "translations/i18n.jsx";
import TranslationText from "../components/Translation/TranslationText.jsx";
import EmailAndPhoneValidator from '../components/EmailAndPhoneValidator.jsx';
import { Trans, withTranslation } from "react-i18next";
import Spinner from "../profile/Information/Spinner.jsx";
import { pushAccountEvent } from "../tools/GaEvents/GaEventSender.jsx";
import { GaEventActionConstants } from "../tools/GaEvents/GaEventActionConstants.jsx";
import { DateInput } from '../components/DateInput.jsx';
import urlTools from "../tools/urlTools.jsx";
import AuthService from "components/services/AuthService.js";
import webTools from "components/tools/webTools.js";
import RimiLogoHeaderButton from "components/components/HeaderComponents/RimiLogoHeaderButton.jsx";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { RECAPTCHA_CHALLENGE_MAIL, RECAPTCHA_CHALLENGE_PHONE, RECAPTCHA_ERROR } from "components/constants/errorCodes.jsx";

class Login extends Component {
  state = {
    model: { username: "", password: "" },
    phonePrefixes: this.props.initialData.phonePrefixes.map(data => ({ label: data.value, value: data.value })),
    errors: {},
    selectedCountry: null,
    clientId: "",
    legalEntity: "",
    showSpinner: true,
    phoneError: false,
    selectedPrefix: "",
    passwordControlePlainTextMode: false,
    businessLogin: false,
    maxPhoneLength: "",
    showEmailSent: false,
    emailHint: "",
    email: "",
    birthDate: null,
    emailError: "",
    birthDateError: "",
    showEnterEmail: false,
    isMobileApp: false,
    isProcessing: false,
    preventBusinessLogin: this.props.initialData.businessLoginDisabled,
    beginRecaptchaValidation: undefined
  };

  setInitialState() {
    const isMobileApp = localStorage.getItem(constants.IS_MOBILE_APP_STORAGE_KEY) === constants.TRUE;
    const queriedSelectedCountry = urlTools.getQueryParamValue(this.props.location.search, constants.COUNTRY_URL_KEY);

    const isBusinessLogin = urlTools.getQueryParamValue(this.props.location.search, constants.BUSINESS_URL_KEY);

    if(isBusinessLogin) {
      this.setState({ businessLogin: true })
    }

    if (queriedSelectedCountry) {
      const country = queriedSelectedCountry.toLowerCase();
      if (country === "lt" || country === "ee" || country === "lv") {
        localStorage.setItem(constants.SELECTED_COUNTRY, country);
      }
    }
    var countryLocal = localStorage.getItem(constants.SELECTED_COUNTRY);
    if (countryLocal !== null) {
      this.setState({ selectedCountry: countryLocal });
    }

    this.setState({
      showSpinner: false, isMobileApp, businessLoginDisabled: this.props.initialData.businessLoginDisabled
    });

    if (this.props.location.state?.recaptchaTwoFactorPassed) {
      this.setState({ showSpinner: true, businessLogin: this.props.location.state.isBusinessFlow });

      this.handleRecaptchaTwoFactorFlow();
    }
  }

  componentDidMount() {
    this.executeUnauthenticatedRedirections();
    this.setInitialState();
  }

  executeUnauthenticatedRedirections = () => {
    const flags = this.props.initialData.unauthenticatedRedirectionFlags;
    switch (true) {
      case flags.redirectToRegistration:
        this.props.history.push("/registration/ExistingCard");
        break;
      case flags.redirectToRemindEmail:
        this.props.history.push("/PasswordRecovery/RemindEmail");
        break;
      case flags.redirectToPasswordReset:
        this.props.history.push("/PasswordRecovery/EnterEmail");
        break;
      case flags.redirectToCardReplacement:
        this.props.history.push("/cardReplacementLogin");
        break;
    }
  }

  onTogglePasswordShow = e => {
    e.preventDefault();
    this.setState({ passwordControlePlainTextMode: !this.state.passwordControlePlainTextMode });
  };

  createSelectItems() {
    const { phonePrefixes } = this.state;
    let items = [];
    for (let i = 0; i < phonePrefixes.length; i++) {
      let label = phonePrefixes[i].label;
      let key = phonePrefixes[i].value;
      items.push(<option key={label} value={key}>{label}</option>);
    }
    return items;
  }

  getSelectedCountryPrefix() {
    const selectedCountry = localStorage.getItem(constants.SELECTED_COUNTRY)
    switch (selectedCountry) {
      case 'lt':
        return '370';
      case 'lv':
        return '371';
      case "ee":
      case "et":
        return "372";

    }
  }

  onDropDownChange = e => {

    let maxlength = validator.getPhoneMinLength(this.props.initialData.phonePrefixes, e.target.value);
    this.setState({ selectedPrefix: e.target.value, maxPhoneLength: maxlength });
  };

  getPhoneFields = () => {

    const { model, selectedPrefix, errors, maxPhoneLength } = this.state;

    return (
      <React.Fragment>
        <label htmlFor="email" className="form-field__label">
          <TranslationText text='login_with_phone_number_page_phone_field_title' />
        </label>
        <div className="form-group no-margin-bottom">
          <div className={((errors['username'] !== undefined && errors['username'].length > 0)
            || (errors['onLogin'] !== undefined && errors['onLogin'].length > 0))
            ? "form-row -phone-number -has-error"
            : "form-row -phone-number"}>

            <div className="form-field form-field__select">
              <select value={selectedPrefix} onChange={this.onDropDownChange}>
                {this.createSelectItems()}
              </select>
              <svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m22.7 12.7-6.7 6.6-6.7-6.6" fill="none" stroke="#a12971" strokeMiterlimit="10" strokeWidth="2" /></svg>
            </div>
            <div className="form-field">
              <InputText
                onChange={this.onChange}
                prop="username"
                value={model.username}
                className="form-field__input -tel"
                type="tel"
                maxLength={maxPhoneLength}
              />
            </div>
          </div>
          <Link className="link-button link-button-small" to={{ pathname: "/PasswordRecovery/RemindEmail" }}>
            <TranslationText text="login_page_remind_email_btn" />
          </Link>
          <label className="sublabel error-msg show padding-bottom">
            <TranslationText text={errors['username']} />
          </label>
        </div>
      </React.Fragment>
    )
  }

  onChange = e => {
    const { model } = this.state;
    model[e.prop] = e.value;
    if (e.prop === 'username') {
      model[e.prop] = validator.blockSpaceSymbol(e.value);
    }

    this.setState({ model, errors: {} });
  }

  onAddEmail = e => {
    if (e.prop === 'email') {
      e.value = validator.blockSpaceSymbol(e.value);
    }

    this.setState({
      email: e.value,
      emailError: "",
      birthDateError: "",
      error: false
    });
  }

  onBirthDateChange = value => {
    this.setState({
      birthDate: value,
      emailError: "",
      birthDateError: "",
      error: false
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
  };

  switchLogin = (e) => {

    e.preventDefault();

    const { model } = this.state;
    model.password = "";
    this.setState({ model, errors: {}, businessLogin: !this.state.businessLogin });
  };

  showEmailSent = (e) => {
    this.setState({ showEmailSent: true });
  }

  verificationDone = (e) => {
    this.setState({ showEmailSent: false });
  }

  beginRecaptchaValidation = () => {
    this.setState({
      isProcessing: true,
      beginRecaptchaValidation: !this.state.beginRecaptchaValidation
    });
  }

  completeRecaptchaValidation = (token) => {
    this.Login(token);
  }

  Login = async recaptchaToken => {
    const { model, errors, selectedPrefix, isMobileApp, businessLogin } = this.state;
    let error = false;
    let clearPassword = true;
    errors['onLogin'] = "";

    if (model.username === "" && model.password === "") {
      errors['username'] = "login_page_empty_username_error";
      errors['password'] = "login_page_empty_pwd_error";
      error = true;
    }
    else if (model.username === "") {
      errors['username'] = "login_page_empty_username_error";
      error = true;
    }
    else if (model.password === "") {
      errors['password'] = "login_page_empty_pwd_error";
      error = true;
    }

    if (selectedPrefix !== "") {
      let phoneError = validator.validatePhonePrefixes(this.props.initialData.phonePrefixes, selectedPrefix, this.state.model.username);
      errors['username'] = phoneError;
      error = phoneError !== "";
    }

    if (!error) {
      const requestSettings = { recaptchaToken };
      const payload = {
        username: model.username,
        password: model.password,
        selectedCountry: localStorage.getItem(constants.SELECTED_COUNTRY),
        selectedPhonePrefix: selectedPrefix,
        isMobileLogin: isMobileApp,
        businessLogin: businessLogin
      };

      let result = (await fetcherApi.post('/api/Account/Login', payload, requestSettings)).response;

      error = await this.processLoginResult(result, error);
    }
    
    if (error) {
      if (clearPassword){
        model.password = "";
      }
      this.setState({ isProcessing: false });
    }

    this.setState({ errors, model });
  }

  handleRecaptchaTwoFactorFlow = async () => {

    const result = (await fetcherApi.post('/api/Account/login-after-two-factor')).response;
    if (!result) {
      this.setState({ showSpinner: false });

      return;
    }

    await this.processLoginResult(result, false);
  }

  processLoginResult = async (result, previousError) => {
    const { model, errors, selectedPrefix } = this.state;

    let error = previousError;
    let clearPassword = true;

    if (!result.data.success) {

      const usersLanguage = result.data.resultObject?.usersLanguageCode;
      if (usersLanguage) {
        const languageIsoCode = LegalEntityFunctions.getLanguageIsoCode(usersLanguage);
        i18n.changeLanguage(languageIsoCode);
      }

      error = true;
      pushAccountEvent(GaEventActionConstants.UserLoginFail);

      if (result.data.error === 'invalidphone') {

        let phoneErrorText = ["login_page_invalid_email_phone_error"];

        this.setState({ phoneError: true });
        if (selectedPrefix === "") {

          let selectedCountryPrefix = this.getSelectedCountryPrefix();
          let maxlength = validator.getPhoneMinLength(this.props.initialData.phonePrefixes, selectedCountryPrefix);
          this.setState({ selectedPrefix: selectedCountryPrefix, maxPhoneLength: maxlength });
        }

        errors['username'] = phoneErrorText;
      }
      else if (result.data.error === "noEmail") {
        clearPassword = false;
        this.setState({ showEnterEmail: true, clientId: result.data.resultObject.clientId, legalEntity: result.data.resultObject.legalEntity });
      }
      else if (result.data.error === "emailNotverified") {

        const resultSendVerification = (await fetcherApi.post(urlTools.constructRoute("api/EmailVerification/SendEmailVerificationLink/" + result.data.resultObject.email))).response;

        if (!resultSendVerification.data.success) {
          errors['onLogin'] = ["rate_limit_reached"];
          this.setState({ error: true, errors });
        }
        else {
          this.setState({ showEmailSent: true, emailHint: result.data.resultObject.emailHint });
        }
      }
      else if (result.data.error === "invalidConsents" || result.data.error === "oldConsents") {
        clearPassword = false;

        const consentsRoute = this.state.businessLogin ? "ConsentsAcceptanceB2b" : "ConsentsAcceptance";
        webTools.reloadToRoute(consentsRoute);
      }
      else if (result.data.error === "invalidcredentials") {
        errors['onLogin'] = ["login_page_invalid_credentials_error"]
      }
      else if (result.data.error === "blockedLogin") {
        errors['onLogin'] = ["blocked_login_after_unsuccessful_attempts"]
      }
      else if (result.data.error === "b2bbadcardtype") {
        errors['onLogin'] = ["not a business acount !ggg"]
      }
      else if (result.data.error === "accountTypeMismatch") {
        errors['onLogin'] = ["account_type_mismatch"]
      }
      else if (result.data.error === "rate_limit_reached") {
        errors['onLogin'] = ["login_rate_limit_reached"]
      }
      else if (result.data.error === RECAPTCHA_CHALLENGE_MAIL
        || result.data.error === RECAPTCHA_CHALLENGE_PHONE) {
        const isEmailVerification = result.data.error === RECAPTCHA_CHALLENGE_MAIL;

        this.props.history.push({
          pathname: "/VerificationCodeLogin",
          search: window.location.search
        }, {
          email: this.state.email,
          rateLimitError: "enter_existing_card_page_rate_limit_reached",
          resendUrl: "api/account/resend-two-factor",
          returnUrl: "login",
          titleTranslation: isEmailVerification ? "two_factor_code_page_title" : "activation_code_page_title",
          infoMessageTranslation: isEmailVerification ? "verification_code_login_info_message" : "",
          activationCodeFieldLabelTranslation: "confirmation_code_label",
          didNotReceiveMessageTranslation: isEmailVerification
            ? "verification_code_did_not_receive_message"
            : "card_replacement_activation_code_page_try_again",
          usesCustomRedirect: true,
          recaptchaTwoFactorVerificationFlow: true,
          usingBusinessLogin: this.state.businessLogin,
          customRedirectRoute: "/login",
          customCodeConfirmUrl: urlTools.constructRoute("api/account/verify-two-factor"),
        });
      }
      else if (result.data.response.data === RECAPTCHA_ERROR) {
        errors['onLogin'] = [RECAPTCHA_ERROR]
      }

      if (result.data.error !== "invalidConsents" && result.data.error !== "oldConsents") {
        this.setState({ showSpinner: false });
      }
    }
    else {
      pushAccountEvent(GaEventActionConstants.UserLoginSuccess);

      webTools.reloadToRoute();
    }
    if ((Object.keys(errors).length > 0 || error) && clearPassword) {
      model.password = "";
    }

    this.setState({ errors, model });

    return error;
  }

  IsEmailValid() {
    const isEmailStructureValid = EmailAndPhoneValidator.IsEmailStructureValid(this.state.email);
    const isBirthDateValid = !!this.state.birthDate || this.state.businessLogin;

    if (!isEmailStructureValid || !isBirthDateValid) {
      this.setState({
        error: true,
        emailError: !isEmailStructureValid ? "empty_email_error_message" : "",
        birthDateError: !isBirthDateValid
          ? "enter_email_invalid_birth_date_error"
          : ""
      });

      return false;
    }

    return true;
  }

  getEmailSetApiCall = () => urlTools.constructRoute("api/EmailSet");
  getEmailSetPayload = () => ({ email: this.state.email, birthDate: this.state.birthDate });

  addEmail = async () => {
    if (this.IsEmailValid()) {
      const result = await fetcherApi.post(this.getEmailSetApiCall(), this.getEmailSetPayload());
      const response = result.response.data;

      if (response.success) {
        this.setState({ showEmailSent: true, showEnterEmail: false });
        return;
      } else if (response.error === "verification_resend_limit_reached" || response.error === "rate_limit_reached") {
        this.setState({
          emailError: "missing_email_rate_limit_error"
        })
        return;
      }

      this.setState({
        error: true,
        emailError: !response.resultObject.isEmailUnique ? "account_found_but_no_email_page_in_login_duplicate_email_error" : "",
        birthDateError: !response.resultObject.isBirthDateValid ? "enter_email_invalid_birth_date_error" : ""
      });
    }
  }

  getCreateAccountPath() {

    if (this.state.businessLogin === true) {
      return '/b2bregistration/CreateNewB2bAccount';
    }

    return '/registration/ExistingCard';
  }

  logout = async () => {
    window.history.replaceState({}, '');
    AuthService.logout();
  }

  showVerificationForm() {

    const { showEmailSent, showEnterEmail } = this.state;

    if (showEnterEmail) {
      return (
        <div className="container">
          <div className="card">
            <header className="card-header">
              <RimiLogoHeaderButton initialData={this.props.initialData} />
              <a onClick={this.logout} className="link-button -pointer -with-left-icon"><svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m15 6-6 6 6 6" fill="none" stroke="#a12971" strokeWidth="2" /></svg>
                <TranslationText text='step_back_button_label' />
              </a>
            </header>
            <div className={this.state.error ? ("card-content") : ("card-content no-padding-bottom")}>
              <h1>
                <Trans i18nKey='account_found_but_no_email_page_in_login_title'>
                  Your My Rimi account is <b>missing e-mail address information</b>
                </Trans>

              </h1>
              <p>
                <TranslationText text='account_found_but_no_email_page_in_login_info' />
              </p>

              <div className="form-group">
                <div className="form-row">
                  <div className={!this.state.error ? "form-field" : "form-field -has-error"}>

                    <label htmlFor="email" className="form-field__label">
                      <TranslationText text='email_field_label' />
                    </label>
                    <InputText
                      onChange={this.onAddEmail}
                      prop="email"
                      value={this.state.email}
                      className="form-field__input"
                      placeholder="example@gmail.com"
                    />
                  </div>
                </div>
                {
                  !this.state.businessLogin ? (
                    <div className="form-row">
                      <div className={`form-field ${this.state.error ? "-has-error" : ""}`}>
                        <label htmlFor="birthDate" className="form-field__label">
                          <TranslationText text="enter_email_birth_date_input_label" />
                        </label>
                        <DateInput
                          initialDateString={this.state.birthDate}
                          onChange={this.onBirthDateChange}
                          errorMessage={this.state.emailError || this.state.birthDateError}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="form-row">
                      <div className="sublabel error-msg show">
                        <span>
                          <TranslationText text={this.state.emailError} />
                        </span>
                      </div>
                    </div>
                  )
                }
              </div>
              <div className="form-button full-width">
                <button className="button" onClick={this.addEmail}>
                  <TranslationText text='account_found_but_no_email_page_in_login_send_link_btn' />
                </button>
              </div>
            </div>
            <footer className="card-footer no-border">
              <div className="footer-text">
                <ConsentLinks business={this.state.businessLogin} />
              </div>
            </footer>
          </div>
        </div>)

    }
    else if (showEmailSent) {
      return (<React.Fragment>
        <div className="container">
          <div className="card">
            <header className="card-header">
              <RimiLogoHeaderButton initialData={this.props.initialData} />
              {this.state.emailHint.length > 0 ?
                (<a onClick={this.logout} className="link-button -pointer -with-left-icon"><svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m15 6-6 6 6 6" fill="none" stroke="#a12971" strokeWidth="2" /></svg>
                  <TranslationText text='step_back_button_label' />
                </a>) : null}

            </header>
            <div className="card-content">
              <div className="email-sent">
                {this.state.emailHint.length === 0 ?
                  <Redirect
                    to={{
                      pathname: '/VerificationEmailSent',
                      state: { apiCall: this.getEmailSetApiCall(), payload: this.getEmailSetPayload() }
                    }} />
                  :
                  (<React.Fragment>
                    <h1>
                      <TranslationText text='verify_your_email_page_title' />
                    </h1>
                    <p>
                      <TranslationText text='verify_your_email_page_info' />
                    </p>
                    <p>({this.state.emailHint})</p>
                  </React.Fragment>)
                }
              </div>
            </div>
            <React.Fragment>
              {
                this.state.emailHint.length > 0 ?
                  <footer className="card-footer no-border">
                    <div className="footer-text">
                      <ConsentLinks business={this.state.businessLogin} />
                    </div>
                  </footer>
                  : null
              }
            </React.Fragment>
          </div>
        </div>
      </React.Fragment>
      );
    }
  }

  resetInitialState = event => {
    this.setState({
      model: { username: "", password: "" },
      phoneError: false,
      errors: {},
      selectedCountry: null,
      clientId: "",
      legalEntity: "",
      showSpinner: true,
      selectedPrefix: "",
      passwordControlePlainTextMode: false,
      businessLogin: false,
      maxPhoneLength: "",
      oldConsents: false,
      setConsents: false,
      showEmailSent: false,
      emailHint: "",
      email: "",
      emailError: "",
      showEnterEmail: false,
    });
    this.setInitialState();
  }

  showConsentLinks() {
    return <div className="footer-text">
      <ConsentLinks business={this.state.businessLogin} />
    </div>
  }

  showFooter() {

    if (!this.state.phoneError) {
      return <footer className="card-footer">
        <div className="form-button full-width">
          <Link className="button -secondary" to={{ pathname: this.getCreateAccountPath() }}>
            <TranslationText text={'login_page_sign_up_btn'} />
          </Link>
        </div>
        {this.showConsentLinks()}
      </footer>
    }
    else {
      return <footer className="card-footer no-border">
        {this.showConsentLinks()}
      </footer>
    }
  }

  showLoginForm() {
    const { errors, businessLogin, model, isMobileApp, preventBusinessLogin, passwordControlePlainTextMode, phoneError } = this.state;

    return <>
      <div className="container">
        <div className="card">
          {isMobileApp ? (<React.Fragment />) :
            <header className="card-header">
              <RimiLogoHeaderButton initialData={this.props.initialData} />
              {preventBusinessLogin ? null :
                (
                  <a href="#" onClick={this.switchLogin} className="link-button -with-right-icon">
                    <TranslationText text={businessLogin ? 'b2b_login_page_private_account_btn' : 'login_page_account_type_btn'} />
                    <svg height="24" viewBox="0 0 26 24" width="26" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="#a12971" strokeWidth="2" transform="translate(2 4)"><path d="m10.4868162 3.0028106c.0751208-1.68496648-1.3545967-3.0028106-3.02542757-3.0028106-1.67083086 0-3.12114597 1.32915262-3.0254276 3.0028106.019386.33642788.336024 2.00241224.336024 2.00241224.261711 1.44587249 1.20435524 2.6684021 2.6894036 2.6684021 1.48504837 0 2.40911436-1.20435524 2.68940357-2.6684021 0 0 .3210807-1.66558049.336024-2.00241224z" /><path d="m14.9207579 15.3474114h-14.9207579l.25282575-2.8973992c.13812525-1.1029826 1.23949236-1.8606521 2.37801598-2.0819756l4.82953721-.63771862 4.88163706.65346972c1.2641288.2241506 2.3299549.924066 2.4119415 2.1990994z" /><path d="m18.3629441 4.99593371c.0601774-1.32996036-1.0835966-2.37074623-2.4204229-2.37074623-1.3364223 0-2.4967552 1.04926724-2.4204228 2.37074623.0157511.26615363.2689807 1.58036287.2689807 1.58036287.2092073 1.14135074.9632419 2.10701586 2.1514421 2.10701586 1.1882003 0 1.9272915-.95112562 2.1514422-2.10701586 0 0 .2568645-1.31420924.2689807-1.58036287z" /><path d="m15.941875 10.5009115 3.9886695.4802073c.9684923.1736663 1.8537863.7362642 1.932138 1.7649338l.1373175 2.6013588h-4.6445625" /></g></svg>
                  </a>
                )
              }
            </header>
          }
          <div className="card-content">
            <h1>
              <Trans i18nKey={businessLogin ? 'b2b_login_page_title' : 'login_page_title'}>
                Welcome back to <b>RIMI!</b>
              </Trans>
            </h1>

            <form onSubmit={this.handleSubmit}>
              {phoneError ? (this.getPhoneFields()) :
                (
                  <React.Fragment>
                    <div className={`form-field no-margin-bottom 
                                        ${(errors['username'] !== undefined && errors['username'].length > 0)
                        || (errors['onLogin'] !== undefined && errors['onLogin'].length > 0)
                        ? "-has-error" : ""}`}>
                      <label htmlFor="email_or_phone" className="form-field__label"><TranslationText text={'login_page_username_title'} /></label>
                      <InputText
                        prop="username"
                        className="form-field__input"
                        value={model.username}
                        onChange={this.onChange}
                      />
                      <Link className="link-button link-button-small" to={{ pathname: "/PasswordRecovery/RemindEmail" }}
                        tabIndex={-1}>
                        <TranslationText text="login_page_remind_email_btn" />
                      </Link>
                    </div>
                    <label className="sublabel error-msg show padding-bottom">
                      <TranslationText text={errors['username']} />
                    </label>
                  </React.Fragment>
                )
              }

              <div className={(errors['password'] !== undefined && errors['password'].length > 0)
                || (errors['onLogin'] !== undefined && errors['onLogin'].length > 0)
                ? "form-field password-form-field -has-error" : "form-field password-form-field"}>
                <label htmlFor="password" className="form-field__label">
                  <TranslationText text={'login_page_password_title'} />
                </label>

                <div className="form-field-container">
                  <InputText
                    prop="password"
                    className="form-field__input"
                    value={model.password}
                    onChange={this.onChange}
                    type={passwordControlePlainTextMode ? "text" : "password"}
                  />
                  <a
                    href="#"
                    className={passwordControlePlainTextMode ? "form-field__card-icon togglePassword show" : "form-field__card-icon togglePassword"}
                    onClick={this.onTogglePasswordShow}
                    tabIndex={-1}>
                    <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2" transform="translate(3 6)"><path d="m18 5.47826087s-4.0292609 5.47826083-9 5.47826083c-4.97073913 0-9-5.47826083-9-5.47826083s4.02926087-5.47826087 9-5.47826087c4.9707391 0 9 5.47826087 9 5.47826087z" /><path d="m11.7391304 5.47826087c0 1.51317391-1.2263478 2.73913043-2.7391304 2.73913043-1.51278261 0-2.73913043-1.22595652-2.73913043-2.73913043 0-1.5123913 1.22634782-2.73913044 2.73913043-2.73913044 1.5127826 0 2.7391304 1.22673914 2.7391304 2.73913044z" /></g></svg>
                  </a>
                </div>
                <Link className="link-button link-button-small" to={{ pathname: "/PasswordRecovery/EnterEmail", state: { isBusinessLogin: this.state.businessLogin } }}
                  tabIndex={-1}>
                  <TranslationText text="login_page_remind_password_btn" />
                </Link>
                <label className="sublabel error-msg show padding-bottom">
                  <TranslationText text={errors['password']} />
                  {errors['passwordValidation']}
                  <TranslationText text={errors['onLogin']} />
                </label>
              </div>
              {
                this.state.beginRecaptchaValidation !== undefined
                  ? <GoogleReCaptcha onVerify={this.completeRecaptchaValidation} refreshReCaptcha={this.state.beginRecaptchaValidation} />
                  : null
              }
              {!this.state.phoneError ?
                <React.Fragment>
                  <TryAgainFooterButtons fromLogin={true} isB2bAccount={this.state.businessLogin} confirm={this.beginRecaptchaValidation} isProcessing={this.state.isProcessing} />
                </React.Fragment>
                :
                <React.Fragment>
                  <TryAgainReturnToLoginButtons Login={this.beginRecaptchaValidation} resetInitialState={this.resetInitialState} />
                </React.Fragment>
              }
            </form>
          </div>
          {this.showFooter()}
        </div>
      </div >
    </>
  }

  render() {
    const { showEmailSent, showEnterEmail, selectedCountry, showSpinner, } = this.state;

    if (showSpinner) {
      return <Spinner />
    }
    else if (selectedCountry === null) {
      return <Redirect to={"/CountrySelection" + location.search} />
    }
    else {
      return <React.Fragment>
        <div>
          {
            (showEmailSent || showEnterEmail) ? this.showVerificationForm() : this.showLoginForm()
          }
        </div>
        <Footer countrySelectionEnabled={true} />
      </React.Fragment>
    }

  }
}

export default withRouter(withTranslation()(Login));


