import React, { Component } from "react";
import InputText from "../components/InputText.jsx";
import validator from "../components/FieldValidator.jsx";
import "regenerator-runtime/runtime";
import fetcherApi from '../components/FetcherApi.jsx';
import Spinner from '../profile/Information/Spinner.jsx';
import Tippy from '@tippyjs/react';
import { Link, withRouter } from "react-router-dom";
import LegalEntityFunctions from "../components/LegalEntityFunctions.jsx";
import Footer from "../components/Footer.jsx";
import ConsentLinks from '../components/Consents/ConsentLinks.jsx';
import * as constants from "../constants/constants.jsx";
import * as validationConstants from "../constants/validationConstants.jsx";
import TranslationText from "../components/Translation/TranslationText.jsx";
import { Trans, withTranslation } from "react-i18next";
import EmailAndPhoneValidator from '../components/EmailAndPhoneValidator.jsx';
import i18n from "translations/i18n.jsx";
import urlTools from "../tools/urlTools.jsx";
import { LATVIAN_LEGAL_ENTITY_ID } from "../constants/legalEntityIds.js";
import RimiLogoHeaderButton from "components/components/HeaderComponents/RimiLogoHeaderButton.jsx";
import { PasswordRuleChecklistOverlay } from "components/components/PasswordRuleChecklist/PasswordRuleChecklistOverlay.jsx";

class B2bData extends Component {

	state = {
		phonePrefixes: {},
		availableCountries: this.props.initialData.availableCountries.map(data => ({ label: data.value, value: data.key })),
		phonePrefixes: this.props.initialData.phonePrefixes.map(data => ({ label: data.value, value: data.key })),
		password: { newpassword: "", confirmpassword: "" },
		errors: {},
		clientData: null,
		companyInformation: null,
		passwordControlePlainTextMode: false,
		mandatoryFieldsErrors: {},
		confirmPasswordControlePlainTextMode: false,
		maxPhoneLength: "",
		selectedPrefix: "",
		consentsAccepted: false,
		errorText: "",
		initialPhone: "",
		zipCodeMaxLength: validationConstants.ZIP_CODE_MAX_LENGTH_DEFAULT
	};

	async componentDidMount() {
		const selectedCountry = localStorage.getItem(constants.SELECTED_COUNTRY);
		const legalEntityId = LegalEntityFunctions.getLegalEntityId(selectedCountry);

		const result = {
			clientData: {
				// location.state is filled in code flow, initialData is filled in link flow
				email: this.props.location.state?.email ?? this.props.initialData.individualRegistrationProfileData.email,
				phonePrefix: LegalEntityFunctions.getPhonePrefix(selectedCountry),
				phonePrefixId: LegalEntityFunctions.getPhonePrefixId(selectedCountry),
				clientId: 0,
				firstName: "",
				lastName: "",
				phone: ""
			},
			companyInformation: {
				countryId: parseInt(legalEntityId),
				companyName: "",
				registrationNumber: "",
				vatRegistrationNumber: "",
				legalAddress: "",
				city: "",
				zipCode: ""
			}
		};

		this.setState({
			maxPhoneLength: validator.getPhoneMinLength(this.props.initialData.phonePrefixes, result.clientData.phonePrefix),
			selectedPrefix: result.clientData.phonePrefix,
			clientData: result.clientData,
			companyInformation: result.companyInformation,
			selectedPrefix: result.clientData.phonePrefix,
			initialPhone: `${result.clientData.phonePrefix}${result.clientData.phone}`,
			zipCodeMaxLength: legalEntityId === LATVIAN_LEGAL_ENTITY_ID
				? validationConstants.ZIP_CODE_MAX_LENGTH_LATVIAN
				: validationConstants.ZIP_CODE_MAX_LENGTH_DEFAULT
		});
	}

	createSelectItems() {
		const { phonePrefixes } = this.state;
		let items = [];
		for (let i = 0; i < phonePrefixes.length; i++) {
			let label = phonePrefixes[i].label;
			let key = phonePrefixes[i].value;
			items.push(<option key={label} value={key}>{label}</option>);
		}
		return items;
	}

	createSelectCountryItems() {
		const { availableCountries } = this.state;
		let items = [];
		for (let i = 0; i < availableCountries.length; i++) {
			let label = availableCountries[i].label;
			let key = availableCountries[i].value;
			let translationKey = 'country_name_' + label.toLowerCase();
			items.push(<option key={label} value={key}>{i18n.t(translationKey)}</option>);
		}
		return items;
	}

	onChange = e => {
		const { clientData, errors } = this.state;

		if (e.prop === 'lastName' || e.prop === 'firstName') {
			clientData[e.prop] = validator.blockUnwantedSymbols(e.value);
		}
		else {
			clientData[e.prop] = validator.validate(e.prop, e.value);
		}
		errors[e.prop] = [];
		this.setState({ clientData, errors, errorText: "" });
	};

	onChangeCompany = e => {
		const { companyInformation, errors, mandatoryFieldsErrors } = this.state;

		companyInformation[e.prop] = validator.validate(e.prop, e.value);
		errors[e.prop] = [];

		this.setState({ companyInformation, errors, mandatoryFieldsErrors });
	};

	onTogglePasswordShow = e => {
		e.preventDefault();
		this.setState({ passwordControlePlainTextMode: !this.state.passwordControlePlainTextMode });
	};

	onToggleConfirmPasswordShow = e => {
		e.preventDefault();
		this.setState({ confirmPasswordControlePlainTextMode: !this.state.confirmPasswordControlePlainTextMode });
	};

	onChangePassword = e => {
		const { errors, password } = this.state;
		errors[e.prop] = [];
		password[e.prop] = e.value;
		this.setState({ password, errors, errorText: "" });
	};

	validatePasswords() {
		const { errors, password } = this.state;
		if (password.newpassword === null || password.newpassword === undefined || password.newpassword.length === 0) {
			errors['newpassword'] = ['b2b_login_page_empty_pwd_error'];
		}
		else {
			errors['newpassword'] = validator.validatePassword(password.newpassword);
			errors['confirmpassword'] = validator.validatePasswords(password.newpassword, password.confirmpassword);
		}
		this.setState({ password, errors });
	}

	hasErrorsOnSave() {
		let foundErrors = false;
		const { errors, mandatoryFieldsErrors } = this.state;

		for (var fieldError in mandatoryFieldsErrors) {
			if (!!mandatoryFieldsErrors[fieldError] && mandatoryFieldsErrors[fieldError].length > 0) {
				errors[fieldError] = mandatoryFieldsErrors[fieldError]
				foundErrors = true;
			}
		}

		if (!foundErrors) {
			this.setState({ mandatoryFieldsErrors: {} });
		}

		this.setState({ errors });
		return foundErrors;
	};

	hasErrors = () => {
		const { errors } = this.state;
		for (var error in errors) {
			if (errors[error] && errors[error].length) {
				return true;
			}
		}

		return false;
	};

	onDropDownChange = e => {
		const { clientData, errors } = this.state;

		let index = e.nativeEvent.target.selectedIndex;
		let prefix = e.nativeEvent.target[index].text;
		let phoneMinLength = validator.getPhoneMinLength(this.props.initialData.phonePrefixes, prefix);

		clientData.phonePrefixId = e.target.value;
		clientData.phonePrefix = prefix;
		errors.phone = undefined;

		this.setState({
			clientData,
			selectedPrefix: prefix,
			maxPhoneLength: phoneMinLength,
			errors,
			errorText: ""
		});
	};

	onDropDownCountryChange = e => {
		const { companyInformation } = this.state;
		companyInformation.countryId = parseInt(e.target.value);
		this.setState({ companyInformation, errorText: "" });
	};

	handleChangeCompanyInformation = e => {
		const { value, checked } = e.target;
		const { companyInformation, errors } = this.state;

		companyInformation[value] = checked;
		this.setState({ companyInformation, errors, errorText: "" });
	};

	handleChange = e => {

		const { name, value, checked } = e.target;
		const { clientData, errors } = this.state;

		if (value === 'consentsAccepted') {
			errors['consents'] = [];
			this.setState({ consentsAccepted: checked });
		}
		else {
			clientData[value] = checked;
		}
		this.setState({ clientData, errors, errorText: "" });
	};

	validateMandatoryFields(model) {
		const { mandatoryFieldsErrors } = this.state;
		const validations = this.props.initialData.validations;
		let legalEntityId = this.props.initialData.legalEntityId;

		Object.keys(model).map(function (keyName, keyIndex) {
			mandatoryFieldsErrors[keyName] = validator.validateMandatoryField(keyName, model[keyName], legalEntityId, true,
				validations);
		})
		this.setState(mandatoryFieldsErrors);
	}

	async IsPhoneUnique() {
		const { errors } = this.state;

		let duplicatePhoneError = false;
		let result = true;

		const phoneUnique = await EmailAndPhoneValidator.IsPhoneUnique(this.state.clientData.phone, this.state.selectedPrefix, "BUSINESS", this.state.clientData.clientId);
		if (!phoneUnique.data.success) {
			errors.rateLimit = phoneUnique.errorStandardOrRateLimit;
			result = false;
		}
		else if (!phoneUnique.data.resultObject.unque) {
			errors.phone = 'b2b_add_your_data_page_existing_phone_field_error';
			duplicatePhoneError = true;
			result = false;
		}

		this.setState({ duplicatePhoneError, errors });
		return result;
	}

	save = async e => {
		const { clientData, companyInformation, errors, password, selectedPrefix, maxPhoneLength, consentsAccepted, initialPhone } = this.state;
		e.preventDefault();

		errors.rateLimit = "";
		this.setState({ errors });

		this.validatePasswords();
		this.validateMandatoryFields(clientData);
		this.validateMandatoryFields(companyInformation);
		const selectedCountry = localStorage.getItem(constants.SELECTED_COUNTRY);

		if (!companyInformation.countryId) {
			companyInformation.countryId = parseInt(LegalEntityFunctions.getLegalEntityId(selectedCountry));
		}

		let error = false;

		if (!consentsAccepted) {
			errors['consents'] = ['b2b_add_your_data_page_consents_error'];
			error = true;
		}

		if (this.hasErrorsOnSave()) {
			error = true;
		}

		if (this.hasErrors()) {
			error = true;
		}

		if (!!clientData.phone) {

			let phoneError = validator.validatePhonePrefixes(this.props.initialData.phonePrefixes, selectedPrefix, clientData.phone);
			if (!!phoneError) {
				errors["phone"] = phoneError;
				error = true;
			}

			const isPhoneUniquenessValidationSkipped = !!error;
			if (!isPhoneUniquenessValidationSkipped) {
				const isPhoneUnique = await this.IsPhoneUnique();
				if (!isPhoneUnique) {
					error = true;
				}
			}
		}

		if (!error) {
			clientData.languageCode = localStorage.getItem(constants.SELECTED_LANGUAGE);;
			clientData.password = password.newpassword;

			const data = {
				clientData,
				companyInformation,
			};

			const registrationDataUrl = urlTools.constructRoute("api/B2BRegistrationCode/Add-Data/" + LegalEntityFunctions.getLegalEntityId(selectedCountry));
			const result = (await fetcherApi.post(registrationDataUrl, JSON.stringify(data))).response;

			if (result.data.success) {
				this.setState({ clientData, companyInformation, errors: {}, mandatoryFieldsErrors: {}, selectedPrefix, maxPhoneLength });
				this.props.history.push("/b2bregistration/CongratulationsB2b");
			}
			else {
				this.setState({ error: true, errorText: result.data.error });
			}
		}
		else {
			this.setState({ errors, selectedPrefix, maxPhoneLength, password });
		}

	};

	render() {
		const { clientData, availableCountries, companyInformation, maxPhoneLength, errors, password, passwordControlePlainTextMode, confirmPasswordControlePlainTextMode, consentsAccepted } = this.state;

		return (clientData !== null && companyInformation !== null && availableCountries !== null) ? (
			<React.Fragment>
				<div className="container">
					<div className="card card-full-width create-account-page">
						<header className="card-header">
							<RimiLogoHeaderButton initialData={this.props.initialData} />
						</header>
						<div className="card-content">
							<h1>
								<Trans i18nKey="b2b_add_your_data_page_title">
									New account creation. <b>Please fill information about yourself and the company.</b>
								</Trans>
							</h1>
							<hr />
							<h2>
								<TranslationText text='b2b_add_your_data_page_comp_representative_information_title' />
							</h2>
							<div className="row">

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['firstName'] && errors['firstName'].length > 0 ? "form-field  -has-error" : "form-field") + " -required"}>
												<label htmlFor="first_name" className="form-field__label">
													<TranslationText text='b2b_add_your_data_page_fname_field_title' />
												</label>
												<InputText
													onChange={this.onChange}
													prop="firstName"
													value={clientData.firstName || ""}
													maxLength="50"
													className="form-field__input"
													type="text"
												/>
											</div>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.firstName} />
											</span>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['lastName'] && errors['lastName'].length > 0 ? "form-field  -has-error" : "form-field") + " -required"}>
												<label htmlFor="last_name" className="form-field__label">
													<TranslationText text='b2b_add_your_data_page_lname_field_title' />
												</label>
												<InputText
													onChange={this.onChange}
													prop="lastName"
													value={clientData.lastName || ""}
													maxLength="50"
													className="form-field__input"
													type="text"
												/>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.lastName} />
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className="form-field -required">
												<label htmlFor="email" className="form-field__label">
													<TranslationText text='email_field_label' />
												</label>
												<input className="form-field__input" type="email" name="email" id="email" value={clientData.email} readOnly />
											</div>
										</div>
									</div>
								</div>

							</div>

							<div className="row">
								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className="form-field -required">
												<label htmlFor="country_code" className="form-field__label">
													<TranslationText text='b2b_add_your_data_page_phone_field_title' />
												</label>
											</div>
										</div>
										<div className={!!errors['phone'] && errors['phone'].length > 0 ? "form-row -phone-number  -has-error" : "form-row -phone-number"}>
											<div className="form-field form-field__select">
												<select value={clientData.phonePrefixId} onChange={this.onDropDownChange}>
													{this.createSelectItems()}
												</select>
												<svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m22.7 12.7-6.7 6.6-6.7-6.6" fill="none" stroke="#a12971" strokeMiterlimit="10" strokeWidth="2" /></svg>                            </div>
											<div className="form-field">
												<InputText
													onChange={this.onChange}
													prop="phone"
													value={clientData.phone || ""}
													className="form-field__input -tel"
													type="tel"
													maxLength={maxPhoneLength}
												/>
											</div>
										</div>
										<div className="form-row">
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.phone} />
												</span>
												{this.state.duplicatePhoneError ?
													(<Link className="link-button link-button-large" to={{ pathname: '/passwordrecovery/enteremail' }}>
														<TranslationText text='add_your_data_page_pwd_recovery_btn' />
													</Link>)
													: <div></div>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<hr />

							<h2>
								<TranslationText text='b2b_profile_information_page_comp_info_field_title' />
							</h2>

							<div className="row start">

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['companyName'] && errors['companyName'].length > 0 ? "form-field  -has-error" : "form-field") + " -required"}>
												<label htmlFor="company-name" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_comp_name_field_title' />
												</label>

												<InputText
													onChange={this.onChangeCompany}
													prop="companyName"
													value={companyInformation.companyName || ""}
													className="form-field__input"
													type="text"
													maxLength={validationConstants.COMPANY_NAME_MAX_LENGTH}
												/>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.companyName} />
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['registrationNumber'] && errors['registrationNumber'].length > 0 ? "form-field  -has-error" : "form-field") + " -required"}>
												<label htmlFor="company_registration_number" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_reg_number_field_title' />
												</label>
												<InputText
													onChange={this.onChangeCompany}
													prop="registrationNumber"
													value={companyInformation.registrationNumber || ""}
													className="form-field__input"
													type="tel"
													maxLength="12"
												/>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.registrationNumber} />
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={!!errors['vatRegistrationNumber'] && errors['vatRegistrationNumber'].length > 0 ? "form-field -has-error" : "form-field  "}>
												<label htmlFor="company_vat_number" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_vat_number_field_title' />
												</label>
												<InputText
													onChange={this.onChangeCompany}
													prop="vatRegistrationNumber"
													value={companyInformation.vatRegistrationNumber || ""}
													className="form-field__input"
													type="tel"
													maxLength="14"
												/>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.vatRegistrationNumber} />
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row -required">
											<label htmlFor="country" className="form-field__label">
												<TranslationText text='b2b_profile_information_page_country_field_title' />
											</label>
											<div className="form-field form-field__select">
												<select value={companyInformation.countryId} onChange={this.onDropDownCountryChange}>
													{this.createSelectCountryItems()}
												</select>
												<svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m22.7 12.7-6.7 6.6-6.7-6.6" fill="none" stroke="#a12971" strokeMiterlimit="10" strokeWidth="2" /></svg>                            </div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['legalAddress'] && errors['legalAddress'].length > 0 ? "form-field -has-error" : "form-field") + " -required"}>
												<label htmlFor="company_legal_address" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_address_field_title' />
												</label>
												<InputText
													onChange={this.onChangeCompany}
													prop="legalAddress"
													value={companyInformation.legalAddress || ""}
													className="form-field__input"
													type="text"
													maxLength={validationConstants.LEGAL_ADDRESS_MAX_LENGTH}
												/>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.legalAddress} />
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['city'] && errors['city'].length > 0 ? "form-field  -has-error" : "form-field") + " -required"}>
												<label htmlFor="company_city_region" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_city_field_title' />
												</label>
												<InputText
													onChange={this.onChangeCompany}
													prop="city"
													value={companyInformation.city || ""}
													className="form-field__input"
													type="text"
													maxLength={validationConstants.CITY_MAX_LENGTH}
												/>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.city} />
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={!!errors['subTown'] && errors['subTown'].length > 0 ? "form-field  -has-error" : "form-field"}>
												<label htmlFor="company_city_parish" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_subtown_field_title' />
												</label>
												<InputText
													onChange={this.onChangeCompany}
													prop="subTown"
													value={companyInformation.subTown || ""}
													className="form-field__input"
													type="text"
													maxLength="50"
												/>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.subTown} />
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['zipCode'] && errors['zipCode'].length > 0 ? "form-field  -has-error" : "form-field") + " -required"}>
												<label htmlFor="company_city_postcode" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_zip_field_title' />
												</label>
												<InputText
													onChange={this.onChangeCompany}
													prop="zipCode"
													value={companyInformation.zipCode || ""}
													className="form-field__input"
													type="text"
													maxLength={this.state.zipCodeMaxLength}
												/>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.zipCode} />
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<hr />
							<div className="row start">
								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['newpassword'] && errors['newpassword'].length > 0 ? "form-field  -has-error" : "password-form-field") + " -required"}>
												<label htmlFor="password" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_new_pwd_field_title' />
												</label>
												<PasswordRuleChecklistOverlay password={password.newpassword} neutralRulesDisabled={true}>
													<div className="form-field-container">
														<InputText
															onChange={this.onChangePassword}
															prop="newpassword"
															value={password.newpassword || ""}
															type={passwordControlePlainTextMode ? "text" : "password"}
															id="newpassword"
															placeholder=""
															className="form-field__input"
														/>
														<a href="#" className={passwordControlePlainTextMode ? "form-field__card-icon togglePassword show" : "form-field__card-icon togglePassword"} onClick={this.onTogglePasswordShow}>
															<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2" transform="translate(3 6)"><path d="m18 5.47826087s-4.0292609 5.47826083-9 5.47826083c-4.97073913 0-9-5.47826083-9-5.47826083s4.02926087-5.47826087 9-5.47826087c4.9707391 0 9 5.47826087 9 5.47826087z" /><path d="m11.7391304 5.47826087c0 1.51317391-1.2263478 2.73913043-2.7391304 2.73913043-1.51278261 0-2.73913043-1.22595652-2.73913043-2.73913043 0-1.5123913 1.22634782-2.73913044 2.73913043-2.73913044 1.5127826 0 2.7391304 1.22673914 2.7391304 2.73913044z" /></g></svg>
														</a>
													</div>
												</PasswordRuleChecklistOverlay>
												<div className="sublabel error-msg show">
													<span>
														<TranslationText text={errors.newpassword} />
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['confirmpassword'] && errors['confirmpassword'].length > 0 ? "form-field  -has-error" : "password-form-field") + " -required"}>
												<label htmlFor="passwordRepeat" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_repeat_pwd_field_title' />
												</label>
												<div className="form-field-container">
													<InputText
														onChange={this.onChangePassword}
														prop="confirmpassword"
														value={password.confirmpassword || ""}
														type={confirmPasswordControlePlainTextMode ? "text" : "password"}
														id="passwordRepeat"
														placeholder=""
														className="form-field__input"
													/>
													<a href="#" className={confirmPasswordControlePlainTextMode ? "form-field__card-icon togglePassword show" : "form-field__card-icon togglePassword"} onClick={this.onToggleConfirmPasswordShow}>
														<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2" transform="translate(3 6)"><path d="m18 5.47826087s-4.0292609 5.47826083-9 5.47826083c-4.97073913 0-9-5.47826083-9-5.47826083s4.02926087-5.47826087 9-5.47826087c4.9707391 0 9 5.47826087 9 5.47826087z" /><path d="m11.7391304 5.47826087c0 1.51317391-1.2263478 2.73913043-2.7391304 2.73913043-1.51278261 0-2.73913043-1.22595652-2.73913043-2.73913043 0-1.5123913 1.22634782-2.73913044 2.73913043-2.73913044 1.5127826 0 2.7391304 1.22673914 2.7391304 2.73913044z" /></g></svg>
													</a>
												</div>
												<div className="sublabel error-msg show">
													<span>
														<TranslationText text={errors.confirmpassword} />
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<hr />
							<div className="row">
								<div className="column-medium-7 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<h2 className="help">
												<TranslationText text='b2b_add_your_data_page_comm_channels_title' />
												<Tippy content={<span>
													<Trans i18nKey='b2b_add_your_data_page_comm_channels_info_msg'>
														E-mail will be used to send various offers and other marketing information from Rimi. SMS messages are used rarely to inform about other most relevant information.
													</Trans>
												</span>}>
													<span className="help-tip">
														<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" transform="translate(1 1)"><g stroke="currentColor" strokeWidth="2"><path d="m11.9497609 2.0502513c2.7336704 2.73367044 2.7336704 7.16583913 0 9.8994913-2.73367047 2.7336704-7.16583916 2.7336704-9.89949133 0-2.73367044-2.73367043-2.73367044-7.16583912 0-9.8994913 2.73367043-2.73367043 7.16583913-2.73367043 9.89949133 0" /><path d="m7 8.82608696v-.48695653c0-.63913043.33478261-1.24782608.91304348-1.58260869.88260869-.51739131 1.18695652-1.61304348.66956522-2.49565217-.51739131-.8826087-1.61304348-1.18695653-2.49565218-.66956522-.54782609.33478261-.91304348.91304348-.91304348 1.58260869" /></g><path d="m7.82533029 10.0601891c.43297264.4329726.43297264 1.1349602 0 1.5679334-.43297265.4329726-1.13496021.4329726-1.5679334 0-.43297265-.4329727-.43297265-1.1349602 0-1.5679334.43297264-.43297268 1.1349602-.43297268 1.5679334 0" fill="#a4afb3" /></g></svg>                                    </span>
												</Tippy>
											</h2>
										</div>
										<div className="form-row">
											<div className="form-field -checkbox">
												<div className="form-check">
													<input
														className="form-check-input"
														onChange={this.handleChangeCompanyInformation}
														checked={companyInformation.eCommerceOffersEmail}
														type="checkbox"
														value="eCommerceOffersEmail"
														id="info_email"
													/>
													<label className="form-check-label" htmlFor="info_email">
														<TranslationText text='b2b_add_your_data_page_email_box' />
														<span></span>
													</label>
												</div>
												<div className="form-check">
													<input
														className="form-check-input"
														onChange={this.handleChangeCompanyInformation}
														checked={companyInformation.eCommerceOffersSms}
														type="checkbox"
														value="eCommerceOffersSms"
														id="info_phone"
													/>
													<label className="form-check-label" htmlFor="info_phone">
														<TranslationText text='b2b_add_your_data_page_phone_box' />
														<span></span>
													</label>
												</div>
											</div>
										</div>
									</div>
									<hr className="spacer" />
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group -required">
										<div className="form-row">
											<div className="form-field -checkbox">
												<div className="form-check">
													<input className="form-check-input" onChange={this.handleChange} checked={consentsAccepted} type="checkbox" value="consentsAccepted" id="user_agreement" />
													<label className="form-check-label" htmlFor="user_agreement">
														<ConsentLinks business={true} translationKey="consent_agreement_text" />
														<span></span>
													</label>
												</div>
												<div className="sublabel error-msg show">
													<span>
														<TranslationText text={errors.consents} />
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className="form-row">
										<div className="sublabel error-msg show">
											<span>{<TranslationText text={this.state.errorText} />}</span>
										</div>
									</div>
									<div className="form-button full-width">
										<button
											type="submit"
											onClick={this.save}
											className="button full-width"
										>
											<TranslationText text='b2b_add_your_data_page_continue_btn' />
										</button>
									</div>
									{
										errors.rateLimit ? (
											<div className="form-row">
												<div className="sublabel error-msg show">
													<span>
														<TranslationText text={errors.rateLimit} />
													</span>
												</div>
											</div>
										) : null
									}
								</div>
							</div>

						</div>
					</div>
				</div>
				<Footer countrySelectionEnabled={false} legalEntity={this.props.initialData.legalEntityId} />
			</React.Fragment>

		) : <Spinner />;
	}
}

export default withRouter(withTranslation()(B2bData));


