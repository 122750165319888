import PropTypes from "prop-types";
import TranslationText from "./Translation/TranslationText";
import InputText from "./InputText";
import { useState } from "react";
import { EyeSvg } from "components/icons/EyeSvg";

export function PasswordInput(props) {
	const [isMasked, setIsMasked] = useState(true);

	return (
		<>
			<label className="form-field__label">
				<TranslationText text={props.label} />
			</label>
			<div className={props.error ? "form-field  -has-error" : "form-field"}>
				<InputText
					prop="password"
					onChange={props.onChange}
					value={props.value}
					type={isMasked ? "password" : "text"}
				/>
				<button
					tabIndex="-1"
					onClick={_ => setIsMasked(!isMasked)}
					className={`form-field__card-icon togglePassword -pointer -transparent ${!isMasked ? "show" : ""}`}
				>
					<EyeSvg />
				</button>
			</div>
			<div className="sublabel error-msg show">
				<span>
					<TranslationText text={props.error} />
				</span>
			</div>
		</>
	);
}

PasswordInput.propTypes = {
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	error: PropTypes.string
};