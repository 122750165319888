import React, { Component } from "react";
import Footer from "../../components/Footer.jsx";
import TranslationText from "../Translation/TranslationText.jsx";
import { Link } from "react-router-dom";
import RimiLogoHeaderButton from "../HeaderComponents/RimiLogoHeaderButton.jsx";

class ConfirmationNoRedirect extends Component {
  render() {
    return (<React.Fragment>
      <div className="container">
        <div className="card">
          <header className="card-header">
            <RimiLogoHeaderButton initialData={this.props.initialData} />
          </header>
          <div className="card-content">
            <div className="single-message">
              <h1><TranslationText text={this.props.titleText} /></h1>
              <p><TranslationText text={this.props.infoText} /></p>
            </div>
            <div className="form-button full-width">
              <Link className="button" to={{ pathname: '/login' }}>
                <TranslationText text='return_to_login_page' />
              </Link>
            </div>
          </div>

          <hr className="spacer" />
        </div>

        <footer className="card-footer no-border">
        </footer>
      </div>
      <Footer countrySelectionEnabled={false} />
    </React.Fragment>)
  }
}
export default ConfirmationNoRedirect;