import React, { Component } from "react";
import i18n from "translations/i18n.jsx";
import * as constants from "../../constants/constants.jsx";

class TranslationText extends Component {
    state = {
       language: i18n.language
    };
    onLanguageChanged = this.onLanguageChanged.bind(this)
    
    
    onLanguageChanged(lng) {
        this.setState({
            language: lng
        })
    }

    componentDidMount() {
        i18n.on(constants.LANGUAGE_CHANGED, this.onLanguageChanged)       
    }

    componentWillUnmount() {
        i18n.off(constants.LANGUAGE_CHANGED, this.onLanguageChanged);
        this.setState = (state, callback) => {
            return;
        }
    }

    render() {
        const incomingParameter = this.props.text;
        const key = Array.isArray(incomingParameter)
            ? (incomingParameter.length > 0 ? incomingParameter[0] : null)
            : incomingParameter;
        if (key) {
            return <React.Fragment>{i18n.t(key, this.props.options)}</React.Fragment>;
        }
        else {
            return null;
        }
    }
}

export default TranslationText;


