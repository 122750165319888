import { MIN_PASSWORD_LENGTH } from "components/constants/validationConstants";
import { containsDigit, isLowerCase, isSpecialSymbol, isUpperCase } from "../UtilityFunctions";

const PasswordValidator = {
	getRuleSatisfactionResult: input => {
		const password = input ?? "";
		return {
			isMinLengthRuleSatisfied: isMinLengthRuleSatisfied(password),
			isUppercaseLetterRuleSatisfied: isUppercaseLetterRuleSatisfied(password),
			isLowercaseLetterRuleSatisfied: isLowercaseLetterRuleSatisfied(password),
			isDigitRuleSatisfied: isDigitRuleSatisfied(password),
			isSpecialSymbolRuleSatisfied: isSpecialSymbolRuleSatisfied(password)
		};
	}
};

const isMinLengthRuleSatisfied = password => password.length >= MIN_PASSWORD_LENGTH;
const isUppercaseLetterRuleSatisfied = password => Array.from(password).some(isUpperCase);
const isLowercaseLetterRuleSatisfied = password => Array.from(password).some(isLowerCase);
const isDigitRuleSatisfied = password => containsDigit(password);
const isSpecialSymbolRuleSatisfied = password => Array.from(password).some(isSpecialSymbol);

export default PasswordValidator;