import React, { Component } from "react";
import EmailVerificationSent from "../../../components/components/Notifications/EmailVerificationSent.jsx";
import * as constants from "../../constants/notificationTypes.jsx";
import TranslationText from "../Translation/TranslationText.jsx";

class NotificationSuccess extends Component {

  state = {
    notificationText: this.props.notificationText ?? "data_change_success_popup_info",
    notificationShown: false,
    notificationType: constants.DATA_SAVED
  };

  componentDidMount() {
  }

  closeClicked = e => {
    this.setState({ notificationShown: false });
  }

  showPopup(notificationType) {
    this.setState({ notificationShown: true, notificationType });
  }

  getMessageText() {
    if (this.state.notificationType === constants.VERIFICATION_EMAIL_SENT) {
      return <React.Fragment>
        <h3>
          <TranslationText text='email_change_success_popup_title' />
        </h3>
        <EmailVerificationSent />
      </React.Fragment>;
    }
    else if(this.state.notificationType === constants.PASSWORD_CHANGED){
      return <React.Fragment>
        <h3>
          <TranslationText text='data_change_success_popup_tile' />
        </h3>
        <p>
          <TranslationText text='password_change_success_popup_info' />
        </p>
      </React.Fragment>;
    }
    else
      return <React.Fragment>
        <h3>
          <TranslationText text='data_change_success_popup_tile' />
        </h3>
        <p>
          <TranslationText text={this.state.notificationText} />
        </p>
      </React.Fragment>;
  }

  render() {
    const { notificationShown } = this.state;

    return notificationShown ? (
      <React.Fragment>
        <div className="notification-container">
          <div className="notification success show">
            <div className="notification-content">
              <div className="icon" style={{ minWidth: 72 + 'px' }}></div>
              <div className="message">

                {/* this component is used through ref */}
                {this.getMessageText()}
              </div>
              <div className="close-button" onClick={this.closeClicked}></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ) : null;
  }
}

export default NotificationSuccess;


