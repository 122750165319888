export const RATE_LIMIT_ERROR = "rate_limit_reached";
export const VERIFICATION_RESEND_LIMIT_ERROR = "verification_resend_limit_reached";
export const RECAPTCHA_ERROR = "recaptcha_suspicious_action";
export const RECAPTCHA_CHALLENGE_MAIL = "recaptcha_challenge_mail";
export const RECAPTCHA_CHALLENGE_PHONE = "recaptcha_challenge_phone";
export const MISSING_PROFILE_DATA_ERROR = "missing_profile_data";
export const DUPLICATE_EMAIL = "duplicate_email";
export const DUPLICATE_PHONE = "phone_duplicate";
export const UNEXPECTED_ERROR = "unexpected_error";
export const WRONG_PASSWORD = "wrongPassword";
export const EMAIL_VALIDATION_LIMIT_REACHED = "email_validation_limit_reached";
export const PHONE_VALIDATION_LIMIT_REACHED = "phone_validation_limit_reached";