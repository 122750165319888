import React, { Component } from "react";
import Footer from "../../components/Footer.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import RimiLogoHeaderButton from "components/components/HeaderComponents/RimiLogoHeaderButton.jsx";

class EmailVerifiedHasAccount extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="card">
            <header className="card-header">
              <RimiLogoHeaderButton initialData={this.props.initialData} />
            </header>
            <div className="card-content">
              <div className="single-message">
                <h1>
                  <TranslationText text='email_verified_page_title' />
                </h1>
                <p>
                  <TranslationText text='email_verified_page_info' />
                </p>
              </div>
            </div>
            <hr className="spacer" />
          </div>
        </div>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>
    );
  }
}

export default EmailVerifiedHasAccount;


