import * as constants from '../../constants/constants.jsx';
import LegalEntityFunctions from '../../components/LegalEntityFunctions.jsx';

export default {
  getCountry() {
    const selectedCountry = localStorage.getItem(constants.SELECTED_COUNTRY);

    return selectedCountry;
  },

  setCountry(country) {
    if (!!country) {
      localStorage.setItem(constants.SELECTED_COUNTRY, country);

      const event = new CustomEvent(constants.COUNTRY_CHANGED, { detail: country });
      window.dispatchEvent(event);
    } else {
      console.error('unable to set country. received value: ' + country);
    }
  },

  setCountryFromLegalEntity(legalEntityId) {
    var country = LegalEntityFunctions.getCountryName(legalEntityId);
    this.setCountry(country);
  }
}