import React, { Component, createRef } from "react";
import "regenerator-runtime/runtime";
import fetcherApi from '../components/FetcherApi.jsx';
import fuzzySearch from '../components/fuzzySearch.jsx';
import NotificationSuccess from '../components/Notifications/NotificationSuccess.jsx';
import SelectSearch from 'react-select-search';
import Spinner from '../profile/Information/Spinner.jsx';
import Footer from "../../components/components/Footer.jsx";
import TranslationText from "../components/Translation/TranslationText.jsx";
import i18n from "translations/i18n.jsx";
import { withTranslation } from "react-i18next";
import AutoHideableTitle from "../components/HeaderComponents/AutoHideableTitle.jsx";
import urlTools from "../tools/urlTools.jsx";

class FavouriteStore extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.notificationPopupRef = createRef();
  }
  state = {
    options: this.props.initialData.stores.map(data => ({ name: data.name, value: data.id, nameWithEnglishLettersOnly: data.nameWithEnglishLettersOnly })),
    personalizedoffers: null,
    initialPersonalizedOffers: {},
    errors: []  // todo implement if error occured on save
  };

  async componentDidMount() {
    this._isMounted = true;
    const personalizedoffers = (await fetcherApi.get(urlTools.constructRoute("api/profile/FavouriteStore/personalizedOffers"))).response.data.resultObject;

    if (this._isMounted) {
      this.setState({
        personalizedoffers,
        initialPersonalizedOffers: JSON.parse(JSON.stringify(personalizedoffers))
      })
    }
    this.createSelectItems();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState = (state, callback) => {
      return;
    }
  }

  save = async e => {
    e.preventDefault();
    const { personalizedoffers, initialPersonalizedOffers } = this.state;

    if (initialPersonalizedOffers.favouriteStoreId === personalizedoffers.favouriteStoreId) {
      console.log('no changes found, skipping update.');
      return;
    }

    await fetcherApi.put(urlTools.constructRoute("api/profile/FavouriteStore/personalizedOffers"), JSON.stringify(personalizedoffers));
    this.setState({ errors: {}, personalizedoffers, initialPersonalizedOffers: JSON.parse(JSON.stringify(personalizedoffers)) });
    this.notificationPopupRef.current.showPopup();
  };

  hasErrors = () => {
    const { errors } = this.state;

    for (var error in errors) {
      if (errors[error] && errors[error].length) {
        return true;
      }
    }
    return false;
  };

  onDropDownChange = (event, values) => {
    const { personalizedoffers } = this.state;
    personalizedoffers.favouriteStoreId = values.value !== '' ? parseInt(values.value) : 0;
    this.setState({ personalizedoffers });
  }

  createSelectItems() {
    const { options } = this.state;
    let items = [];
    for (let i = 0; i < options.length; i++) {
      let label = options[i].label;
      let key = options[i].value;
      items.push(<option key={key}>{label}</option>);
    }
    return items;
  }

  render() {
    const { personalizedoffers, options } = this.state;
    return personalizedoffers !== null ? (
      <React.Fragment>
        <div className="container page-container">
          <AutoHideableTitle text={'favourite_store_page_title'} />
          <div className="card card-full-width">
            <div className="card-content">

              <div className="page-description-row">
                <p>
                  <TranslationText text='favourite_store_page_info_title' />
                </p>
                <p>
                  <TranslationText text='favourite_store_page_info' />
                </p>
              </div>

              <hr />
              <div className="favourite-store group-row">
                <div className="row">
                  <div className="column-desktop-5 column-tablet-12">
                    <div className="form-field searchable-list">
                      <label htmlFor="search_input" className="form-field__label">
                        <TranslationText text='' />
                      </label>
                      <div id="react-select-search-root">
                        <div className="select-search">
                          <div className="select-search__value">
                            <SelectSearch value={personalizedoffers.favouriteStoreId} onChange={this.onDropDownChange} options={options} search filterOptions={fuzzySearch} name="search" placeholder={i18n.t('favourite_store_empty_page_drop_down_text')} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column-desktop-4 hidden-desktop"></div>
                  <div className="column-desktop-3 column-tablet-12">
                    <button
                      type="submit"
                      onClick={this.save}
                      className="button full-width"
                      disabled={this.hasErrors()}
                    >
                      <TranslationText text='favourite_store_page_sabe_btn' />
                    </button>
                    <NotificationSuccess ref={this.notificationPopupRef}></NotificationSuccess>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} legalEntity={this.props.initialData.legalEntityId} />
      </React.Fragment>
    ) : <Spinner />
  }
}

export default withTranslation()(FavouriteStore);