import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Spinner from "../../../components/profile/Information/Spinner.jsx";
import EmailVerifiedText from "../../../components/components/EmailVerification/EmailVerifiedText.jsx";

class EmailVerifiedPasswordRecovery extends Component {
  _isMounted = false;
  state = {
    confirmed: false,
    emailVerified: false,
    redirectTo: "",
    emailVerifiedRecently: false
  };

  componentDidMount() {
        /* leave for debug, will remove later */ console.log(this.props);
    this._isMounted = true;
    let emailVerificationFailed = this.props.initialData.emailVerificationFailed;
    if (emailVerificationFailed) {
      this.setState({ confirmed: true, redirectTo: '/PasswordRecovery/EmailExpired' });
    }
    else if (this.props.showEmailVerify) {
      this.timer = this.setState({ emailVerified: true });
      setTimeout(() => {
        this.setState({ confirmed: true, redirectTo: '/passwordrecovery/addyourdata/', emailVerifiedRecently: true });
      }, 1000)
    }
    else {
      this.setState({ confirmed: true, redirectTo: '/passwordrecovery/addyourdata/' });
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState = (state, callback) => {
      return;
    }
  }

  emailVerified() {
    if (this.state.emailVerified) {
      return <EmailVerifiedText initialData={this.props.initialData} text={'email_verified_page_info'} />
    }
    else {
      return <Spinner />
    }
  }

  render() {
    if (this._isMounted) {
      return (this.state.confirmed ? (
        <Redirect to={{
          pathname: this.state.redirectTo,
          state: {
            emailVerifiedRecently: this.state.emailVerifiedRecently
          }
        }}
        />) :
        (this.emailVerified())
      );
    }
    else {
      return <Spinner />
    }
  }
}

export default EmailVerifiedPasswordRecovery;


