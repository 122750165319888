import React, { Component } from "react";
import KidsClubImage from "../components/KidsClubImage.jsx";
import {
  BrowserRouter as Router,
  Link,
} from "react-router-dom";
import Footer from "../../components/components/Footer.jsx";
import TranslationText from "../components/Translation/TranslationText.jsx";
import fetcherApi from "../components/FetcherApi.jsx";
import Spinner from "../profile/Information/Spinner.jsx";
import AutoHideableTitle from "../components/HeaderComponents/AutoHideableTitle.jsx";
import urlTools from "../tools/urlTools.jsx";

class Clubs extends Component {
  _isMounted = false;
  state = {
    petClubButtonStyle: "",
    kidsClubButtonStyle: "",
    seniorClubButtonStyle: "",
    wineClubButtonStyle: "",
    petsClubButtonText: "",
    kidsClubButtonText: "",
    wineClubButtonText: "",
    seniorClubButtonText: "",
    showWineClub: false,
    loaded: false
  };

  async componentDidMount() {
    this._isMounted = true;
    const clubsJoined = (await fetcherApi.get(urlTools.constructRoute("api/profile/ClubsJoined/" + this.props.initialData.legalEntityId + "/GetClubs"))).response.data;
    let petsClub = clubsJoined.resultObject.petsClubJoined;
    let kidsClub = clubsJoined.resultObject.kidsClubJoined;
    let seniorClub = clubsJoined.resultObject.seniorClubJoined;
    let wineClub = clubsJoined.resultObject.wineClubJoined;
    let showWineClub = clubsJoined.resultObject.showWineClub;

    if (this._isMounted) {
      this.setState({
        petClubButtonStyle: petsClub ? "button -secondary" : "button",
        kidsClubButtonStyle: kidsClub ? "button -secondary" : "button",
        seniorClubButtonStyle: seniorClub ? "button -secondary" : "button",
        wineClubButtonStyle: wineClub ? "button -secondary" : "button",
        petsClubButtonText: petsClub ? "rimi_clubs_page_edit_btn" : "rimi_clubs_page_join_pet_club_btn",
        kidsClubButtonText: kidsClub ? "rimi_clubs_page_edit_btn" : "rimi_clubs_page_join_kids_club_btn",
        seniorClubButtonText: seniorClub ? "rimi_clubs_page_edit_btn" : "rimi_clubs_page_join_senior_club_btn",
        wineClubButtonText: wineClub ? "rimi_clubs_page_edit_btn" : "rimi_clubs_page_join_wine_club_btn",
        showWineClub: showWineClub,
        loaded: true
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState = (state, callback) => {
      return;
    }
  }

  render() {
    return !this.state.loaded ? (<Spinner />) : (
      <React.Fragment>
        <div className="container page-container">
          <AutoHideableTitle text={'rimi_clubs_page_title'} />
          <div className="card card-full-width">
            <div className="card-content">
              <div className="page-description-row">
                <p>
                  <TranslationText text='rimi_clubs_page_info_title' />
                </p>
                <p>
                  <TranslationText text='rimi_clubs_page_info' />
                </p>
              </div>
              <hr />
              <div className="row clubs-row">
                <div className="column-desktop-4 column-tablet-12">
                  <div className="club-column">
                    <Link className="club-link" to={{ pathname: '/kidsclub' }}>
                      <div className="icon">
                        <img src={KidsClubImage.getKidsClubPicture(this.props.initialData.legalEntityId)} alt="user_photo" />
                      </div>
                      <h2>
                        <TranslationText text='rimi_clubs_page_kids_club_title' />
                      </h2>
                      <p>
                        <TranslationText text='rimi_clubs_page_kids_club_info' />
                      </p>
                    </Link>
                    <Link className={this.state.kidsClubButtonStyle} to={{ pathname: '/kidsclub' }}>
                      <TranslationText text={this.state.kidsClubButtonText} />
                    </Link>
                  </div>
                </div>
                {this.props.initialData.legalEntityId === "2" ?
                  (
                    <React.Fragment>
                      <div className="column-desktop-4 column-tablet-12">
                        <div className="club-column">
                          <Link className="club-link" to={{ pathname: '/petsclub' }}>
                            <div className="icon">
                              <img src={require("assets/svg/pet-club.svg").default} alt="pet-club" />
                            </div>
                            <h2>
                              <TranslationText text='rimi_clubs_page_pet_club_title' />
                            </h2>
                            <p>
                              <TranslationText text='rimi_clubs_page_pet_club_info' />
                            </p>
                          </Link>
                          <Link className={this.state.petClubButtonStyle} to={{ pathname: '/petsclub' }}>
                            <TranslationText text={this.state.petsClubButtonText} />
                          </Link>
                        </div>
                      </div>
                      </React.Fragment>
                       ) : (<div></div>)}
                {this.props.initialData.legalEntityId === "2" && this.state.showWineClub ?  
                    (
                    <React.Fragment>
                      <div className="column-desktop-4 column-tablet-12">
                        <div className="club-column">
                          <Link className="club-link" to={{ pathname: '/wineclub' }}>
                            <div className="icon">
                              <img src={require("assets/svg/wine-club.svg").default} alt="wine-club" />
                            </div>
                            <h2>
                              <TranslationText text='rimi_clubs_page_wine_club_title' />
                            </h2>
                            <p>
                              <TranslationText text='rimi_clubs_page_wine_club_info' />
                            </p>
                          </Link>
                          <Link className={this.state.wineClubButtonStyle} to={{ pathname: '/wineclub' }}>
                            <TranslationText text={this.state.wineClubButtonText} />
                          </Link>
                        </div>
                      </div>
                    </React.Fragment>
                 ) : (<div></div>)}
                {this.props.initialData.legalEntityId === "1" ?
                  (
                    <React.Fragment>
                      <div className="column-desktop-4 column-tablet-12">
                        <div className="club-column">
                          <Link className="club-link" to={{ pathname: '/seniorsclub' }}>
                            <div className="icon">
                              <img src={require("assets/img/senior-club.jpg")} alt="senior-club" />
                            </div>
                            <h2>
                              <TranslationText text='rimi_clubs_page_senior_club_title' />
                            </h2>
                            <p>
                              <TranslationText text='rimi_clubs_page_senior_club_info' />
                            </p>
                          </Link>
                          <Link className={this.state.seniorClubButtonStyle} to={{ pathname: '/seniorsclub' }}>
                            <TranslationText text={this.state.seniorClubButtonText} />
                          </Link>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (<div></div>)}
              </div>
            </div>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} legalEntity={this.props.initialData.legalEntityId} />
      </React.Fragment>
    );
  }
}

export default Clubs;