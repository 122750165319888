import axios from 'axios';
import * as constants from "../constants/constants.jsx";
import webTools from "../tools/webTools.js";
import AuthService from "components/services/AuthService.js";
import { RecaptchaTokenHeaderKey } from "components/constants/requestHeaders.js";

const handshakeHeader = 'handshake';

export default {
  async get(url) {
    return fetch('get', url, null);
  },
  async get(url, payload) {
    return fetch('get', url, payload);
  },
  async post(url, payload = null, settings = null) {
    return fetch('post', url, payload, settings);
  },
  async delete(url) {
    return fetch('delete', url, null);
  },
  async delete(url, payload) {
    return fetch('delete', url, payload);
  },
  async put(url) {
    return fetch('put', url, null);
  },
  async put(url, payload) {
    return fetch('put', url, payload);
  }
};

let fetch = async function (httpVertb, url, payload, settings = null) {
  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }

  if (settings?.recaptchaToken) {
    config.headers[RecaptchaTokenHeaderKey] = settings.recaptchaToken;
  }

  let result = {
    response: null,
    error: null,
    status: null,
  };

  try {
    const handshake = webTools.getSessionStorageItem(constants.HANDSHAKE_SESSION_STORAGE);
    if (handshake) {
      config.headers[handshakeHeader] = handshake;
    }

    result.response = await getData(httpVertb, url, payload, config);
    if (result.response.headers.hasOwnProperty(handshakeHeader)) {
      webTools.setSessionStorageItem(constants.HANDSHAKE_SESSION_STORAGE, result.response.headers[handshakeHeader]);
    }
  } catch (error) {
    if (error.response.status === 429) {
      result.response = {
        ['error']: "rate_limit_reached",
        ['status']: 429,
        ['data']: { success: false, error: "rate_limit_reached" }
      };
    } else if (error.response.status === 401) {
      AuthService.logout();
    } else if (error.response.status === 403) {
      result.response = {
        ['error']: "forbidden",
        ['data']: { success: false, error: "forbidden" },
        ['status']: error.response.status
      };
    } else {
      result.response = {
        ['error']: "unexpected_error",
        ['data']: error,
        ['status']: error.response.status
      };
    }
  }

  return result;
};

let getData = async function (httpVertb, uri, payload, config) {
  switch (httpVertb) {
    case 'post':
      return await axios.post(uri, payload, config);
    case 'get':
      return await axios.get(uri, payload, config);
    case 'delete':
      return await axios.delete(uri, payload, config);
    case 'put':
      return await axios.put(uri, payload, config);
  }
}

