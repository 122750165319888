import React, { Component } from "react";
import { Link, Redirect, NavLink } from "react-router-dom";
import InputText from "../../components/InputText.jsx";
import fetcherApi from '../../components/FetcherApi.jsx';
import Footer from "../../components/Footer.jsx"
import ConsentLinks from "../../components/Consents/ConsentLinks.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import { Trans, withTranslation } from "react-i18next";
import StepBackHeader from "../../../components/components/HeaderComponents/StepBackHeader.jsx";
import RimiLogoHeaderButton from "../../../components/components/HeaderComponents/RimiLogoHeaderButton.jsx";
import AutoHideableHeader from "../../../components/components/HeaderComponents/AutoHideableHeader.jsx";
import { resolveVerificationCodeInput } from '../../functions/VerificationCodeResolver.jsx';
import { TemporarySuccessDisplay } from '../../components/SuccessDisplay/TemporarySuccessDisplay.jsx';
import urlTools from "../../tools/urlTools.jsx";
import ErrorMessagesService from '../../services/ErrorMessagesService.js';

class VerificationCode extends Component {
  _isMounted = false;
  state = {
    activationCode: { code1: "0", code2: "0", code3: "0", code4: "0" },
    errors: [],
    confirmed: false,
    confirmText: "confirm_button_label",
    stepBackPathName: "",
    isResendSuccessDisplayed: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      stepBackPathName: this.props.location.state.fromCardTypeSelection ? '/cardTypeSelection' : '/cardReplacement'
    });
  }

  handleClick = event => {
    this.props.hideNavBar(false);
  }

  onChange = e => {

    const { activationCode } = this.state;
    activationCode[e.prop] = resolveVerificationCodeInput(e);

    this.setState({ activationCode, errors: {} });
  };

  componentWillUnmount() {
    this._isMounted = false;
    this.setState = (state, callback) => {
      return;
    }
  }

  resendCode = async e => {
    let { errors } = this.state;
    e.preventDefault();

    const result = (await fetcherApi.post(urlTools.constructRoute("api/profile/CardReplacement/VerificationCode/resendActivationCode"))).response;

    if (result.data.success) {
      errors = [];
      this.setState({ isResendSuccessDisplayed: true });
    } else if (result.data.error === 'verification_resend_limit_reached') {
      errors['activation'] = ['card_replacement_page_sms_limit_error']
    } else {
      errors['activation'] = ['unexpected_error'];
    }

    this.setState({ errors });
  };

  onResendSuccessDisplayExpiration = () => {
    this.setState({ isResendSuccessDisplayed: false });
  };

  confirm = async e => {
    const { activationCode, errors } = this.state;
    e.preventDefault();
    if (activationCode.code1.length < 1 || activationCode.code2.length < 1 || activationCode.code3.length < 1 || activationCode.code4.length < 1) {

      errors['activation'] = ["card_replacement_activation_code_page_invalid_code_error"];
    }
    else {
      const result = (await fetcherApi.post(urlTools.constructRoute("api/profile/CardReplacement/VerificationCode/validate"), {
        code1: activationCode.code1,
        code2: activationCode.code2,
        code3: activationCode.code3,
        code4: activationCode.code4
      })).response;

      if (result.data.success) {
        this.setState({ confirmed: true });
      } else {
        errors['activation'] = ErrorMessagesService.getConvertedVerificationCodeError(result.data.error);
        this.setState({ confirmed: false, confirmText: "try_again_button_label", activationCode: { code1: "0", code2: "0", code3: "0", code4: "0" } });
      }
    }
    if (this._isMounted) {
      this.setState({ errors });
    }
  };

  render() {

    const { activationCode, confirmed, errors, confirmText } = this.state;
    return (
      <React.Fragment>
        <div className="container">
          <div className="card">
            <AutoHideableHeader>
              <RimiLogoHeaderButton onClick={this.handleClick.bind(this)} to="/profileInformation" />
              <StepBackHeader pathName={this.state.stepBackPathName}
                buttonText={'step_back_button_label'} />
            </AutoHideableHeader>
            <div className="card-content no-padding-bottom">
              <h1>
                <Trans i18nKey='card_replacement_activation_code_page_title'>
                  Rimi card <b>Replacement</b>
                </Trans>
              </h1>
              <div className="form-group">
                <div className="form-row">
                  <div className={errors['activation'] !== undefined && errors['activation'].length > 0 ? "form-field  -has-error" : "form-field"}>
                    <label htmlFor="activation_code" className="form-field__label">
                      <TranslationText text='card_replacement_activation_code_page_code_field_title' />
                    </label>
                    <div className="verification-code">
                      <InputText
                        onChange={this.onChange}
                        prop="code1"
                        name="field-1"
                        className="form-field__input"
                        type="tel" min="0"
                        max="9"
                        value={activationCode.code1}
                        isSelectedOnFocus={true}
                      />
                      <InputText
                        onChange={this.onChange}
                        prop="code2"
                        name="field-2"
                        className="form-field__input"
                        type="tel" min="0"
                        max="9"
                        value={activationCode.code2}
                        isSelectedOnFocus={true}
                      />
                      <InputText
                        onChange={this.onChange}
                        prop="code3"
                        name="field-3"
                        className="form-field__input"
                        type="tel" min="0"
                        max="9"
                        value={activationCode.code3}
                        isSelectedOnFocus={true}
                      />
                      <InputText
                        onChange={this.onChange}
                        prop="code4"
                        name="field-4"
                        className="form-field__input"
                        type="tel" min="0"
                        max="9"
                        value={activationCode.code4}
                        isSelectedOnFocus={true}
                      />
                    </div>
                    <div className="message-container">
                      <TemporarySuccessDisplay
                        isDisplayed={this.state.isResendSuccessDisplayed}
                        onExpiration={this.onResendSuccessDisplayExpiration}
                        message="verification_code_resend_success_message"
                      />
                      <div className="form-row">
                        <div className="sublabel error-msg show">
                          <span>
                            <TranslationText text={errors.activation} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-button full-width">

                {!confirmed ? (
                  <button
                    type="submit"
                    onClick={this.confirm}
                    className="button"
                  >
                    <TranslationText text={confirmText} />
                  </button>
                ) : <Redirect to={{
                  pathname: '/accountFound',
                  state: {
                    fromProfile: true
                  }
                }} />
                }

              </div>

              <div className="form-button full-width">
                <Link to="/profileInformation" className="button -secondary" onClick={this.handleClick.bind(this)}>
                  <TranslationText text='return_to_login_page' />
                </Link>
              </div>
              <div className="info-text">
                <p>
                  <TranslationText text='card_replacement_activation_code_page_thank_you_msg' />
                </p>
                <p>
                  <Trans i18nKey='card_replacement_activation_code_page_try_again'
                    components={{ url: <NavLink to='#' onClick={e => this.resendCode(e)} className="link-button">&nbsp;&nbsp;Try again</NavLink> }}>
                    Didn’t receive SMS? <url />
                  </Trans>
                </p>
              </div>
            </div>
            <footer className="card-footer no-border">
              <div className="footer-text">
                <ConsentLinks />
              </div>
            </footer>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(VerificationCode);


