import React, { Component, useRef } from "react";
import { Link, Redirect, NavLink } from "react-router-dom";
import InputText from "../../components/InputText.jsx";
import fetcherApi from '../../components/FetcherApi.jsx';
import Footer from "../../components/Footer.jsx";
import ConsentLinks from '../../components/Consents/ConsentLinks.jsx';
import TranslationText from "../../components/Translation/TranslationText.jsx";
import { Trans, withTranslation } from "react-i18next";
import RimiLogoHeaderButton from "../../components/HeaderComponents/RimiLogoHeaderButton.jsx";
import StepBackHeader from "../../components/HeaderComponents/StepBackHeader.jsx";
import AutoHideableHeader from "../../components/HeaderComponents/AutoHideableHeader.jsx";
import { resolveVerificationCodeInput } from '../../functions/VerificationCodeResolver.jsx';
import { TemporarySuccessDisplay } from '../../components/SuccessDisplay/TemporarySuccessDisplay.jsx';
import urlTools from "../../tools/urlTools.jsx";
import * as cardStatusCodes from "../../constants/cardStatusCodes.jsx";
import ErrorMessagesService from '../../services/ErrorMessagesService.js';

class VerificationCodeLogin extends Component {
  _isMounted = false;
  state = {
    activationCode: { code1: "0", code2: "0", code3: "0", code4: "0" },
    errors: [],
    confirmed: false,
    confirmText: "confirm_button_label",
    isResendSuccessDisplayed: false
  };

  constructor(props) {
    super(props);

    this.rateLimitError = this.props.location.state.rateLimitError ?? 'card_replacement_page_sms_limit_error';
    this.resendUrl = this.props.location.state.resendUrl ?? 'api/Account/CardReplacement/VerificationCode/resendActivationCode';
    this.returnUrl = this.props.location.state.returnUrl ?? (this.props.location.state.cardStatus === cardStatusCodes.PENDING_REG
      ? "/AdditionalInformation"
      : "/cardReplacementLogin");
    this.titleTranslation = this.props.location.state.titleTranslation ?? 'card_replacement_activation_code_page_title';
    this.infoMessageTranslation = this.props.location.state.infoMessageTranslation ?? 'card_replacement_activation_code_page_thank_you_msg';
    this.didNotReceiveMessageTranslation = this.props.location.state.didNotReceiveMessageTranslation ?? 'card_replacement_activation_code_page_try_again';
    this.activationCodeFieldLabelTranslation = this.props.location.state.activationCodeFieldLabelTranslation ?? 'card_replacement_activation_code_page_code_field_title';
  }

  componentDidMount() {
    this._isMounted = true;
  }

  onChange = e => {
    const { activationCode } = this.state;

    activationCode[e.prop] = resolveVerificationCodeInput(e);
    this.setState({ activationCode, errors: {} });
  };

  componentWillUnmount() {
    this._isMounted = false;
    this.setState = (state, callback) => {
      return;
    }
  }

  resendCode = async e => {
    let { errors } = this.state;
    e.preventDefault();

    const result = (await fetcherApi.post(urlTools.constructRoute(this.resendUrl))).response;

    if (result.data.success) {
      errors = [];
      this.setState({ isResendSuccessDisplayed: true });
    } else if (result.data.error === 'verification_resend_limit_reached' || result.data.error === 'rate_limit_reached') {
      errors['activation'] = [this.rateLimitError]
    } else {
      errors['activation'] = ['unexpected_error'];
    }

    this.setState({ errors });
  };

  onResendSuccessDisplayExpiration = () => {
    this.setState({ isResendSuccessDisplayed: false });
  };

  confirm = async e => {
    const { activationCode, errors } = this.state;
    e.preventDefault();
    if (activationCode.code1.length < 1 || activationCode.code2.length < 1 || activationCode.code3.length < 1 || activationCode.code4.length < 1) {

      errors['activation'] = 'card_replacement_activation_code_page_invalid_code_error';
    }
    else {
      let result;

      if (this.props.location.state.usesCustomRedirect) {
        result = (await fetcherApi.post(this.props.location.state.customCodeConfirmUrl, {
          code: activationCode.code1 + activationCode.code2 + activationCode.code3 + activationCode.code4
        })).response;
      }
      else {
        result = (await fetcherApi.post(urlTools.constructRoute("api/Account/CardReplacement/VerificationCode/validate"), {
          code1: activationCode.code1,
          code2: activationCode.code2,
          code3: activationCode.code3,
          code4: activationCode.code4
        })).response;
      }

      if (result.data.success) {
        this.setState({ confirmed: true });
      } else {
        errors['activation'] = ErrorMessagesService.getConvertedVerificationCodeError(result.data.error);
        this.setState({ confirmed: false, confirmText: "try_again_button_label", activationCode: { code1: "0", code2: "0", code3: "0", code4: "0" } });
      }
    }
    if (this._isMounted) {
      this.setState({ errors });
    }
  };

  getStepBackPath = () => {
    return this.returnUrl;
  }

  getRedirect() {
    return (this.props.location.state.usesCustomRedirect ? (<Redirect to={{
      pathname: this.props.location.state.customRedirectRoute,
      search: window.location.search,
      state: {
        email: this.props.location.state.email,
        recaptchaTwoFactorPassed: this.props.location.state.recaptchaTwoFactorVerificationFlow,
        isBusinessFlow: this.props.location.state.usingBusinessLogin
      }
    }} />) : <Redirect to='/AccountFoundLogin' />)
  }

  render() {

    const { activationCode, confirmed, errors, confirmText } = this.state;

    return (
      <React.Fragment>
        <div className="container">
          <div className="card">
            <AutoHideableHeader>
              <RimiLogoHeaderButton initialData={this.props.initialData} />
              <StepBackHeader
                pathName={this.getStepBackPath()}
                buttonText={'step_back_button_label'}
                payload={{ isPhysicalCardRegistration: this.props.location?.state?.isPhysicalCardRegistration }} />
            </AutoHideableHeader>
            <div className="card-content no-padding-bottom">
              <h1>
                <Trans i18nKey={this.titleTranslation}
                  components={{ bold: <b style={{ display: "inline" }} /> }}>
                  Rimi card <bold>Replacement</bold>
                </Trans>
              </h1>

              <div className="form-group">
                <div className="form-row">
                  <div className={errors['activation'] !== undefined && errors['activation'].length > 0 ? "form-field  -has-error" : "form-field"}>
                    <label htmlFor="activation_code" className="form-field__label">
                      <TranslationText text={this.activationCodeFieldLabelTranslation} />
                    </label>
                    <div className="verification-code">

                      <InputText
                        onChange={this.onChange}
                        prop="code1"
                        name="field-1"
                        className="form-field__input"
                        type="tel" min="0"
                        max="9"
                        value={activationCode.code1}
                        isSelectedOnFocus={true}
                      />

                      <InputText
                        onChange={this.onChange}
                        prop="code2"
                        name="field-2"
                        className="form-field__input"
                        type="tel" min="0"
                        max="9"
                        value={activationCode.code2}
                        isSelectedOnFocus={true}
                      />
                      <InputText
                        onChange={this.onChange}
                        prop="code3"
                        name="field-3"
                        className="form-field__input"
                        type="tel" min="0"
                        max="9"
                        value={activationCode.code3}
                        isSelectedOnFocus={true}
                      />
                      <InputText
                        onChange={this.onChange}
                        prop="code4"
                        name="field-4"
                        className="form-field__input"
                        type="tel" min="0"
                        max="9"
                        value={activationCode.code4}
                        isSelectedOnFocus={true}
                      />
                    </div>
                    <div className="message-container">
                      <TemporarySuccessDisplay
                        isDisplayed={this.state.isResendSuccessDisplayed}
                        onExpiration={this.onResendSuccessDisplayExpiration}
                        message="verification_code_resend_success_message"
                      />
                      <div className="form-row">
                        <div className="sublabel error-msg show">
                          <span>
                            <TranslationText text={errors.activation} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-button full-width">

                {!confirmed ? (
                  <button
                    type="submit"
                    onClick={this.confirm}
                    className="button"
                  >
                    <TranslationText text={confirmText} />
                  </button>
                ) : this.getRedirect()
                }
              </div>

              <div className="form-button full-width">
                <Link className="button -secondary" to={{ pathname: '/login' }}>
                  <TranslationText text='return_to_login_page' />
                </Link>
              </div>
              <div className="info-text">
                <p>
                  <TranslationText text={this.infoMessageTranslation} />
                </p>
                <p>
                  <Trans i18nKey={this.didNotReceiveMessageTranslation}
                    components={{ url: <NavLink to='#' onClick={e => this.resendCode(e)} className="link-button">&nbsp;&nbsp;Try again</NavLink> }}>
                    Didn’t receive SMS? <url />
                  </Trans>
                </p>
              </div>
            </div>
            <footer className="card-footer no-border">
              <div className="footer-text">
                <ConsentLinks />

              </div>
            </footer>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(VerificationCodeLogin);


