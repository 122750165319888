import React, { Component } from "react";
import { Link } from "react-router-dom";
import TranslationText from "../Translation/TranslationText.jsx";
import * as constants from "../../constants/constants.jsx";

class StepBackHeader extends Component {

  state = {
    isMobileApp: false,
  }

  async componentDidMount() {
    this.setState({ isMobileApp: localStorage.getItem(constants.IS_MOBILE_APP_STORAGE_KEY) === constants.TRUE });
  }

  render() {
    if (this.state.isMobileApp) {
      return <React.Fragment />
    }
    else {

      var inputProps = {};
      if (!!this.props.handleClick) {
        inputProps.onClick = this.props.handleClick
      }
      const svg = !!this.props.iconBeforeText ?
        this.props.iconBeforeText :
        <svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m15 6-6 6 6 6" fill="none" stroke="#a12971" strokeWidth="2" /></svg>

      return <React.Fragment>

        <Link
          className="link-button -with-left-icon"
          to={{
            pathname: this.props.pathName,
            state: this.props.payload
          }}
          {...inputProps}>
          {svg}
          <TranslationText text={this.props.buttonText} />
        </Link>

      </React.Fragment>
    }
  }
}
export default StepBackHeader;