import React, { Component } from "react";
import { Link } from "react-router-dom";
import TranslationText from '../Translation/TranslationText.jsx';

class ForgotPasswordTryAgain extends Component {

	state = {
		showDecline: false
	}

	getCardReplacementButton = () => {
		return !this.props.isCardReplacementLink ? (
			<div className="form-button full-width">
				<Link className="button -secondary" to={{ pathname: "/cardReplacementLogin" }}>
					<TranslationText text="login_page_replace_card_btn" />
				</Link>
			</div>
		) : (
			<Link className="link-button link-button-large" to={{ pathname: "/cardReplacementLogin" }}>
				<TranslationText text="login_page_replace_card_btn" />
			</Link>
		);
	};

	render() {
		return (
			<div className={this.props.customClass}>
				{
					!this.props.isPasswordResetHidden ? (
						<Link className="link-button link-button-large" to={{ pathname: '/passwordrecovery/enteremail' }}>
							<TranslationText text={'login_page_forgot_password_btn'} />
						</Link>
					) : null
				}
				{
					!this.props.isB2bAccount ? this.getCardReplacementButton() : null
				}
			</div>
		);
	}
}
export default ForgotPasswordTryAgain;