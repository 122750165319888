import React, { Component, createRef } from "react";
import { NavLink } from 'react-router-dom';
import logout from 'assets/svg/icons/sign-out.svg';
import CurrentUserInfo from "components/components/CurrentUserInfo.jsx";
import Routes from "Routes.jsx";
import TranslationText from "components/components/Translation/TranslationText.jsx";
import NavigateBackResolver from "components/components/NavigateBackResolver.jsx";
import TagManager from 'react-gtm-module';
import * as constants from "components/constants/constants.jsx";
import i18n from "translations/i18n.jsx";
import { UserContext } from "components/tools/context/UserContext.jsx";
import AuthService from "components/services/AuthService";
import FetcherApi from "components/components/FetcherApi";
import urlTools from "components/tools/urlTools";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import ViewportFunctions from "./tools/ViewportFunctions";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import RimiLogoHeaderButton from "./components/HeaderComponents/RimiLogoHeaderButton";
import CookieBotService from "./services/CookieBotService";
// check sessionstorage for f5 refresh screen fix

class AppContent extends Component {
	state = {
		greetingText: "",
		hideNavBar: false,
		redirections: null,
		businessLogin: false,
		toggleShowNav: false,
		googleTagManagerId: "",
		isMobileApp: false,
		initialData: null,
		isLoaded: false
	};

	constructor(props) {
		super(props);
		this.currentUserInfoRef = createRef();
	}

	async componentDidMount() {
		const response = await FetcherApi.get(urlTools.constructRoute("api/react/initial-data") + window.location.search);
		const initialData = response.response.data.resultObject;

		this.initialize(initialData);
		this.addEventListeners();
	}

	addEventListeners = () => {
		this.addPictographicContentFilter();
	}

	addPictographicContentFilter = () => {
		document.addEventListener("beforeinput", e => {
			const potentialInput = e.data;
			const containsPictographicContent = /\p{Extended_Pictographic}/u.test(potentialInput);

			if (containsPictographicContent) {
				e.preventDefault();
			}
		});
	}

	initialize(initialData) {
		const explicitMobAppSetting = new URLSearchParams(this.props.location.search).get("mobApp");
		if (explicitMobAppSetting) {
			initialData.isRimiMobileAppUser = explicitMobAppSetting.toLowerCase() === "true";
		}

		const isMobileApp = initialData.isRimiMobileAppUser;
		localStorage.setItem(constants.IS_MOBILE_APP_STORAGE_KEY, isMobileApp);
		console.log('is mobile App: ' + isMobileApp);

		const decodedQueryString = decodeURIComponent(window.location.search);
		
		if (decodedQueryString.startsWith(constants.OIDC_QUERY_BEGINNING)){
			sessionStorage.setItem(constants.OIDC_QUERY_KEY, window.location.search);
		}
		
		this.setState({
			initialData,
			greetingText: initialData.customerNameSurname,
			isMobileApp: isMobileApp,
			isLoaded: true,
			hideNavBar: initialData.hideNavBar,
			redirections: initialData.redirections,
			businessLogin: initialData.businessLogin,
			googleTagManagerId: initialData.googleTagManagerId,
		});
		
		if (initialData.languageCode && initialData.languageCode != i18n.language) {
      i18n.changeLanguage(initialData.languageCode);
    } else if (initialData.defaultLanguageCode) {
			i18n.changeLanguage(initialData.defaultLanguageCode.toLowerCase());
		}
		
		this.updateGreetingText(initialData.customerNameSurname);

		TagManager.initialize({
			gtmId: initialData.googleTagManagerId,
			dataLayer: {
				[constants.GTM_USER_LANGUAGE_KEY]: CookieBotService.getLanguage()
			}
		});

		CookieBotService.verifyUserAgentSuitability(this.props.location.search);
	}

	hideNavBar(hide) {
		if (hide === this.state.hideNavBar) {
			return;
		}

		this.setState({
			hideNavBar: hide
		});
	}

	updateGreetingText(greetingText) {
		this.currentUserInfoRef.current?.updateUserName(greetingText);
	}

	logout = async () => {
		await AuthService.logout();
	}

	handleNavLinkClick = () => {
		const isMobile = ViewportFunctions.isMobileLayout();
		if (isMobile) {
			this.setState({ toggleShowNav: !this.state.toggleShowNav });
		}
	}

	render() {
		return this.state.isLoaded ? (
			<GoogleReCaptchaProvider reCaptchaKey={this.state.initialData.recaptchaSiteKey}>
				<UserContext.Provider value={this.state.initialData.isUserLoggedIn}>
					<div className="main-wrapper">
						<header className="main-header">
							{this.state.hideNavBar || this.state.isMobileApp ? (<div></div>) :
								(
									<React.Fragment>
										<div className="top-bar">
											<div className="container">
												<div className="header-row">
													<div className="left-columns" style={{ display: 'flex', alignItems: 'center' }}>
														<div className="logo-column">
															<RimiLogoHeaderButton to="/ProfileInformation" />
														</div>
														<div className="links-column">
															<a
																className="link-button -with-left-icon"
																href={NavigateBackResolver.getNavigateBackUrl(this.state.initialData)}
															>
																<svg
																	enableBackground="new 0 0 24 24"
																	viewBox="0 0 24 24"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="m15 6-6 6 6 6"
																		fill="none"
																		stroke="#a12971"
																		strokeWidth="2"
																	/>
																</svg>
																<TranslationText text={NavigateBackResolver.getNavigateBackLocalizationText(this.state.initialData)} />
																<div id="referertest" title={this.state.initialData.referer} />
															</a>
														</div>
													</div>

													<div className="actions-column">
														<CurrentUserInfo userName={this.state.greetingText} ref={this.currentUserInfoRef} />
														<a onClick={this.logout} className="button -secondary -with-right-icon">
															<TranslationText text='logout_button_label' />
															<img width="25px" height="25px" src={logout} alt="svg image" />
														</a>
													</div>
												</div>
											</div>
										</div>

										<div className="container">
											<nav className={this.state.toggleShowNav ? "navigation-row show-nav" : "navigation-row"}>
												<NavLink
													className="nav-link nav-item"
													to='/ProfileInformation'
													onClick={this.handleNavLinkClick}
												>
													<TranslationText text='profile_information_page_menu_bar_profile_info' />
												</NavLink>
												{
													!this.state.businessLogin && (
														<NavLink
															className="nav-link nav-item"
															to='/myrimioffers'
															onClick={this.handleNavLinkClick}
														>
															<TranslationText text='profile_information_page_menu_bar_favourite_store' />
														</NavLink>
													)
												}
												{
													!this.state.businessLogin && (
														<NavLink
															className="nav-link nav-item"
															to='/MyRimiGroup'
															onClick={this.handleNavLinkClick}
														>
															<TranslationText text='profile_information_page_menu_bar_my_rimi_group' />
														</NavLink>
													)
												}
												{
													!this.state.businessLogin && (
														<NavLink
															className="nav-link nav-item"
															to='/clubs'
															onClick={this.handleNavLinkClick}
														>
															<TranslationText text='profile_information_page_menu_bar_clubs' />
														</NavLink>)
												}
												<NavLink
													className="nav-link nav-item"
													to='/communication'
													onClick={this.handleNavLinkClick}
												>
													<TranslationText text='profile_information_page_menu_bar_communication' />
												</NavLink>
												<NavLink
													className="nav-link nav-item"
													to='/ecommerce'
													onClick={this.handleNavLinkClick}
												>
													<TranslationText text='profile_information_page_menu_bar_ecommerce' />
												</NavLink>
											</nav>
										</div>
									</React.Fragment>
								)}
						</header>
						<Routes initialData={this.state.initialData}
							updateGreetingText={this.updateGreetingText.bind(this)}
							hideNavBar={this.hideNavBar.bind(this)}
						/>
					</div>
				</UserContext.Provider>
			</GoogleReCaptchaProvider>
		) : null;
	}
}

export default withRouter(AppContent);