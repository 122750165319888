import React, { Component } from "react";
import InputText from "../../components/InputText.jsx";
import validator from "../../components/FieldValidator.jsx";
import "regenerator-runtime/runtime";
import fetcherApi from '../../components/FetcherApi.jsx';
import Spinner from '../../profile/Information/Spinner.jsx';
import Tippy from '@tippyjs/react';
import { Link } from "react-router-dom";
import Footer from "../../components/Footer.jsx";
import LegalEntityFunctions from "../../components/LegalEntityFunctions.jsx";
import { withRouter } from "react-router-dom";
import ConsentLinks from "../../components/Consents/ConsentLinks.jsx";
import * as constants from "../../constants/constants.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import EmailAndPhoneValidator from '../../components/EmailAndPhoneValidator.jsx';
import { Trans, withTranslation } from 'react-i18next';
import urlTools from "../../tools/urlTools.jsx";
import { DateInput } from '../../components/DateInput.jsx';
import RimiLogoHeaderButton from "components/components/HeaderComponents/RimiLogoHeaderButton.jsx";
import webTools from "components/tools/webTools.js";
import { PasswordRuleChecklistOverlay } from "components/components/PasswordRuleChecklist/PasswordRuleChecklistOverlay.jsx";

class YourData extends Component {

	state = {
		phonePrefixes: this.props.initialData.phonePrefixes.map(data => ({ label: data.value, value: data.key })),
		password: { newpassword: "", confirmpassword: "" },
		errors: {},
		client: null,
		submiterror: "",
		initialcustomer: {},
		dirtyfields: {},
		passwordControlePlainTextMode: false,
		mandatoryFieldsErrors: {},
		confirmPasswordControlePlainTextMode: false,
		maxPhoneLength: "",
		selectedPrefix: "",
		consentsAccepted: false,
		validations: {},
		errorText: "",
		duplicatePhoneError: false,
		confirmUrl: ""
	};

	async componentDidMount() {
		const client = this.props.initialData.individualRegistrationProfileData;
		client.email = this.props.location.state?.email ?? this.props.initialData.individualRegistrationProfileData.email;
		let selectedCountry = localStorage.getItem(constants.SELECTED_COUNTRY);
		const registrationConfirmUrl = "api/RegistrationCode/Add-Data/" + LegalEntityFunctions.getLegalEntityId(selectedCountry);
		client.phonePrefix = LegalEntityFunctions.getPhonePrefix(selectedCountry);
		client.phonePrefixId = LegalEntityFunctions.getPhonePrefixId(selectedCountry);


		this.setState({
			confirmUrl: registrationConfirmUrl,
			validations: this.props.initialData.validations,
			maxPhoneLength: validator.getPhoneMinLength(this.props.initialData.phonePrefixes, client.phonePrefix),
			selectedPrefix: client.phonePrefix,
			client,
			initialcustomer: JSON.parse(JSON.stringify(client)),
			dirtyfields: {},
			errors: {}
		});
	}

	createSelectItems() {
		const { phonePrefixes } = this.state;
		let items = [];
		for (let i = 0; i < phonePrefixes.length; i++) {
			let label = phonePrefixes[i].label;
			let key = phonePrefixes[i].value;
			items.push(<option key={label} value={key}>{label}</option>);
		}
		return items;
	}

	onBirthDateChange = value => {
		const { client, errors } = this.state
		errors['birthDate'] = [];
		client['birthDate'] = value;
		this.setState({ client, errors });
	}

	onChange = e => {
		const { client, initialcustomer, dirtyfields, errors } = this.state;
		if (initialcustomer[e.prop] !== e.value) {
			dirtyfields[e.prop] = true;
		}
		else {
			dirtyfields[e.prop] = false;
		}
		if (e.prop === 'lastName' || e.prop === 'name') {
			client[e.prop] = validator.blockUnwantedSymbols(e.value);
		}
		else {
			client[e.prop] = validator.validate(e.prop, e.value);
		}
		errors[e.prop] = [];
		this.setState({ client, errors });
	};

	onTogglePasswordShow = e => {
		e.preventDefault();
		this.setState({ passwordControlePlainTextMode: !this.state.passwordControlePlainTextMode });
	};

	onToggleConfirmPasswordShow = e => {
		e.preventDefault();
		this.setState({ confirmPasswordControlePlainTextMode: !this.state.confirmPasswordControlePlainTextMode });

	};

	onChangePassword = e => {
		const { errors, password } = this.state;
		errors[e.prop] = [];
		password[e.prop] = e.value;
		this.setState({ password, errors });
	};

	hasErrorsOnSave() {
		let foundErrors = false;
		const { errors, mandatoryFieldsErrors } = this.state;

		for (var fieldError in mandatoryFieldsErrors) {
			if (mandatoryFieldsErrors[fieldError] !== undefined && mandatoryFieldsErrors[fieldError].length > 0) {
				errors[fieldError] = mandatoryFieldsErrors[fieldError]
				foundErrors = true;
			}
		}

		if (!foundErrors) {
			this.setState({ mandatoryFieldsErrors: {} });
		}

		this.setState({ errors });
		return foundErrors;
	};

	hasErrors = () => {
		const { errors } = this.state;
		for (var error in errors) {
			if (errors[error] && errors[error].length) {
				return true;
			}
		}

		return false;
	};

	onDropDownChange = e => {
		const { client, errors } = this.state;
		errors['phone'] = [];
		client.phonePrefixId = e.target.value;
		let index = e.nativeEvent.target.selectedIndex;
		let prefix = e.nativeEvent.target[index].text;
		client.phonePrefix = prefix;
		this.setState({
			client,
			errors,
			selectedPrefix: prefix,
			maxPhoneLength: validator.getPhoneMinLength(this.props.initialData.phonePrefixes, prefix)
		});
	};

	handleChange = e => {

		const { name, value, checked } = e.target;
		const { client, errors } = this.state;

		if (value === 'consentsAccepted') {
			errors['consents'] = [];
			this.setState({ consentsAccepted: checked });
		}
		else if (name === 'gender') {
			let genderCode = value == 'male' ? "1" : "2";
			client.gender = genderCode;
			errors['gender'] = [];
		}
		else {
			client.communication[value] = checked;
		}

		this.setState({ client, errors });

	};

	validateMandatoryFields() {
		const { mandatoryFieldsErrors, client } = this.state;
		Object.keys(this.state.client).map(function (keyName, keyIndex) {
			mandatoryFieldsErrors[keyName] = validator.validateMandatoryField(keyName, client[keyName]);
		})
		this.setState(mandatoryFieldsErrors);
	}

	async IsPhoneUnique() {
		const { errors } = this.state;
		let errorText = '';
		let duplicatePhoneError = false;
		let result = true;

		const phoneUnique = await EmailAndPhoneValidator.IsPhoneUnique(this.state.client.phone,
			this.state.selectedPrefix, 'RETAIL', this.state.client.clientsId);

		if (!phoneUnique.data.success) {
			errorText = phoneUnique.errorStandardOrRateLimit;
			result = false;
		}
		else if (!phoneUnique.data.resultObject.unque) {
			errorText = 'profile_information_page_duplicate_phone_error';
			duplicatePhoneError = true;
			result = false;
		}

		errors["phone"] = errorText;
		this.setState({ duplicatePhoneError, errors });
		return result;
	}

	validatePasswords() {
		const { errors, password } = this.state;
		if (!(!!password.newpassword)) {
			errors['newpassword'] = ['create_password_page_login_empty_pwd_error'];
		}
		else {
			errors['newpassword'] = validator.validatePassword(password.newpassword);
			errors['confirmpassword'] = validator.validatePasswords(password.newpassword, password.confirmpassword);
		}
		this.setState({ errors });
	}

	redirectUserOnSuccess() {
		if (sessionStorage.getItem(constants.OIDC_QUERY_KEY)) {
			webTools.reloadToRoute();
		} else {
			this.props.history.push("/registration/HasAccount/Congratulations");
		}
	}

	save = async e => {
		const { client, errors, password, validations, selectedPrefix, maxPhoneLength,
			consentsAccepted, userRegistrationData, initialcustomer } = this.state;
		e.preventDefault();

		this.validatePasswords();
		this.validateMandatoryFields();
		let error = false;
		const selectedLanguageCode = LegalEntityFunctions.getLanguageIsoCode(localStorage.getItem(constants.SELECTED_LANGUAGE));

		errors['birthDate'] = validator.validateBirthDate(
			client.birthDate,
			validations,
			undefined,
			"add_your_data_page_birthdate_error_min_age"
		);

		if (!!client.phone) {
			let phoneError = validator.validatePhonePrefixes(this.props.initialData.phonePrefixes, this.state.selectedPrefix, client.phone);
			if (phoneError !== "") {
				errors["phone"] = phoneError;
				error = true;
			} else if (!(await this.IsPhoneUnique())) {
				error = true;
			}
		}

		if (!consentsAccepted) {
			errors['consents'] = ['add_your_data_page_no_consents_error'];
			error = true;
		}

		if (this.hasErrorsOnSave()) {
			error = true;
		}

		if (this.hasErrors()) {
			error = true;
		}

		if (!error) {
			client.password = password.newpassword;
			const userRegistrationData = { client: client, selectedLanguageCode: selectedLanguageCode };

			const result = (await fetcherApi.post(urlTools.constructRoute(this.state.confirmUrl), userRegistrationData)).response;
			if (result.data.success) {
				window.sessionStorage.removeItem(constants.HANDSHAKE_SESSION_STORAGE);
				this.setState({ client, errors: {}, mandatoryFieldsErrors: {}, selectedPrefix, maxPhoneLength });
				this.redirectUserOnSuccess();
			}
			else {
				this.setState({ errorText: result.data.error });
			}
		}
		else {
			this.setState({ errors });
		}
	};

	render() {
		const { client, maxPhoneLength, errors, password, passwordControlePlainTextMode,
			confirmPasswordControlePlainTextMode, consentsAccepted } = this.state;

		return client !== null ?


			<React.Fragment>
				<div className="container">
					<div className="card card-full-width create-account-page">
						<header className="card-header">
							<RimiLogoHeaderButton initialData={this.props.initialData} />
						</header>
						<div className="card-content">
							<h1>
								<Trans i18nKey="add_your_data_page_title">
									New account creation.<b>Please fill information about yourself</b>.
								</Trans>
							</h1>
							<div className="row">
								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={errors['name'] !== undefined && errors['name'].length > 0 ? "form-field -required -has-error" : "form-field -required"}>
												<label htmlFor="first_name" className="form-field__label">
													<TranslationText text='add_your_data_page_fname_field_title' />
												</label >
												<InputText
													onChange={this.onChange}
													prop="name"
													value={client.name || ""}
													maxLength="50"
													className="form-field__input"
													type="text"
												/>

											</div>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.name} />
											</span>
										</div>
									</div>
								</div>
								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={errors['lastName'] !== undefined && errors['lastName'].length > 0 ? "form-field -required -has-error" : "form-field -required"}>
												<label htmlFor="last_name" className="form-field__label">
													<TranslationText text='add_your_data_page_lname_field_title' />
												</label>
												<InputText
													onChange={this.onChange}
													prop="lastName"
													value={client.lastName || ""}
													maxLength="50"
													className="form-field__input"
													type="text"
												/>
											</div>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.lastName} />
											</span>
										</div>
									</div>
								</div>
								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className="form-field -required">
												<label htmlFor="email" className="form-field__label"><TranslationText text='email_field_label' /></label>
												<input className="form-field__input" type="email" name="email" id="email" value={client.email || ""} readOnly />                            </div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group -required">
										<div className="form-row">
											<div className="form-field">
												<label htmlFor="phone_number" className="form-field__label">
													<TranslationText text='add_your_data_page_phone_field_title' />
												</label>
											</div>
										</div>
										<div className={errors['phone'] !== undefined && errors['phone'].length > 0 ? "form-row -phone-number -has-error" : "form-row -phone-number"}>
											<div className="form-field form-field__select">
												<select value={client.phonePrefixId} onChange={this.onDropDownChange}>
													{this.createSelectItems()}
												</select>
												<svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m22.7 12.7-6.7 6.6-6.7-6.6" fill="none" stroke="#a12971" strokeMiterlimit="10" strokeWidth="2" /></svg>                            </div>
											<div className="form-field">
												<InputText
													onChange={this.onChange}
													prop="phone"
													value={client.phone || ""}
													className="form-field__input -tel"
													type="tel"
													maxLength={maxPhoneLength}
												/>
											</div>
										</div>
										<div className="form-row">
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.phone} />
												</span>
												{this.state.duplicatePhoneError ?
													(<Link className="link-button link-button-large" to={{ pathname: '/passwordrecovery/enteremail' }}>
														<TranslationText text='add_your_data_page_pwd_recovery_btn' />
													</Link>)
													: <div></div>
												}
											</div>
										</div>
									</div>
								</div>
								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className="form-field password-form-field -required">
												<label htmlFor="password" className="form-field__label">
													<TranslationText text='add_your_data_page_pwd_field_title' />
												</label>
												<PasswordRuleChecklistOverlay password={password.newpassword} neutralRulesDisabled={true}>
													<div className={errors['newpassword'] !== undefined && errors['newpassword'].length > 0 ? "form-field-container -has-error" : "form-field-container"}>
														<InputText
															onChange={this.onChangePassword}
															prop="newpassword"
															value={password.newpassword || ""}
															type={passwordControlePlainTextMode ? "text" : "password"}
															id="newpassword"
															placeholder=""
															className="form-field__input"
														/>
														<a href="#" className={passwordControlePlainTextMode ? "form-field__card-icon togglePassword show" : "form-field__card-icon togglePassword"} onClick={this.onTogglePasswordShow}>
															<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2" transform="translate(3 6)"><path d="m18 5.47826087s-4.0292609 5.47826083-9 5.47826083c-4.97073913 0-9-5.47826083-9-5.47826083s4.02926087-5.47826087 9-5.47826087c4.9707391 0 9 5.47826087 9 5.47826087z" /><path d="m11.7391304 5.47826087c0 1.51317391-1.2263478 2.73913043-2.7391304 2.73913043-1.51278261 0-2.73913043-1.22595652-2.73913043-2.73913043 0-1.5123913 1.22634782-2.73913044 2.73913043-2.73913044 1.5127826 0 2.7391304 1.22673914 2.7391304 2.73913044z" /></g></svg>
														</a>
													</div>
												</PasswordRuleChecklistOverlay>
												<div className="sublabel error-msg show">
													<span>
														<TranslationText text={errors.newpassword} />
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className="form-field password-form-field -required">
												<label htmlFor="passwordRepeat" className="form-field__label">
													<TranslationText text='add_your_data_page_repeat_pwd_field_title' />
												</label>
												<div className={errors['confirmpassword'] !== undefined && errors['confirmpassword'].length > 0 ? "form-field-container -has-error" : "form-field-container"}>
													<InputText
														onChange={this.onChangePassword}
														prop="confirmpassword"
														value={password.confirmpassword || ""}
														type={confirmPasswordControlePlainTextMode ? "text" : "password"}
														id="passwordRepeat"
														placeholder=""
														className="form-field__input"
													/>
													<a href="#" className={confirmPasswordControlePlainTextMode ? "form-field__card-icon togglePassword show" : "form-field__card-icon togglePassword"} onClick={this.onToggleConfirmPasswordShow}>
														<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2" transform="translate(3 6)"><path d="m18 5.47826087s-4.0292609 5.47826083-9 5.47826083c-4.97073913 0-9-5.47826083-9-5.47826083s4.02926087-5.47826087 9-5.47826087c4.9707391 0 9 5.47826087 9 5.47826087z" /><path d="m11.7391304 5.47826087c0 1.51317391-1.2263478 2.73913043-2.7391304 2.73913043-1.51278261 0-2.73913043-1.22595652-2.73913043-2.73913043 0-1.5123913 1.22634782-2.73913044 2.73913043-2.73913044 1.5127826 0 2.7391304 1.22673914 2.7391304 2.73913044z" /></g></svg>
													</a>
												</div>
												<div className="sublabel error-msg show">
													<span>
														<TranslationText text={errors.confirmpassword} />
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={errors['birthDate'] !== undefined && errors['birthDate'].length > 0 ? "form-field -required -has-error" : "form-field -required"}>
												<label htmlFor="birth_date" className="form-field__label">
													<TranslationText text='add_your_data_page_birthdate_field_title' />
													<Tippy content={<span>
														<TranslationText text='add_your_data_page_birthdate_info_msg' />
													</span>}>
														<span className="help-tip">
															<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" transform="translate(1 1)"><g stroke="currentColor" strokeWidth="2"><path d="m11.9497609 2.0502513c2.7336704 2.73367044 2.7336704 7.16583913 0 9.8994913-2.73367047 2.7336704-7.16583916 2.7336704-9.89949133 0-2.73367044-2.73367043-2.73367044-7.16583912 0-9.8994913 2.73367043-2.73367043 7.16583913-2.73367043 9.89949133 0" /><path d="m7 8.82608696v-.48695653c0-.63913043.33478261-1.24782608.91304348-1.58260869.88260869-.51739131 1.18695652-1.61304348.66956522-2.49565217-.51739131-.8826087-1.61304348-1.18695653-2.49565218-.66956522-.54782609.33478261-.91304348.91304348-.91304348 1.58260869" /></g><path d="m7.82533029 10.0601891c.43297264.4329726.43297264 1.1349602 0 1.5679334-.43297265.4329726-1.13496021.4329726-1.5679334 0-.43297265-.4329727-.43297265-1.1349602 0-1.5679334.43297264-.43297268 1.1349602-.43297268 1.5679334 0" fill="#a4afb3" /></g></svg>                                    </span>
													</Tippy>
												</label>
												<DateInput
													initialDateString={client.birthDate || ""}
													onChange={this.onBirthDateChange}
													errorMessage={errors.birthDate}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group -required">
										<div className="form-row">
											<div className="form-field">
												<label htmlFor="gender" className="form-field__label">
													<TranslationText text='add_your_data_page_gender_title' />
												</label>
											</div>
										</div>
										<div className="form-row">
											<div className="form-field -radio">
												<div className="form-check inline">
													<input onChange={this.handleChange} className="form-check-input" name="gender" type="radio" value="male" id="male"
														checked={client.gender === "1"}
													/>
													<label className="form-check-label" htmlFor="male">
														<TranslationText text='add_your_data_page_gender_male' />
														<span></span>
													</label>
												</div>
												<div className="form-check inline">
													<input onChange={this.handleChange} className="form-check-input" name="gender" type="radio" value="female" id="female"
														checked={client.gender === "2"}
													/>
													<label className="form-check-label" htmlFor="female">
														<TranslationText text='add_your_data_page_gender_female' />
														<span></span>
													</label>
												</div>
											</div>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.gender} />
											</span>
										</div>
									</div>
								</div>
								<div className="column-medium-4 column-desktop-6 column-tablet-12"></div>
							</div>
							<hr />
							<div className="row">
								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<h2 className="help">
												<TranslationText text='add_your_data_page_comm_channels_title' />
												<Tippy content={<span>
													<TranslationText text='add_your_data_page_comm_channels_info_msg' />
												</span>}>
													<span className="help-tip">
														<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" transform="translate(1 1)"><g stroke="currentColor" strokeWidth="2"><path d="m11.9497609 2.0502513c2.7336704 2.73367044 2.7336704 7.16583913 0 9.8994913-2.73367047 2.7336704-7.16583916 2.7336704-9.89949133 0-2.73367044-2.73367043-2.73367044-7.16583912 0-9.8994913 2.73367043-2.73367043 7.16583913-2.73367043 9.89949133 0" /><path d="m7 8.82608696v-.48695653c0-.63913043.33478261-1.24782608.91304348-1.58260869.88260869-.51739131 1.18695652-1.61304348.66956522-2.49565217-.51739131-.8826087-1.61304348-1.18695653-2.49565218-.66956522-.54782609.33478261-.91304348.91304348-.91304348 1.58260869" /></g><path d="m7.82533029 10.0601891c.43297264.4329726.43297264 1.1349602 0 1.5679334-.43297265.4329726-1.13496021.4329726-1.5679334 0-.43297265-.4329727-.43297265-1.1349602 0-1.5679334.43297264-.43297268 1.1349602-.43297268 1.5679334 0" fill="#a4afb3" /></g></svg>                                    </span>
												</Tippy>
											</h2>
										</div>
										<div className="form-row">
											<div className="form-field -checkbox">
												<div className="form-check">
													<input
														className="form-check-input"
														onChange={this.handleChange}
														checked={client.communication.email || false}
														type="checkbox"
														value="email"
														id="info_email"
													/>
													<label className="form-check-label" htmlFor="info_email">
														<TranslationText text='add_your_data_page_comm_channels_email' />                                                <span></span>
													</label>
												</div>
												<div className="form-check">
													<input
														className="form-check-input"
														onChange={this.handleChange}
														checked={client.communication.sms}
														type="checkbox"
														value="sms"
														id="info_phone"
													/>
													<label className="form-check-label" htmlFor="info_phone">
														<TranslationText text='communication_channels_page_phone_box' />
														<span></span>
													</label>
												</div>
											</div>
										</div>
									</div>
									<hr className="spacer" />
								</div>
								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<h2 className="help">
												<TranslationText text='communication_channels_receipts_form_header' />
												<Tippy content={<span>
													<TranslationText text='add_your_data_page_dgtl_rcpt_info_msg' />
												</span>}>
													<span className="help-tip">
														<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" transform="translate(1 1)"><g stroke="currentColor" strokeWidth="2"><path d="m11.9497609 2.0502513c2.7336704 2.73367044 2.7336704 7.16583913 0 9.8994913-2.73367047 2.7336704-7.16583916 2.7336704-9.89949133 0-2.73367044-2.73367043-2.73367044-7.16583912 0-9.8994913 2.73367043-2.73367043 7.16583913-2.73367043 9.89949133 0" /><path d="m7 8.82608696v-.48695653c0-.63913043.33478261-1.24782608.91304348-1.58260869.88260869-.51739131 1.18695652-1.61304348.66956522-2.49565217-.51739131-.8826087-1.61304348-1.18695653-2.49565218-.66956522-.54782609.33478261-.91304348.91304348-.91304348 1.58260869" /></g><path d="m7.82533029 10.0601891c.43297264.4329726.43297264 1.1349602 0 1.5679334-.43297265.4329726-1.13496021.4329726-1.5679334 0-.43297265-.4329727-.43297265-1.1349602 0-1.5679334.43297264-.43297268 1.1349602-.43297268 1.5679334 0" fill="#a4afb3" /></g></svg>                                    </span>
												</Tippy>
											</h2>
										</div>
										<div className="form-row">
											<div className="form-field -checkbox">
												<div className="form-check form-toggle">
													<input
														className="form-check-input"
														onChange={this.handleChange}
														checked={client.communication.dgtlRcpt || false}
														type="checkbox"
														value="dgtlRcpt"
														id="receipts_account"
													/>
													<label className="form-check-label" htmlFor="receipts_account">
														<TranslationText text='communication_channels_receipt_option_general' />
														<span></span>
													</label>
												</div>
												<div className="form-check">
													<input
														className="form-check-input"
														onChange={this.handleChange}
														checked={!client.communication.dgtlRcpt
															? (false)
															: (client.communication.sendEmailDgtlRcpt || false)}
														type="checkbox"
														value="sendEmailDgtlRcpt"
														id="receipts_email"
														disabled={!client.communication.dgtlRcpt}
													/>
													<label className="form-check-label" htmlFor="receipts_email">
														<TranslationText text='communication_channels_receipt_option_email' />
														<span></span>
													</label>
													<div className="sublabel">
														<TranslationText text='add_your_data_page_dgtl_rcpt_info' />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group -required">
										<div className="form-row">
											<div className="form-field -checkbox">
												<div className="form-check">
													<input className="form-check-input" onChange={this.handleChange} checked={consentsAccepted} type="checkbox" value="consentsAccepted" id="user_agreement" />
													<label className="form-check-label" htmlFor="user_agreement">
														<ConsentLinks translationKey="consent_agreement_text" />
														<span></span>
													</label>
												</div>
												<div className="sublabel error-msg show">
													<span>
														<TranslationText text={errors.consents} />
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className="form-row">
										<div className="sublabel error-msg show">
											<span>{<TranslationText text={this.state.errorText} />}</span>
										</div>
									</div>
									<div className="form-button full-width">
										<button
											type="submit"
											onClick={this.save}
											className="button full-width"
										>
											<TranslationText text='add_your_data_page_continue_btn' />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>`
				</div>
				<Footer countrySelectionEnabled={false} legalEntity={this.props.initialData.legalEntityId} />
			</React.Fragment>
			: <Spinner />;
	}
}

export default withRouter(withTranslation()(YourData));


