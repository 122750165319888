import PropTypes from "prop-types";
import TranslationText from "../Translation/TranslationText";
import { ExclamationCircledSvg } from "components/icons/ExclamationCircledSvg";

export function PasswordRuleChecklistRow(props) {
	const className = props.isRuleNeutral
		? "rule-neutral"
		: getSatisfactionClassName(props.isRuleSatisfied);

	return (
		<span className={className}>
			{props.isRuleNeutral ? <ExclamationCircledSvg /> : null}
			<TranslationText text={props.ruleTranslationKey} />
		</span>
	);
}

const getSatisfactionClassName = isSatisfied => isSatisfied ? "rule-satisfied" : "rule-unsatisfied";

PasswordRuleChecklistRow.propTypes = {
	ruleTranslationKey: PropTypes.string.isRequired,
	isRuleNeutral: PropTypes.bool,
	isRuleSatisfied: PropTypes.bool
};