import React, { Component } from "react";
import ForgotPasswordTryAgain from "../../components/Buttons/ForgotPasswordTryAgain.jsx";
import TranslationText from "../Translation/TranslationText.jsx";

class TryAgainFooterButtons extends Component {

  state = {
    showDecline: false,
    confirmText: this.props.fromLogin !== undefined && this.props.fromLogin ? "login_button_label" : "try_again_button_label",
    forgotPasswordAndTryAgainClass: this.props.fromLogin !== undefined && this.props.fromLogin ? "form-link-buttons text-center" : "form-button full-width",
  }

  confirm = () => {
    this.props.confirm();
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-button full-width">
          <button
            type="submit"
            onClick={this.confirm.bind(this)}
            className="button"
            disabled={this.props.isProcessing}
          >
            <TranslationText text={this.state.confirmText} />
          </button>
        </div>
        <ForgotPasswordTryAgain
          isB2bAccount={this.props.isB2bAccount}
          customClass={this.state.forgotPasswordAndTryAgainClass}
          isPasswordResetHidden={this.props.fromLogin}
          isCardReplacementLink={!this.props.fromLogin}
        />
      </React.Fragment>)
  }
}
export default TryAgainFooterButtons;