import PropTypes from "prop-types";

export function ExclamationCircledSvg(props) {
	return (
		<svg viewBox="0 0 1024 1024" fill={props.color ?? "#DAA520"} xmlns="http://www.w3.org/2000/svg">
			<path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 0 1 0-96 48.01 48.01 0 0 1 0 96z" />
		</svg>
	);
}

ExclamationCircledSvg.propTypes = {
	color: PropTypes.string
};