import React, { Component } from "react";
import InputText from "../../components/InputText.jsx";
import Footer from "../../components/Footer.jsx";
import fetcherApi from '../../components/FetcherApi.jsx';
import validator from '../../components/FieldValidator.jsx';
import ConsentLinks from '../../components/Consents/ConsentLinks.jsx';
import TranslationText from "../../components/Translation/TranslationText.jsx";
import { Trans, withTranslation } from 'react-i18next';
import RimiLogoHeaderButton from "../../components/HeaderComponents/RimiLogoHeaderButton.jsx";
import StepBackHeader from "../../components/HeaderComponents/StepBackHeader.jsx";
import AutoHideableHeader from "../../components/HeaderComponents/AutoHideableHeader.jsx";
import ConfirmationNoRedirect from "../../components/EmailVerification/ConfirmationNoRedirect.jsx";
import urlTools from "../../tools/urlTools.jsx";
import { PasswordRuleChecklistOverlay } from "components/components/PasswordRuleChecklist/PasswordRuleChecklistOverlay.jsx";

class AddYourDataPasswordRecovery extends Component {

	state = {
		phonePrefixes: this.props.initialData.phonePrefixes.map(data => ({ label: data.value, value: data.key })),
		password: { newPassword: '', confirmPassword: '' },
		errors: [],
		confirmed: false,
		passwordErrors: {},
		passwordCreationFlow: this.props.initialData.passwordCreationForm,
		passwordControlePlainTextMode: false,
		confirmPasswordControlePlainTextMode: false
	};

	componentDidMount() {
	}

	onChange = e => {
		const { password } = this.state;
		password[e.prop] = e.value;
		this.setState({ password, errors: {} });
	};

	onChangeConfirmPassword = e => {
		const { passwordErrors, password } = this.state;

		password['confirmPassword'] = e.value;
		passwordErrors['confirmPassword'] = validator.validatePasswords(password.newPassword, e.value, "create_password_page_login_pwd_not_match_error");
		this.setState({ password, passwordErrors, errors: {} });
	};

	onChangePassword = e => {
		const { passwordErrors, password } = this.state;
		password['newPassword'] = e.value;
		passwordErrors['newPassword'] = validator.validatePassword(e.value);
		passwordErrors['confirmPassword'] = validator.validatePasswords(e.value, password.confirmPassword, "create_password_page_login_pwd_not_match_error");
		this.setState({ password, passwordErrors, errors: {} });
	};

	hasPasswordErrors = () => {
		const { passwordErrors } = this.state;

		for (var error in passwordErrors) {
			if (passwordErrors[error] !== undefined && passwordErrors[error].length > 0) {
				return true;
			}
		}

		return false;
	}

	onTogglePasswordShow = e => {
		e.preventDefault();
		this.setState({ passwordControlePlainTextMode: !this.state.passwordControlePlainTextMode });
	};

	onToggleConfirmPasswordShow = e => {
		e.preventDefault();
		this.setState({ confirmPasswordControlePlainTextMode: !this.state.confirmPasswordControlePlainTextMode });
		console.log(this.state.confirmPasswordControlePlainTextMode);
	};

	confirm = async e => {
		const { errors, password, passwordErrors, maxPhoneLength } = this.state;
		e.preventDefault();
		var error = false;


		if (password.newPassword.length === 0) {
			errors['newPassword'] = this.state.passwordCreationFlow ? 'create_password_page_login_empty_pwd_error' : 'password_creation_page_empty_pwd_field_error';
			error = true;
		}

		if (password.confirmPassword.length === 0) {
			errors['confirmPassword'] = this.state.passwordCreationFlow ? 'create_password_page_login_empty_repeated_pwd_error' : 'password_creation_page_empty_repeated_pwd_field_error';
			error = true;
		}

		if (error) {
			this.setState({ errors });
		}
		else if (this.hasPasswordErrors()) {
			errors['confirmPassword'] = passwordErrors['confirmPassword'];
			errors['newPassword'] = passwordErrors['newPassword'];
			this.setState({ errors });
		}
		else {
			const passwordResetDto =
			{
				newPassword: password.newPassword,
				emailRecentlyVerified: this.props.location?.state?.emailVerifiedRecently
			};
			const result = (await fetcherApi.post(urlTools.constructRoute("api/PasswordRecovery/verifyAndLogin"), passwordResetDto)).response;

			if (result.data.success) {
				this.setState({ confirmed: true });
			}
			else {
				this.setState({ errorText: result.data.error });
			}
		}
	};

	showPasswordChangedSuccesfully() {
		return <React.Fragment>
			<ConfirmationNoRedirect initialData={this.props.initialData} infoText="password_change_success_page_info" titleText="password_change_success_page_title" />
		</React.Fragment>
	}


	RedirectToFavouriteStore = (e) => {
		e.preventDefault();
	}

	render() {

		const { password, confirmed, errors, passwordControlePlainTextMode, confirmPasswordControlePlainTextMode } = this.state;

		return (confirmed ? (this.showPasswordChangedSuccesfully()) :

			(<React.Fragment>
				<div className="container">
					<div className="card">
						<AutoHideableHeader>
							<RimiLogoHeaderButton initialData={this.props.initialData} />
							<StepBackHeader
								pathName={'/login'}
								buttonText={'login_button_label'}
								iconBeforeText={<svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="#a12971" strokeWidth="2" transform="translate(5 4)"><path d="m3.8 5.6v-5.6h7.7c1.2 0 2.1.9 2.1 2.1v11.8c0 1.2-.9 2.1-2.1 2.1h-7.7v-5.6" /><path d="m0 8h9" /><path d="m6.1 10.9 2.9-2.9-2.9-3" /></g></svg>}
							/>
						</AutoHideableHeader>
						<div className="card-content">
							<h1>
								<Trans i18nKey={this.state.passwordCreationFlow
									? 'create_password_page_login_title'
									: 'password_creation_page_title'}>
									New account creation. <b>Please fill information about yourself</b>
								</Trans>
							</h1>

							<div className="form-group">
								<div className="form-row">
									<div className="form-field password-form-field">
										<label htmlFor="password" className="form-field__label">
											<TranslationText text={this.state.passwordCreationFlow
												? 'create_password_page_login_pwd_title'
												: 'password_creation_page_pwd_field_title'} />
										</label>
										<PasswordRuleChecklistOverlay password={password.newPassword}>
											<div className={errors['newPassword'] !== undefined && errors['newPassword'].length > 0 ? "form-field-container -has-error" : "form-field-container"}>
												<InputText
													prop="password"
													className="form-field__input"
													value={password.newPassword}
													onChange={this.onChangePassword}
													type={passwordControlePlainTextMode ? "text" : "password"}
												/>
												<a href="#" className={passwordControlePlainTextMode ? "form-field__card-icon togglePassword show" : "form-field__card-icon togglePassword"} onClick={this.onTogglePasswordShow}>
													<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2" transform="translate(3 6)"><path d="m18 5.47826087s-4.0292609 5.47826083-9 5.47826083c-4.97073913 0-9-5.47826083-9-5.47826083s4.02926087-5.47826087 9-5.47826087c4.9707391 0 9 5.47826087 9 5.47826087z" /><path d="m11.7391304 5.47826087c0 1.51317391-1.2263478 2.73913043-2.7391304 2.73913043-1.51278261 0-2.73913043-1.22595652-2.73913043-2.73913043 0-1.5123913 1.22634782-2.73913044 2.73913043-2.73913044 1.5127826 0 2.7391304 1.22673914 2.7391304 2.73913044z" /></g></svg>
												</a>
											</div>
										</PasswordRuleChecklistOverlay>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.newPassword} />
											</span>
										</div>
									</div>
								</div>
								{/*
                                <div className="form-row">
                                    <div className="password-strength">
                                        <div className="progress-bar">
                                            <div className="bar"></div>
                                        </div>
                                        <div className="password-strength-labels">
                                            <label className="form-field__label">Weak</label>
                                            <label className="form-field__label">Strong</label>
                                        </div>
                                    </div>
                                </div>
                                */}
							</div>

							<div className="form-group">
								<div className="form-row">
									<div className="form-field password-form-field">
										<label htmlFor="passwordRepeat" className="form-field__label">
											<TranslationText text={this.state.passwordCreationFlow
												? 'create_password_page_login_repeat_pwd_title'
												: 'password_creation_page_pwd_repeat_title'} />
										</label>
										<div className={errors['confirmPassword'] !== undefined && errors['confirmPassword'].length > 0 ? "form-field-container -has-error" : "form-field-container"}>
											<InputText
												prop="confirmPassword"
												className="form-field__input"
												value={password.confirmPassword}
												onChange={this.onChangeConfirmPassword}
												type={confirmPasswordControlePlainTextMode ? "text" : "password"}
											/>
											<a href="#" className={confirmPasswordControlePlainTextMode ? "form-field__card-icon togglePassword show" : "form-field__card-icon togglePassword"} onClick={this.onToggleConfirmPasswordShow}>
												<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2" transform="translate(3 6)"><path d="m18 5.47826087s-4.0292609 5.47826083-9 5.47826083c-4.97073913 0-9-5.47826083-9-5.47826083s4.02926087-5.47826087 9-5.47826087c4.9707391 0 9 5.47826087 9 5.47826087z" /><path d="m11.7391304 5.47826087c0 1.51317391-1.2263478 2.73913043-2.7391304 2.73913043-1.51278261 0-2.73913043-1.22595652-2.73913043-2.73913043 0-1.5123913 1.22634782-2.73913044 2.73913043-2.73913044 1.5127826 0 2.7391304 1.22673914 2.7391304 2.73913044z" /></g></svg>
											</a>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.confirmPassword} />
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="form-button full-width">
								<div className="form-row">
									<div className="sublabel error-msg show">
										<span>{<TranslationText text={this.state.errorText} />}</span>
									</div>
								</div>
								<button
									type="submit"
									onClick={this.confirm}
									className="button"
								>
									<TranslationText text={this.state.passwordCreationFlow
										? 'create_password_page_login_continue_btn'
										: 'password_creation_page_save_btn'} />
								</button>
							</div>
						</div>

						<footer className="card-footer no-border">
							<hr className="spacer" />
							<div className="footer-text">
								<ConsentLinks />
							</div>
						</footer>
					</div>
				</div>
				<Footer countrySelectionEnabled={false} />
			</React.Fragment>)
		)
	}
}


export default withTranslation()(AddYourDataPasswordRecovery);


