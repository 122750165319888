import React, { Component } from "react";
import PropTypes from "prop-types";
import { createRef } from 'react';

class InputText extends Component {
  inputRef = createRef();

  static defaultProps = {
    value: "",
    customProp: "",
    className: "",
    name: "",
    type: "",
    placeholder: "",
    errors: [],
    maxLength: "",
    min: "",
    max: "",
    isSelectedOnFocus: false
  };

  static propTypes = {
    children: PropTypes.string,
    onChange: PropTypes.func,
    prop: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    customProp: PropTypes.any,
    name: PropTypes.any,
    type: PropTypes.string,
    errors: PropTypes.array,
    maxLength: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    min: PropTypes.string,
    max: PropTypes.string,
    isSelectedOnFocus: PropTypes.bool
  };

  focus = () => this.inputRef.current.focus();

  onChange = e => {
    const value = e.target.value;
    this.props.onChange({ value: value, maxLength: this.props.maxLength, min: this.props.min, max: this.props.max, placeholder: this.props.placeholder, name: this.props.name, customProp: this.props.customProp, className: this.props.className, prop: this.props.prop, type: this.props.type });
  };

  onFocus = e => {
    if (this.props.isSelectedOnFocus) {
      e.target.select();
    }
  }

  render() {

    var inputProps = {
      id: this.props.prop + "Input",
      className: (this.props.className !== "" ? this.props.className : "form-field__input"),
      value: this.props.value ?? "",
      type: (this.props.type !== "" ? this.props.type : "text"),
      placeholder: this.props.placeholder,
    };

    if (this.props.name !== "") {
      inputProps.name = this.props.name;
    }
    if (this.props.maxLength !== "") {
      inputProps.maxLength = this.props.maxLength;
    }
    if (this.props.min !== "") {
      inputProps.min = this.props.min;
    }
    if (this.props.max !== "") {
      inputProps.max = this.props.max;
    }
    if (this.props.disabled === true) {
      inputProps.disabled = true;
      inputProps.style = { cursor: 'default' };
    }

    return (
      <React.Fragment>
        {/*   <label>{this.props.children}</label>  */}
        <input
          ref={this.inputRef}
          onInput={this.onChange}
          onFocus={this.onFocus}
          {...inputProps}
        />

        {this.props.errors.map(error => (
          <div className="sublabel error-msg show">
            <span>
              {error}
            </span>
          </div>
        ))}

      </React.Fragment>
    );
  }
}

export default InputText;