import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import InputText from "../../components/InputText.jsx";
import validator from "../../components/FieldValidator.jsx";
import fetcherApi from '../../components/FetcherApi.jsx';
import Footer from "../../components/Footer.jsx";
import LegalEntityFunctions from "../../components/LegalEntityFunctions.jsx";
import * as constants from "../../constants/constants.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import ConsentLinks from "../../components/Consents/ConsentLinks.jsx";
import { Trans, withTranslation } from 'react-i18next';
import RimiLogoHeaderButton from "../../components/HeaderComponents/RimiLogoHeaderButton.jsx";
import StepBackHeader from "../../components/HeaderComponents/StepBackHeader.jsx";
import AutoHideableHeader from "../../components/HeaderComponents/AutoHideableHeader.jsx";
import { DateInput } from '../../components/DateInput.jsx';
import urlTools from "../../tools/urlTools.jsx";
import * as cardStatusCodes from "../../constants/cardStatusCodes.jsx";

class AdditionalInformation extends Component {
  _isMounted = false;
  state = {
    phonePrefixes: this.props.initialData.phonePrefixes.map(data => ({ label: data.value, value: data.key })),
    client: { name: "", phonePrefixId: "", phone: "" },
    errors: [],
    confirmed: false,
    maxPhoneLength: "",
    selectedPrefix: ""
  };

  onDropDownChange = e => {

    let index = e.nativeEvent.target.selectedIndex;
    const { client } = this.state;
    client.phonePrefixId = e.target.value;
    let prefix = e.nativeEvent.target[index].text;
    this.setState({ client, selectedPrefix: prefix, maxPhoneLength: validator.getPhoneMinLength(this.props.initialData.phonePrefixes, prefix) });
  };

  componentDidMount() {
    const { client } = this.state;
    let phonePrefixId = LegalEntityFunctions.getPhonePrefixId(localStorage.getItem(constants.SELECTED_COUNTRY));
    let phonePrefix = LegalEntityFunctions.getPhonePrefix(localStorage.getItem(constants.SELECTED_COUNTRY));
    client.phonePrefixId = phonePrefixId;
    this._isMounted = true;
    this.setState({
      client, selectedPrefix: phonePrefix,
      maxPhoneLength: validator.getPhoneMinLength(this.props.initialData.phonePrefixes, phonePrefix)
    });
  }

  createSelectItems() {
    const { phonePrefixes } = this.state;
    let items = [];
    for (let i = 0; i < phonePrefixes.length; i++) {
      let label = phonePrefixes[i].label;
      let key = phonePrefixes[i].value;
      items.push(<option key={label} value={key}>{label}</option>);
    }
    return items;
  }

  onBirthDateChange = value => {
    const { client } = this.state
    client['birthDate'] = value;
    this.setState({ client, errors: {} });
  }

  onChange = e => {
    const { client } = this.state;
    if (e.prop === 'name') {
      client['name'] = validator.blockUnwantedSymbols(e.value);
    }
    else if (e.prop === 'phone') {
      client['phone'] = e.value.replace(/[^0-9]/g, '');
    }
    else {
      client[e.prop] = e.value;
    }

    this.setState({ client, errors: {} });

  };

  componentWillUnmount() {
    this._isMounted = false;
    this.setState = (state, callback) => {
      return;
    }
  }

  confirm = async e => {

    const { client, errors, selectedPrefix } = this.state;
    let phoneError = validator.validatePhonePrefixes(this.props.initialData.phonePrefixes, selectedPrefix, client.phone);

    let error = false;
    if (client.name === '') {
      errors['name'] = 'enter_additional_information_page_empty_name_field_error';
      error = true;
    }
    if (client.birthDate === undefined || client.birthDate === '') {
      errors['birthDate'] = 'enter_additional_information_page_empty_birthdate_field_error';
      error = true;
    }
    if (client.phone === '') {
      errors['phone'] = 'enter_additional_information_page_empty_phone_field_error';
      error = true;
    }
    if (phoneError !== "") {
      errors['phone'] = phoneError;
      error = true;
    }

    if (!error) {
      const result = (await fetcherApi.post(urlTools.constructRoute("api/Account/CardReplacement/AdditionalInfo/validate"),
        {
          name: client.name,
          birthDate: client.birthDate,
          phonePrefixId: client.phonePrefixId,
          phone: client.phone
        }
      )).response;

      if (result.data.success && this._isMounted) {
        this.setState({ confirmed: true });
      } else {
        if (result.data.error === "nodata") {
          errors['onConfirm'] = ["enter_additional_information_page_no_match_found_error"];
        } else if (result.data.error === "valid_repl") {
          errors['onConfirm'] = ["not a valid pending repl!"];
        } else if (result.data.error === "verification_resend_limit_reached") {
          errors['onConfirm'] = ["card_replacement_page_sms_limit_error"];
        } else if (result.data.error === "unexpected") {
          errors['onConfirm'] = ["unexpected_error"];
        } else if (result.data.error === "rate_limit_reached") {
          errors['onConfirm'] = ["enter_additional_information_page_rate_limit_reached"]
        }
      }
    }
    if (this._isMounted) {
      this.setState({ errors });
    }
  };

  render() {

    const { client, confirmed, errors, maxPhoneLength } = this.state;

    return (
      <React.Fragment>
        <div className="container">
          <div className="card">
            <AutoHideableHeader>
              <RimiLogoHeaderButton initialData={this.props.initialData} />
              <StepBackHeader
                pathName={'/cardReplacementLogin'}
                buttonText={'step_back_button_label'} />
            </AutoHideableHeader>
            <div className="card-content no-padding-bottom">
              <h1>
                <Trans i18nKey='enter_additional_information_page_title'>
                  Please provide <b>additional information</b>
                </Trans>
              </h1>
              <p><TranslationText text='enter_additional_information_page_info' /></p>

              <div className="form-group">
                <div className={((errors['onConfirm'] !== undefined && errors['onConfirm'].length > 0)
                  || (errors['name'] !== undefined && errors['name'].length > 0))
                  ? "form-row -has-error" : "form-row"}>
                  <div className="form-field">
                    <label htmlFor="name" className="form-field__label">
                      <TranslationText text='enter_additional_information_page_fname_field_title' />
                    </label>
                    <InputText
                      className="form-field__input"
                      onChange={this.onChange}
                      prop="name"
                      value={client.name}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="sublabel error-msg show">
                    <span>
                      <TranslationText text={errors.name} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className={((errors['onConfirm'] !== undefined && errors['onConfirm'].length > 0)
                  || (errors['birthDate'] !== undefined && errors['birthDate'].length > 0))
                  ? "form-row -has-error" : "form-row"}>
                  <div className="form-field">
                    <label htmlFor="birth_date" className="form-field__label">
                      <TranslationText text='enter_additional_information_page_birthdate_field_title' />
                    </label>
                    <DateInput
                      initialDateString={client.birthDate}
                      onChange={this.onBirthDateChange}
                      errorMessage={errors.birthDate}
                    />

                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="form-row">
                  <div className="form-field">
                    <label htmlFor="email" className="form-field__label">
                      <TranslationText text='enter_additional_information_page_phone_field_title' />
                    </label>
                  </div>
                </div>

                <div className={((errors['onConfirm'] !== undefined && errors['onConfirm'].length > 0)
                  || (errors['phone'] !== undefined && errors['phone'].length > 0))
                  ? "form-row -phone-number -has-error" : "form-row -phone-number"}>
                  <div className="form-field form-field__select">
                    <select value={client.phonePrefixId} onChange={this.onDropDownChange}>
                      {this.createSelectItems()}
                    </select>
                    <svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m22.7 12.7-6.7 6.6-6.7-6.6" fill="none" stroke="#a12971" strokeMiterlimit="10" strokeWidth="2" /></svg>                    </div>
                  <div className="form-field">
                    <InputText
                      onChange={this.onChange}
                      prop="phone"
                      value={client.phone}
                      className="form-field__input -tel"
                      type="tel"
                      maxLength={maxPhoneLength}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="sublabel error-msg show">
                    <span>
                      {
                        (errors.onConfirm !== undefined && errors.onConfirm.length > 0)
                          ? <TranslationText text={errors.onConfirm[0]} />
                          : null
                      }
                      <TranslationText text={errors.phone} />
                    </span>
                  </div>
                </div>
              </div>

            </div>

            <footer className="card-footer no-border">
              <div className="form-button full-width">


                {!confirmed ? (
                  <button
                    type="submit"
                    onClick={this.confirm}
                    className="button"
                  >
                    <TranslationText text='enter_additional_information_page_continue_btn' />
                  </button>
                ) :

                  <Redirect to={{ pathname: '/VerificationCodeLogin/', state: { cardStatus: cardStatusCodes.PENDING_REG } }} />
                }
              </div>
              <div className="form-button full-width">
                <Link className="button -secondary" to={{ pathname: '/login' }}><TranslationText text='return_to_login_page' />
                </Link>
              </div>
              <div className="footer-text">
                <ConsentLinks></ConsentLinks>
              </div>
            </footer>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(AdditionalInformation);


