import AutoHideableTitle from "../HeaderComponents/AutoHideableTitle";
import TranslationText from "../Translation/TranslationText";
import InputText from "../InputText";
import ConsentLinks from "../Consents/ConsentLinks";
import Footer from "../Footer";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom";
import webTools from "components/tools/webTools";
import FieldValidator from "../FieldValidator";
import FetcherApi from "../FetcherApi";
import urlTools from "components/tools/urlTools";
import i18n from "translations/i18n.jsx";
import PropTypes from "prop-types";
import { DUPLICATE_EMAIL, DUPLICATE_PHONE, EMAIL_VALIDATION_LIMIT_REACHED, PHONE_VALIDATION_LIMIT_REACHED, UNEXPECTED_ERROR, WRONG_PASSWORD } from "components/constants/errorCodes";
import { PasswordInput } from "../PasswordInput";
import LegalEntityFunctions from "../LegalEntityFunctions";

export function ConsentsDataStepB2B(props) {
	const location = useLocation();
	const initialClientRef = useRef(null);

	const [clientData, setClientData] = useState(null);
	const [companyInformation, setCompanyInformation] = useState(null);

	const [currentPassword, setCurrentPassword] = useState("");
	const [showCurrentPasswordField, setShowCurrentPasswordField] = useState(false);

	const [readonlyFlags, setReadonlyFlags] = useState({});
	const [saveInProgress, setSaveInProgress] = useState(false);
	const [saveErrorCode, setSaveErrorCode] = useState(null);

	useEffect(() => {
		const payload = location.state?.payload;
		if (!payload) {
			webTools.reloadToRoute();
			return;
		}

		props.hideNavBar(true);

		setClientData(payload.clientData);
		setCompanyInformation(payload.companyInformation);
		initialClientRef.current = { ...payload.clientData };

		setReadonlyFlags({
			firstName: !!payload.clientData.firstName,
			registrationNumber: !!payload.companyInformation.registrationNumber
		});
	}, []);

	if (!companyInformation) {
		return null;
	}

	const onClientDataChange = (key, value) => {
		clientData[key] = FieldValidator.getGroomedProfilePropertyChange(key, value);

		setClientData({ ...clientData });
		setSaveErrorCode(null);
	}

	const onCompanyChange = (key, value) => {
		companyInformation[key] = FieldValidator.validate(key, value);

		setCompanyInformation({ ...companyInformation });
	}

	const onCurrentPasswordChange = e => {
		setSaveErrorCode(null);
		setCurrentPassword(e.value);
	}

	const onPhonePrefixChange = e => {
		const index = e.nativeEvent.target.selectedIndex;
		const prefix = e.nativeEvent.target[index].text;
		const selectedPhonePrefixId = e.target.value;

		clientData.phonePrefixId = selectedPhonePrefixId;
		clientData.phonePrefix = prefix;

		setClientData({ ...clientData });
	};

	const onDropDownCountryChange = e => {
		companyInformation.countryId = parseInt(e.target.value);

		setCompanyInformation({ ...companyInformation });
	}

	const onSave = async () => {
		if (Object.keys(errors).length || saveErrorCode) {
			return;
		}

		const phoneChanged = hasPhoneChanged();
		const emailChanged = hasEmailChanged();

		const isPasswordRequired = phoneChanged || emailChanged;

		if (isPasswordRequired && !currentPassword) {
			setShowCurrentPasswordField(true);
			return;
		} else if (!isPasswordRequired) {
			setCurrentPassword("");
			setShowCurrentPasswordField(false);
		}

		clientData.password = currentPassword;

		setSaveInProgress(true);

		const route = urlTools.constructRoute("api/Consents/b2b/Profile");
		const payload = { ...clientData, ...companyInformation };

		const response = await FetcherApi.post(route, payload);

		if (response.response.data.success) {
			webTools.reloadToRoute();
			return;
		}

		setSaveInProgress(false);
		setSaveErrorCode(response.response.data.error);
	}

	const hasPhoneChanged = () => {
		const initialClient = initialClientRef.current;
		return initialClient.phonePrefix + initialClient.phone !== clientData.phonePrefix + clientData.phone;
	}

	const hasEmailChanged = () => {
		const initialClient = initialClientRef.current;
		return initialClient.email !== clientData.email;
	}

	const maxPhoneLength = FieldValidator.getPhoneMinLength(props.initialData.phonePrefixes, clientData.phonePrefix);
	const errors = FieldValidator.getConsentsDataStepB2BValidationErrors(clientData, companyInformation, props.initialData, showCurrentPasswordField, currentPassword);

	const getEmailError = () => {
		switch (saveErrorCode) {
			case DUPLICATE_EMAIL:
				return "account_found_but_no_email_page_in_login_duplicate_email_error";
			case EMAIL_VALIDATION_LIMIT_REACHED:
				return "rate_limit_reached";
			default:
				return errors.email;
		}
	}

	const getPhoneError = () => {
		switch (saveErrorCode) {
			case DUPLICATE_PHONE:
				return "profile_information_page_duplicate_phone_error";
			case PHONE_VALIDATION_LIMIT_REACHED:
				return "rate_limit_reached";
			default:
				return errors.phone;
		}
	}

	return (
		<>
			<div className="container page-container profile-page">
				<AutoHideableTitle text="b2b_profile_information_page_title" />
				<div className="card card-full-width">
					<div className="card-content">
						<div className="row group-row">
							<div className="column-desktop-12">
								<h2 style={{ marginBottom: '0px' }}>
									<TranslationText text='b2b_profile_information_page_account_info_title' />
								</h2>
								<div className="row padded-row">
									<div className="column-desktop-4 column-tablet-6 column-mobile-12">
										<div className={errors.firstName ? "form-field  -has-error" : "form-field"}>
											<label className="form-field__label">
												<TranslationText text='profile_information_page_fname_field_title' />
											</label>
											<InputText
												prop="firstName"
												onChange={e => onClientDataChange("firstName", e.value)}
												value={clientData.firstName}
												maxLength="50"
												disabled={readonlyFlags.firstName}
											/>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.firstName} />
											</span>
										</div>
									</div>
									<div className="column-desktop-4 column-tablet-6 column-mobile-12">
										<div className={errors.lastName ? "form-field  -has-error" : "form-field"}>
											<label className="form-field__label">
												<TranslationText text='profile_information_page_lname_field_title' />
											</label>
											<InputText
												prop="lastName"
												onChange={e => onClientDataChange("lastName", e.value)}
												value={clientData.lastName}
												maxLength="50"
											/>

										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.lastName} />
											</span>
										</div>
									</div>
									<div className="column-desktop-4 column-tablet-6 column-mobile-12">
										<div className={getEmailError() ? "form-field  -has-error" : "form-field"}>
											<label className="form-field__label">
												<TranslationText text='profile_information_page_email_field_title' />
											</label>
											<InputText
												prop="email"
												onChange={e => onClientDataChange("email", e.value)}
												value={clientData.email}
												maxLength="50"
											/>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={getEmailError()}
												/>
											</span>
										</div>
									</div>
								</div>
								<div className="row padded-row">
									<div className="column-desktop-4 column-tablet-6 column-mobile-12">
										<div className="form-group no-margin-bottom">
											<div className="form-row">
												<div className="form-field">
													<label className="form-field__label">
														<TranslationText text='profile_information_page_phone_field_title' />
													</label>
												</div>
											</div>
											<div className={getPhoneError() ? "form-row -phone-number -has-error" : "form-row -phone-number"}>
												<div className="form-field form-field__select">
													<select value={clientData.phonePrefixId} onChange={e => onPhonePrefixChange(e)}>
														{createPhonePrefixSelectItems(props.initialData)}
													</select>
													<svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
														<path d="m22.7 12.7-6.7 6.6-6.7-6.6" fill="none" stroke="#a12971" strokeMiterlimit="10" strokeWidth="2"></path>
													</svg>
												</div>
												<div className="form-field">
													<InputText
														prop="phone"
														onChange={e => onClientDataChange("phone", e.value)}
														value={clientData.phone}
														className="form-field__input -tel"
														type="tel"
														maxLength={maxPhoneLength}
													/>
												</div>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={getPhoneError()}
													/>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="btn-group">
							</div>
						</div>
						<hr />

						<h2>
							<TranslationText text='b2b_profile_information_page_comp_info_field_title' />
						</h2>

						<div className="row start">

							<div className="column-medium-4 column-desktop-6 column-tablet-12">
								<div className="form-group">
									<div className="form-row">
										<div className={(errors.companyName ? "form-field  -has-error" : "form-field") + " -required"}>
											<label htmlFor="company-name" className="form-field__label">
												<TranslationText text='b2b_profile_information_page_comp_name_field_title' />
											</label>

											<InputText
												prop="companyName"
												onChange={e => onCompanyChange("companyName", e.value)}
												value={companyInformation.companyName}
												className="form-field__input"
												type="text"
												maxLength="140"
											/>

										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.companyName} />
											</span>
										</div>
									</div>
								</div>
							</div>

							<div className="column-medium-4 column-desktop-6 column-tablet-12">
								<div className="form-group">
									<div className="form-row">
										<div className="form-field">
											<label htmlFor="company_registration_number" className="form-field__label">
												<TranslationText text='b2b_profile_information_page_reg_number_field_title' />
											</label>
											<InputText
												prop="registrationNumber"
												value={companyInformation.registrationNumber}
												className="form-field__input"
												disabled={readonlyFlags.registrationNumber}
												onChange={e => onCompanyChange("registrationNumber", e.value)}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="column-medium-4 column-desktop-6 column-tablet-12">
								<div className="form-group">
									<div className="form-row">
										<div className={(errors.vatRegistrationNumber ? "form-field -has-error" : "form-field")}>
											<label htmlFor="company_vat_number" className="form-field__label">
												<TranslationText text='b2b_profile_information_page_vat_number_field_title' />
											</label>

											<InputText
												prop="vatRegistrationNumber"
												onChange={e => onCompanyChange("vatRegistrationNumber", e.value)}
												value={companyInformation.vatRegistrationNumber}
												className="form-field__input"
												type="tel"
												maxLength="14"
											/>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.vatRegistrationNumber} />
											</span>
										</div>
									</div>
								</div>
							</div>

							<div className="column-medium-4 column-desktop-6 column-tablet-12">
								<div className="form-group">
									<div className="form-row">
										<div className="form-field -required">
											<label htmlFor="country" className="form-field__label">
												<TranslationText text='b2b_profile_information_page_country_field_title' />
											</label>
										</div>
										<div className="form-field form-field__select">
											<select value={companyInformation.countryId} onChange={onDropDownCountryChange}>
												{createCountrySelectItems(props.initialData)}
											</select>
											<svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m22.7 12.7-6.7 6.6-6.7-6.6" fill="none" stroke="#a12971" strokeMiterlimit="10" strokeWidth="2" /></svg></div>
									</div>
								</div>
							</div>

							<div className="column-medium-4 column-desktop-6 column-tablet-12">
								<div className="form-group">
									<div className="form-row">
										<div className={(errors.legalAddress ? "form-field -has-error" : "form-field") + " -required"}>
											<label htmlFor="company_legal_address" className="form-field__label">
												<TranslationText text='b2b_profile_information_page_address_field_title' />
											</label>
											<InputText
												prop="legalAddress"
												onChange={e => onCompanyChange("legalAddress", e.value)}
												value={companyInformation.legalAddress}
												className="form-field__input"
												type="text"
												maxLength="60"
											/>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.legalAddress} />
											</span>
										</div>
									</div>
								</div>
							</div>

							<div className="column-medium-4 column-desktop-6 column-tablet-12">
								<div className="form-group">
									<div className="form-row">
										<div className={(errors.city ? "form-field -has-error" : "form-field") + " -required"}>
											<label htmlFor="company_city_region" className="form-field__label">
												<TranslationText text='b2b_profile_information_page_city_field_title' />
											</label>

											<InputText
												prop="city"
												onChange={e => onCompanyChange("city", e.value)}
												value={companyInformation.city}
												className="form-field__input"
												type="text"
												maxLength="40"
											/>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.city} />
											</span>
										</div>
									</div>
								</div>
							</div>

							<div className="column-medium-4 column-desktop-6 column-tablet-12">
								<div className="form-group">
									<div className="form-row">
										<div className={errors.subTown ? "form-field  -has-error" : "form-field"}>
											<label htmlFor="company_city_parish" className="form-field__label">
												<TranslationText text='b2b_profile_information_page_subtown_field_title' />
											</label>


											<InputText
												onChange={e => onCompanyChange("subTown", e.value)}
												prop="subTown"
												value={companyInformation.subTown}
												className="form-field__input"
												type="text"
												maxLength="50"
											/>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.subTown} />
											</span>
										</div>
									</div>
								</div>
							</div>

							<div className="column-medium-4 column-desktop-6 column-tablet-12">
								<div className="form-group">
									<div className="form-row">
										<div className={(errors.zipCode ? "form-field -has-error" : "form-field") + " -required"}>
											<label htmlFor="company_city_postcode" className="form-field__label">
												<TranslationText text='b2b_profile_information_page_zip_field_title' />
											</label>
											<InputText
												onChange={e => onCompanyChange("zipCode", e.value)}
												prop="zipCode"
												value={companyInformation.zipCode}

												className="form-field__input"
												type="text"
												maxLength={LegalEntityFunctions.getZipCodeLength(props.initialData.legalEntityId)}
											/>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.zipCode} />
											</span>
										</div>

									</div>
								</div>
							</div>
						</div>
						{
							showCurrentPasswordField
								? (
									<div className="row group-row">
										<div className="column-desktop-12">
											<h2 style={{ marginBottom: '0px' }}>
												<TranslationText text='profile_information_page_confirm_password_title' />
											</h2>
											<div className="row padded-row">
												<div className="column-desktop-4 column-tablet-6 column-mobile-12">
													<PasswordInput
														label="password_change_page_current_password_label"
														value={currentPassword}
														onChange={onCurrentPasswordChange}
														error={saveErrorCode === WRONG_PASSWORD
															? "password_change_page_current_password_incorrect_error"
															: errors.currentPassword
														}
													/>
												</div>
											</div>
										</div>
									</div>
								) : null
						}
						<hr />
						<div className="row profile-footer">
							<div className="column-medium-6 column-desktop-6 column-tablet-12">
								<ConsentLinks preselectedLegalEntityId={props.initialData.legalEntityId} />
							</div>
							<div className="column-medium-3 column-desktop-3 column-tablet-12">
								<div className="form-button full-width" style={{ marginTop: 0 }}>
									<Link className="button -secondary" to="/ConsentsAcceptance">
										<TranslationText text="step_back_button_label" />
									</Link>
								</div>
							</div>
							<div className="column-medium-3 column-desktop-3 column-tablet-12">
								<div className="full-width">
									{
										saveErrorCode === UNEXPECTED_ERROR ? (
											<div className="form-row">
												<div className="sublabel error-msg show">
													<span>{<TranslationText text={UNEXPECTED_ERROR} />}</span>
												</div>
											</div>
										) : null
									}
									<button
										type="submit"
										onClick={onSave}
										className="button full-width"
										disabled={saveInProgress}
									>
										<TranslationText text='profile_information_page_save_btn' />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
			<Footer countrySelectionEnabled={false} legalEntity={props.initialData.legalEntityId} />
		</>
	);
}

ConsentsDataStepB2B.propTypes = {
	initialData: PropTypes.object.isRequired,
	hideNavBar: PropTypes.func.isRequired,
};

const createPhonePrefixSelectItems = (initialData) => {
	const items = [];

	for (let phonePrefix of initialData.phonePrefixes) {
		items.push(<option key={phonePrefix.value} value={phonePrefix.key}>{phonePrefix.value}</option>);
	}

	return items;
}

const createCountrySelectItems = (initialData) => {
	const availableCountries = initialData.availableCountries.map(data => ({ label: data.value, value: data.key }));
	const items = [];

	for (let availableCountry of availableCountries) {
		const label = availableCountry.label;
		const key = availableCountry.value;
		const translationKey = 'country_name_' + label.toLowerCase();

		items.push(<option key={label} value={key}>{i18n.t(translationKey)}</option>);
	}

	return items;
}