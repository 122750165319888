import { RETURN_URL_KEY, OIDC_QUERY_KEY, REDIRECT_URL_KEY } from "components/constants/constants.jsx";
import urlTools from "./urlTools.jsx";

export default {
	getSessionStorageItem(key) {
		return window.sessionStorage.getItem(key);
	},
	setSessionStorageItem(key, value) {
		window.sessionStorage.setItem(key, value);
	},
	reloadToRoute(route = "") {
		if (route) {
			window.location.href = urlTools.constructRoute("spa/" + route + window.location.search);

			return;
		}

		const redirectUrl = getRedirectUrl();
		if (redirectUrl) {
			window.location.href = redirectUrl;
			return;
		}

		const returnUrl = getReturnUrl();
		if (returnUrl) {
			const url = urlTools.constructRoute(returnUrl);
			window.location.href = url;
			return;
		}

		window.location.href = urlTools.constructRoute("spa/" + window.location.search);
	}
}

const getReturnUrl = () => {
	let returnUrl = new URLSearchParams(window.location.search).get(RETURN_URL_KEY) ?? "";
	if (!returnUrl) {
		const oidcUrl = sessionStorage.getItem(OIDC_QUERY_KEY) ?? "";
		returnUrl = new URLSearchParams(oidcUrl).get(RETURN_URL_KEY) ?? "";
	}

	return returnUrl;
}

const getRedirectUrl = () => {
	let redirectUrl = new URLSearchParams(window.location.search).get(REDIRECT_URL_KEY) ?? "";

	return redirectUrl;
}