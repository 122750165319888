import { useEffect } from "react";
import TranslationText from "../Translation/TranslationText.jsx";

export function TemporarySuccessDisplay(props) {
  useEffect(() => {
    if (props.isDisplayed) {
      setTimeout(() => props.onExpiration(), displayDuration);
    }
  }, [props.isDisplayed])

  if (!props.isDisplayed) {
    return null;
  }

  return props.message ? (
    <div className="form-row">
      <div className="sublabel temporary-success-message">
        <span>
          <TranslationText text={props.message} />
        </span>
      </div>
    </div>
  ) : (
    <div className="sublabel temporary-success-message icon-only">
      <img src={require("assets/svg/icons/success-icon.svg").default} alt="success-icon" height="24px" />
    </div>
  );
}

const displayDuration = 4000;