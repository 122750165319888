import React, { Component } from "react";
import Footer from "../../components/Footer.jsx";
import { Link } from "react-router-dom";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import RimiLogoHeaderButton from "components/components/HeaderComponents/RimiLogoHeaderButton.jsx";

class EmailExpiredPasswordRecovery extends Component {
  render() {
    return <React.Fragment>
      <div className="container">
        <div className="card">
          <header className="card-header">
            <RimiLogoHeaderButton initialData={this.props.initialData} />
          </header>
          <div className="card-content">
            <div className="single-message">
              <h1><TranslationText text='email_verification_link_has_expired_page_title' /></h1>
              <p>
                <TranslationText text='pwd_reset_verification_link_expired_page_info' />
              </p>
              <div className="form-button full-width">
                <Link className="button" to={{ pathname: '/login' }}>
                  <TranslationText text='return_to_login_page' />
                </Link>
              </div>
            </div>
          </div>

          <footer className="card-footer no-border"></footer>
        </div>
      </div>
      <Footer countrySelectionEnabled={false} />
    </React.Fragment>
  }
}

export default EmailExpiredPasswordRecovery;


