import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Spinner from '../profile/Information/Spinner.jsx';
import ConfirmationNoRedirect from "../components/EmailVerification/ConfirmationNoRedirect.jsx";

class EmailVerifiedProfile extends Component {
  _isMounted = false;
  state = {
    confirmed: false,
    linkExpired: false,
    loaded: false
  };

  componentDidMount() {
    this._isMounted = true;
    let emailVerificationFailed = this.props.initialData.emailVerificationFailed;
    if (emailVerificationFailed) {
      this.setState({ linkExpired: true });
    }
    else {
      this.setState({ confirmed: true, loaded: true });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState = (state, callback) => {
      return;
    }
  }

  render() {
    if (this._isMounted) {
      if (this.state.linkExpired) {
        return (<Redirect to={{ pathname: '/registration/NoAccount/EmailExpired' }} />)
      }
      else if (this.state.loaded) {
        return <React.Fragment>
          <ConfirmationNoRedirect initialData={this.props.initialData} infoText="email_verify_page_no_redirect" titleText="email_verified_page_title" />
        </React.Fragment>
      }
      else
        return <Spinner />
    }
    else
      return <Spinner />
  }
}

export default EmailVerifiedProfile;