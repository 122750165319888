import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import InputText from "../../components/InputText.jsx";
import Footer from "../../components/Footer.jsx"
import ConsentLinks from "../../components/Consents/ConsentLinks.jsx";
import * as constants from "../../constants/constants.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import StepBackHeader from "../../../components/components/HeaderComponents/StepBackHeader.jsx";
import RimiLogoHeaderButton from "../../../components/components/HeaderComponents/RimiLogoHeaderButton.jsx";
import AutoHideableHeader from "../../../components/components/HeaderComponents/AutoHideableHeader.jsx";
import { Trans, withTranslation } from 'react-i18next';
import CardReplacementValidation from '../../../components/components/CardReplacementValidation.jsx';
import urlTools from "../../tools/urlTools.jsx";

class CardReplacement extends Component {
  _isMounted = false;
  state = {
    card: {
      cardPrefix: "",
      cardNumber: ""
    },
    errors: [],
    confirmed: false
  };

  componentDidMount() {
    const { card } = this.state;
    const selectedCountry = localStorage.getItem(constants.SELECTED_COUNTRY);
    card.cardPrefix = constants.CARD_NUMBER_PREFIX_START + this.props.initialData.cardPrefixes.find(cp => cp.key === selectedCountry).value;

    this._isMounted = true;
    this.setState({ card });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState = (state, callback) => {
      return;
    }
  }

  onChange = e => {
    const { card, errors } = this.state;
    errors['onConfirm'] = [];
    card[e.prop] = e.value.replace(/[^0-9]/g, '');
    if (this._isMounted) {
      this.setState({ card, errors });
    }
  };

  handleClick = _ => {
    this.props.hideNavBar(false);
  }

  confirm = async e => {
    const { card, errors } = this.state;
    e.preventDefault();
    var validationResult = await CardReplacementValidation.validateCardReplacement(card, urlTools.constructRoute("api/profile/CardReplacement/"));

    if (validationResult.isValid) {
      this.setState({ confirmed: true });
    }
    else {
      errors['onConfirm'] = validationResult.error;
    }

    if (this._isMounted) {
      this.setState({ errors }, () => { });
    }
  };

  render() {

    const { card, errors, confirmed } = this.state;

    return (
      <React.Fragment>
        <div className="container">
          <div className="card">
            <AutoHideableHeader>
              <RimiLogoHeaderButton onClick={this.handleClick.bind(this)} to="/profileInformation" />
              <StepBackHeader handleClick={this.handleClick.bind(this)}
                pathName={'/ProfileInformation'}
                buttonText={'back_to_profile_btn'} />
            </AutoHideableHeader>
            <div className="card-content no-padding-bottom">
              <h1>
                <Trans i18nKey='card_replacement_from_profile_page_title'>
                  Enter last 8 digits of <b>new My Rimi ID</b>
                </Trans>
              </h1>

              <div className="form-group">
                <div className="form-row rimi-card-back">
                  <img className="rimi-card-back-image" src={require("assets/img/rimi-card-back.jpg")} alt="Mans Rimi" />
                  <div className="card-numbers">
                    <div className="form-col">
                      <div className="form-field">

                        <InputText
                          prop="cardPrefix"
                          className="form-field__input"
                          value={card.cardPrefix}
                          onChange={this.onChange}
                          maxLength="11"
                          type="tel"
                        />
                      </div>
                    </div>
                    <div className="form-col">
                      <div className="form-field">
                        <InputText
                          prop="cardNumber"
                          className="form-field__input"
                          value={card.cardNumber}
                          onChange={this.onChange}
                          maxLength="8"
                          type="tel"
                        />
                      </div>
                    </div>
                  </div>
                </div>


                <div className="sublabel error-msg show">
                  <span>
                    {
                      (errors.onConfirm !== undefined && errors.onConfirm.length > 0)
                        ? <TranslationText text={errors.onConfirm[0]} />
                        : null
                    }
                  </span>
                </div>

              </div>

              <div className="form-button full-width">

                {!confirmed ? (
                  <button
                    type="submit"
                    onClick={this.confirm}
                    className="button"
                  >
                    <TranslationText text='card_replacement_from_profile_page_confirm_btn' />
                  </button>
                ) :

                  <Redirect to={{ pathname: '/verificationCode', state: { fromCardTypeSelection: false } }} />
                }
              </div>

              <div className="form-button full-width">

                <Link className="button -secondary" onClick={this.handleClick.bind(this)} to={{ pathname: '/ProfileInformation' }}>
                  <TranslationText text='card_replacement_from_profile_page_return_btn' />
                </Link>
              </div>

              <hr className="spacer" />
            </div>

            <footer className="card-footer no-border">
              <div className="footer-text">
                <ConsentLinks />
              </div>
            </footer>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(CardReplacement);


