import React, { Component } from "react";
import * as constants from "../../constants/constants.jsx";

// this header component automatically gets hidden if client came from mobileApp
class AutoHideableHeader extends Component {

  state = {
    isMobileApp: false,
  }

  async componentDidMount() {
    this.setState({ isMobileApp: localStorage.getItem(constants.IS_MOBILE_APP_STORAGE_KEY) === constants.TRUE });
  }

  render() {
    if (this.state.isMobileApp) {
      return <React.Fragment />
    }
    else {
      return <header className="card-header">
        {this.props.children}
      </header>
    }
  }
}
export default AutoHideableHeader;