import FetcherApi from "components/components/FetcherApi";
import urlTools from "components/tools/urlTools";
import webTools from "components/tools/webTools";
import * as constants from "components/constants/constants";

const AuthService = {
    logout: async () => {
        await FetcherApi.post(urlTools.constructRoute("api/account/logout"));
        window.sessionStorage.removeItem(constants.HANDSHAKE_SESSION_STORAGE);
        webTools.reloadToRoute("login");
    }
};

export default AuthService;