import React, { Component } from "react";
import TranslationText from "./Translation/TranslationText.jsx";

class Decline extends Component {

  state = {
    newPlate: {},
    errors: {},
  }

  hideDecline = () => {
    this.props.hideDecline();
  }

  returnToLogin = () => {
    this.props.returnToLogin();
  }

  render() {

    return <div className="modal-container privacy-modal show">
      <div className="modal-backdrop"></div>
      <div className="modal-window-scrollspace">
        <div className="modal-window modal-show" id="privacy-modal">
          <div className="modal-content">
            <div className="single-message">
              <div className="icon">
                <svg height="110" viewBox="0 0 125 110" width="125" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" transform="translate(3 4)"><path d="m59.4285714 0 59.4285716 104h-118.857143z" stroke="#ed1c24" strokeWidth="3" /><path d="m59.428571 37.142857v34.666667" stroke="#ed1c24" strokeWidth="3" /><path d="m64.3809524 84.1904762c0 2.7337143-2.2161905 4.9523809-4.952381 4.9523809-2.7361904 0-4.9523809-2.2186666-4.9523809-4.9523809s2.2161905-4.952381 4.9523809-4.952381c2.7361905 0 4.952381 2.2186667 4.952381 4.952381" fill="#ed1c24" /></g></svg>                    </div>
              <h2>
                <b>
                  <TranslationText text={this.props.oldConsents
                    ? 'update_consents_are_you_sure_popup_title'
                    : 'missing_consents_are_you_sure_popup_page_title'} />
                </b>
              </h2>
              <p>
                <TranslationText text={this.props.oldConsents
                  ? 'update_consents_are_you_sure_popup_info'
                  : 'missing_consents_are_you_sure_popup_page_info'} />
              </p>
            </div>
            <div className="form-button split">
              <button onClick={this.hideDecline} className="modal-close button -primary">
                <span>
                  <TranslationText text='update_consents_are_you_sure_popup_stay_btn' />
                </span>
              </button>
              <button onClick={this.returnToLogin} className="button -secondary">
                <span>
                  <TranslationText text='update_consents_are_you_sure_popup_exit_btn' />
                </span>
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  }
}
export default Decline;