import React, { Component } from "react";
import * as constants from "../../constants/constants.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";

// this title component automatically gets hidden if client came from mobileApp
class AutoHideableTitle extends Component {

  state = {
    isMobileApp: false,
  }

  async componentDidMount() {
    this.setState({ isMobileApp: localStorage.getItem(constants.IS_MOBILE_APP_STORAGE_KEY) === constants.TRUE });
  }

  render() {
    if (this.state.isMobileApp) {
      return <React.Fragment />
    }
    else {
      return <h1>
        <TranslationText text={this.props.text} />
      </h1>
    }
  }
}
export default AutoHideableTitle;