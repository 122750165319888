export default {
  getKidsClubPicture(legalEntity) {
    if (legalEntity === "1") {
      return require('assets/svg/lt-kids-club.svg').default;
    }
    else if (legalEntity === "2") {
      return require('assets/svg/kids-club.svg').default;
    }
    else if (legalEntity === "3") {
      return require('assets/img/ee-kidsclub.png');
    }
  }
};