import AutoHideableTitle from "../HeaderComponents/AutoHideableTitle";
import TranslationText from "../Translation/TranslationText";
import InputText from "../InputText";
import { DateInput } from "../DateInput";
import ConsentLinks from "../Consents/ConsentLinks";
import Footer from "../Footer";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom";
import webTools from "components/tools/webTools";
import FieldValidator from "../FieldValidator";
import FetcherApi from "../FetcherApi";
import urlTools from "components/tools/urlTools";
import PropTypes from "prop-types";
import { DUPLICATE_EMAIL, DUPLICATE_PHONE, EMAIL_VALIDATION_LIMIT_REACHED, PHONE_VALIDATION_LIMIT_REACHED, UNEXPECTED_ERROR, WRONG_PASSWORD } from "components/constants/errorCodes";
import { PasswordInput } from "components/components/PasswordInput.jsx";

export function ConsentsDataStepB2C(props) {
	const location = useLocation();
	const initialClientRef = useRef(null);

	const [client, setClient] = useState(null);
	const [readonlyFlags, setReadonlyFlags] = useState({});

	const [saveInProgress, setSaveInProgress] = useState(false);
	const [saveErrorCode, setSaveErrorCode] = useState(null);

	const [currentPassword, setCurrentPassword] = useState("");
	const [showCurrentPasswordField, setShowCurrentPasswordField] = useState(false);

	useEffect(() => {
		const client = location.state?.payload;
		if (!client) {
			webTools.reloadToRoute();
			return;
		}

		props.hideNavBar(true);

		setClient(client);
		initialClientRef.current = { ...client };

		setReadonlyFlags({
			name: !!client.name,
			birthDate: !!client.birthDate,
			gender: !!client.gender
		});
	}, []);

	if (!client) {
		return null;
	}

	const onChange = (key, value) => {
		client[key] = FieldValidator.getGroomedProfilePropertyChange(key, value);

		setClient({ ...client });
		setSaveErrorCode(null);
	}

	const onCurrentPasswordChange = e => {
		setSaveErrorCode(null);
		setCurrentPassword(e.value);
	}

	const onGenderChange = value => {
		const genderCode = value === "male" ? "1" : "2";
		client.gender = genderCode;

		setClient({ ...client });
	}

	const onPhonePrefixChange = e => {
		const index = e.nativeEvent.target.selectedIndex;
		const prefix = e.nativeEvent.target[index].text;
		const selectedPhonePrefixId = e.target.value;

		client.phonePrefixId = selectedPhonePrefixId;
		client.phonePrefix = prefix;

		setClient({ ...client });
	};

	const createSelectItems = () => {
		const items = [];

		for (let phonePrefix of props.initialData.phonePrefixes) {
			items.push(<option key={phonePrefix.value} value={phonePrefix.key}>{phonePrefix.value}</option>);
		}

		return items;
	}

	const hasPhoneChanged = () => {
		const initialClient = initialClientRef.current;
		return initialClient.phonePrefix + initialClient.phone !== client.phonePrefix + client.phone;
	}

	const hasEmailChanged = () => {
		const initialClient = initialClientRef.current;
		return initialClient.email !== client.email;
	}

	const onSave = async () => {
		if (Object.keys(errors).length || saveErrorCode) {
			return;
		}

		const phoneChanged = hasPhoneChanged();
		const emailChanged = hasEmailChanged();

		const isPasswordRequired = phoneChanged || emailChanged;

		if (isPasswordRequired && !currentPassword) {
			setShowCurrentPasswordField(true);
			return;
		} else if (!isPasswordRequired) {
			setCurrentPassword("");
			setShowCurrentPasswordField(false);
		}

		client.password = currentPassword;

		setSaveInProgress(true);
		const response = await FetcherApi.post(urlTools.constructRoute("api/Consents/Profile"), client);

		if (response.response.data.success) {
			webTools.reloadToRoute();
			return;
		}

		setSaveInProgress(false);
		setSaveErrorCode(response.response.data.error);
	}

	const maxPhoneLength = FieldValidator.getPhoneMinLength(props.initialData.phonePrefixes, client.phonePrefix);
	const errors = FieldValidator.getConsentsDataStepB2CValidationErrors(client, props.initialData, readonlyFlags, showCurrentPasswordField, currentPassword);

	const getEmailError = () => {
		switch (saveErrorCode) {
			case DUPLICATE_EMAIL:
				return "account_found_but_no_email_page_in_login_duplicate_email_error";
			case EMAIL_VALIDATION_LIMIT_REACHED:
				return "rate_limit_reached";
			default:
				return errors.email;
		}
	}

	const getPhoneError = () => {
		switch (saveErrorCode) {
			case DUPLICATE_PHONE:
				return "profile_information_page_duplicate_phone_error";
			case PHONE_VALIDATION_LIMIT_REACHED:
				return "rate_limit_reached";
			default:
				return errors.phone;
		}
	}

	return (
		<>
			<div className="container page-container profile-page">
				<AutoHideableTitle text={'profile_information_page_menu_bar_profile_info'} />
				<div className="card card-full-width">
					<div className="card-content">
						<div className="row group-row">
							<div className="column-desktop-12">
								<h2 style={{ marginBottom: '0px' }}>
									<TranslationText text='profile_information_page_personal_data' />
								</h2>
								<div className="row padded-row">
									<div className="column-desktop-4 column-tablet-6 column-mobile-12">
										<div className={errors.name ? "form-field  -has-error" : "form-field"}>
											<label className="form-field__label">
												<TranslationText text='profile_information_page_fname_field_title' />
											</label>
											<InputText
												prop="firstName"
												onChange={e => onChange("name", e.value)}
												value={client.name}
												maxLength="50"
												disabled={readonlyFlags.name}
											/>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.name} />
											</span>
										</div>
									</div>
									<div className="column-desktop-4 column-tablet-6 column-mobile-12">
										<div className={errors.lastName ? "form-field  -has-error" : "form-field"}>
											<label className="form-field__label">
												<TranslationText text='profile_information_page_lname_field_title' />
											</label>
											<InputText
												prop="lastName"
												onChange={e => onChange("lastName", e.value)}
												value={client.lastName}
												maxLength="50"
											/>

										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.lastName} />
											</span>
										</div>
									</div>
									<div className="column-desktop-4 column-tablet-6 column-mobile-12">
										<div className={errors.birthDate ? "form-field -has-error" : "form-field"}>
											<label className="form-field__label">
												<TranslationText text='profile_information_page_birthdate_field_title' />
											</label>
											<DateInput
												initialDateString={client.birthDate}
												errorMessage={errors.birthDate}
												onChange={v => onChange("birthDate", v)}
												readOnly={readonlyFlags.birthDate}
											/>

										</div>
									</div>
								</div>
								<div className="row padded-row">
									<div className="column-desktop-4 column-tablet-6 column-mobile-12">
										<div className={getEmailError() ? "form-field  -has-error" : "form-field"}>
											<label className="form-field__label">
												<TranslationText text='profile_information_page_email_field_title' />
											</label>
											<InputText
												prop="email"
												onChange={e => onChange("email", e.value)}
												value={client.email}
												maxLength="50"
											/>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={getEmailError()} />
											</span>
										</div>
									</div>
									<div className="column-desktop-4 column-tablet-6 column-mobile-12">
										<div className="form-group no-margin-bottom">
											<div className="form-row">
												<div className="form-field">
													<label className="form-field__label">
														<TranslationText text='profile_information_page_phone_field_title' />
													</label>
												</div>
											</div>
											<div className={getPhoneError() ? "form-row -phone-number  -has-error" : "form-row -phone-number"}>
												<div className="form-field form-field__select">
													<select value={client.phonePrefixId} onChange={e => onPhonePrefixChange(e)}>
														{createSelectItems()}
													</select>
													<svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
														<path d="m22.7 12.7-6.7 6.6-6.7-6.6" fill="none" stroke="#a12971" strokeMiterlimit="10" strokeWidth="2"></path>
													</svg>
												</div>
												<div className="form-field">
													<InputText
														prop="phone"
														onChange={e => onChange("phone", e.value)}
														value={client.phone}
														className="form-field__input -tel"
														type="tel"
														maxLength={maxPhoneLength}
													/>
												</div>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={getPhoneError()} />
												</span>
											</div>
										</div>
									</div>
									<div className="column-desktop-4 column-tablet-6 column-mobile-12">
										<div className={errors.gender ? "form-field -radio gender -has-error" : "form-field -radio gender"}>
											<label className="form-field__label">
												<TranslationText text='profile_information_page_gender_title' />
											</label>
											<div className="form-check inline">
												<input onChange={e => onGenderChange(e.target.value)} className="form-check-input" name="gender" type="radio" value="male" id="male"
													checked={client.gender === "1"}
													disabled={readonlyFlags.gender}
												/>
												<label className="form-check-label" htmlFor="male">
													<TranslationText text='profile_information_page_gender_male' />
													<span></span>
												</label>
											</div>
											<div className="form-check inline">
												<input onChange={e => onGenderChange(e.target.value)} className="form-check-input" name="gender" type="radio" value="female" id="female"
													checked={client.gender === "2"}
													disabled={readonlyFlags.gender}
												/>
												<label className="form-check-label" htmlFor="female">
													<TranslationText text='profile_information_page_gender_female' />
													<span></span>
												</label>
											</div>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.gender} />
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="btn-group">
							</div>
						</div>
						{
							showCurrentPasswordField
								? (
									<div className="row group-row">
										<div className="column-desktop-12">
											<h2 style={{ marginBottom: '0px' }}>
												<TranslationText text='profile_information_page_confirm_password_title' />
											</h2>
											<div className="row padded-row">
												<div className="column-desktop-4 column-tablet-6 column-mobile-12">
													<PasswordInput
														label="password_change_page_current_password_label"
														value={currentPassword}
														onChange={onCurrentPasswordChange}
														error={saveErrorCode === WRONG_PASSWORD
															? "password_change_page_current_password_incorrect_error"
															: errors.currentPassword
														}
													/>
												</div>
											</div>
										</div>
									</div>
								) : null
						}
						<hr />
						<div className="row profile-footer">
							<div className="column-medium-6 column-desktop-6 column-tablet-12">
								<ConsentLinks preselectedLegalEntityId={props.initialData.legalEntityId} />
							</div>
							<div className="column-medium-3 column-desktop-3 column-tablet-12">


								<div className="form-button full-width" style={{ marginTop: 0 }}>
									<Link className="button -secondary" to="/ConsentsAcceptance">
										<TranslationText text="step_back_button_label" />
									</Link>
								</div>
							</div>
							<div className="column-medium-3 column-desktop-3 column-tablet-12">
								<div className="full-width">
									{
										saveErrorCode === UNEXPECTED_ERROR ? (
											<div className="form-row">
												<div className="sublabel error-msg show">
													<span>{<TranslationText text={UNEXPECTED_ERROR} />}</span>
												</div>
											</div>
										) : null
									}
									<button
										type="submit"
										onClick={onSave}
										className="button full-width"
										disabled={saveInProgress}
									>
										<TranslationText text='profile_information_page_save_btn' />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
			<Footer countrySelectionEnabled={false} legalEntity={props.initialData.legalEntityId} />
		</>
	);
}

ConsentsDataStepB2C.propTypes = {
	initialData: PropTypes.object.isRequired,
	hideNavBar: PropTypes.func.isRequired,
};