import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer.jsx";
import TranslationText from "../components/Translation/TranslationText.jsx";
import i18n from "translations/i18n.jsx";
import { withTranslation } from 'react-i18next';
import CountryService from "../tools/localization/CountryService.jsx";
import RimiLogoHeaderButton from "components/components/HeaderComponents/RimiLogoHeaderButton.jsx";

class CountrySelection extends Component {

  state = {
    selectedCountry: 'lv',
  };

  constructor(props) {
    super(props);
    if (this.props.initialData.isUserLoggedIn) {
      this.props.history.push('/profileInformation');
    }
  }

  componentDidMount() {
  }

  confirm = async e => {
    CountryService.setCountry(this.state.selectedCountry);
  };

  onDropDownChange = e => {
    this.setState({ selectedCountry: e.target.value });
  };

  createSelectItems() {

    var countries = [{ label: 'Latvia', value: "lv", translationKey: "country_name_latvia" },
    { label: 'Lithuania', value: "lt", translationKey: "country_name_lithuania" },
    { label: 'Estonia', value: "ee", translationKey: "country_name_estonia" }
    ];

    let items = [];
    for (let i = 0; i < countries.length; i++) {
      let label = countries[i].label;
      let key = countries[i].value;
      let translationKey = countries[i].translationKey;
      items.push(<option key={label} value={key}>{i18n.t(translationKey)}</option>);
    }
    return items;
  }

  render() {
    return (
      <React.Fragment>
        <form>
          <div className="container">
            <div className="card">
              <header className="card-header">
                <RimiLogoHeaderButton initialData={this.props.initialData} />
              </header>
              <div className="card-content">
                <h1>
                  <b>
                    <TranslationText text='country_selection_page_title' />
                  </b>
                </h1>
                <label className="form-field__label"><TranslationText text='country_selection_page_info' /></label>

                <div className="form-field form-field__select">
                  <select value={this.state.selectedCountry} onChange={this.onDropDownChange}>
                    {this.createSelectItems()}
                  </select>
                  <svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d="m22.7 12.7-6.7 6.6-6.7-6.6" fill="none" stroke="#a12971" strokeMiterlimit="10" strokeWidth="2"></path>
                  </svg>
                </div>

                <div className="form-button full-width">
                  <Link className="button" onClick={this.confirm} to={{ pathname: '/login', search: location.search }}>
                    <TranslationText text='country_selection_page_next_btn' />
                  </Link>
                </div>
              </div>
              <footer className="card-footer">

              </footer>
            </div>
          </div>
        </form>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>);
  }
}

export default withTranslation()(CountrySelection);


