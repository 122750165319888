import Fuse from 'fuse.js';

export default function fuzzySearch(options) {
  const fuse = new Fuse(options, {
    keys: ['name', 'nameWithEnglishLettersOnly', 'items.name'],
    threshold: 0.3,
  });

  return (name) => {

    console.log(options);

    if (!name.length) {
      return options;
    }

    return fuse.search(name).map(o => o.item);
  };
}