import React, { Component } from "react";
import InputText from "../components/InputText.jsx";
import validator from "../../components/components/FieldValidator.jsx";
import TranslationText from "./Translation/TranslationText.jsx";
import { Trans, withTranslation } from "react-i18next";

class Modal extends Component {

  state = {
    newPlate: {},
    errors: {},
  }

  hideModal = () => {
    this.props.hideModal();

  }

  addCarPlate = (e) => {

    const { newPlate, errors } = this.state;

    event.preventDefault();
    event.stopPropagation();

    if (newPlate.numberPlate === undefined || newPlate.numberPlate.length === 0) {
      errors['numberPlate'] = "add_car_plate_popup_page_empty_number_field_error";
      this.setState({ errors });
    }
    else {
      this.props.onSave(this, newPlate.numberPlate);
    }
  }

  onChange = e => {
    let value = e.value;
    this.setState({ errors: {} });

    if (e.value.length <= 10) {

      const { newPlate } = this.state
      value = validator.validateCarPlate(value);

      newPlate['numberPlate'] = value;
      this.setState({ newPlate });
    }
  }

  render() {
    const { newPlate, errors } = this.state;


    return <div>
      <div className="modal-container show">
        <div className="modal-backdrop"></div>
        <div className="modal-window-scrollspace">

          <div className="modal-window modal-show" id="add-new-car">
            <div className="modal-header">
              <div className="icon">
                <img src={require("assets/svg/number-plate.svg").default} alt="pet-club" />
              </div>
              <a href="#" onClick={this.hideModal} className="modal-close link-button -with-left-icon">
                <svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="#a12971" strokeWidth="2"><path d="m6 6 12 12"></path><path d="m6 18 12-12"></path></g></svg>
                <span>
                  <TranslationText text='add_car_plate_popup_page_close_btn' />
                </span>
              </a>
            </div>
            <div className="modal-content">
              <h2>
                <Trans i18nKey='add_car_plate_popup_page_title'>
                  Add new <b>car plate number</b>
                </Trans>
              </h2>
              <div className="form-group">
                <div className="form-row">
                  <div className={errors['numberPlate'] !== undefined && errors['numberPlate'].length > 0 ? "form-field  -has-error" : "form-field"}>

                    <label htmlFor="car-plate-number" className="form-field__label">
                      <TranslationText text='add_car_plate_popup_page_plate_number_field_title' />
                    </label>
                    <InputText
                      onChange={this.onChange}
                      prop="numberPlate"
                      className="car-plate-number form-field__input"
                      value={newPlate.numberPlate}
                      type="text"
                      customProp={true}
                    />
                  </div>
                  <div className="sublabel error-msg show">
                    <span>
                      <TranslationText text={errors.numberPlate} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-button full-width">
                <a href="#" onClick={this.addCarPlate} className="modal-close button -with-right-icon">
                  <span>
                    <TranslationText text='add_car_plate_popup_save_btn' />
                  </span>
                  <svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 1)"><path d="m14.5 20h-7c-1.7 0-3.1-1.4-3.1-3.1v-7.9h13.3v7.8c0 1.8-1.4 3.2-3.2 3.2z" fill="none" stroke="#f2f6f7" strokeWidth="2"></path><path d="m7.1 9v-3.1c0-2.1 1.7-3.9 3.9-3.9 2.2 0 3.9 1.8 3.9 3.9v3.1" fill="none" stroke="#f2f6f7" strokeWidth="2"></path><path clipRule="evenodd" d="m11 16.5c-.2 0-.5-.2-.5-.4l-.6-2.1c-.1-.6.3-1.3.9-1.4h.2c.6 0 1.2.5 1.2 1.2v.2l-.6 2.1c-.1.2-.4.4-.6.4z" fill="#fff" fillRule="evenodd"></path></g></svg>                    </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  }
}
export default withTranslation()(Modal);