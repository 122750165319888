import React, { Component } from "react";
import "regenerator-runtime/runtime";
import fetcherApi from '../components/FetcherApi.jsx';
import Modal from "../components/Modal.jsx";
import fuzzySearch from '../components/fuzzySearch.jsx';
import SelectSearch from 'react-select-search';
import Spinner from '../profile/Information/Spinner.jsx';
import Footer from "../../components/components/Footer.jsx";
import TranslationText from "../components/Translation/TranslationText.jsx";
import i18n from "translations/i18n.jsx";
import { withTranslation } from 'react-i18next';
import AutoHideableTitle from "../components/HeaderComponents/AutoHideableTitle.jsx";
import urlTools from "../tools/urlTools.jsx";

class ECommerce extends Component {
  _isMounted = false;
  state = {
    options: this.props.initialData.ecommerceStores.map(data => ({ name: data.name, value: data.id, nameWithEnglishLettersOnly: data.nameWithEnglishLettersOnly })),
    errors: {},
    ecommerce: null,
    dirtyfields: {},
    maxCarPlatesAllowed: {},
    showModal: false
  }

  showModal = () => {
    this.setState({ showModal: true })
  }

  hideModal = () => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showModal: false })
  }

  async componentDidMount() {
    this._isMounted = true;
    const response = (await fetcherApi.get(urlTools.constructRoute("api/profile/Ecommerce/ecommerce"))).response.data;
    if (this._isMounted) {
      this.setState({
        ecommerce: response.resultObject,
        maxCarPlatesAllowed: response.resultObject.maxCarPlates
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState = (state, callback) => {
      return;
    }
  }

  onChange = e => {
    if (e.prop === 'numberPlate' && e.customProp === true) {
      const { ecommerce } = this.state
      ecommerce.carNumberPlates[ecommerce.carNumberPlates.length - 1].numberPlate = e.value;
      this.setState({ ecommerce });
    }
  };

  onDropDownChange = async (event, values) => {
    await fetcherApi.post(urlTools.constructRoute("api/profile/Ecommerce/favouriteCCStore/" + values.value));

    const { ecommerce } = this.state;
    ecommerce.favouriteEcomStore = parseInt(values.value);
    this.setState({ ecommerce });
  };

  hasErrors = () => {
    const { errors } = this.state;

    for (var error in errors) {
      if (errors[error] && errors[error].length) {
        return true;
      }
    }
    return false;
  };

  onRemoveCarPlate = async (e, plateId) => {

    event.preventDefault();
    event.stopPropagation();

    const { ecommerce } = this.state;
    await fetcherApi.delete(urlTools.constructRoute("api/profile/Ecommerce/carPlates/" + plateId));
    ecommerce.carNumberPlates = ecommerce.carNumberPlates.filter(function (plate) {
      return plate.id !== plateId
    });
    if (plateId > 0) {
      ecommerce.deletedPlatesIds.push(plateId);
    }
    this.setState({ ecommerce });
  }

  addCarPlate = async (e, newNumberPlate) => {
    const { ecommerce } = this.state;

    const response = (await fetcherApi.put(urlTools.constructRoute("api/profile/Ecommerce/carPlates/"), {
      NumberPlate: newNumberPlate
    })).response.data;
    if (response.success) {
      var joined = ecommerce.carNumberPlates.concat(
        { id: response.resultObject, numberPlate: newNumberPlate },
      );
      ecommerce.carNumberPlates = joined;

      this.setState({ ecommerce, showModal: false });
    } else {
      //error comes from api, currently it will be always unexpected
      e.setState({ errors: { numberPlate: response.error } });
    }
  };

  onRemoveCard = async (e, cardId, rowNumber) => {
    event.preventDefault();
    event.stopPropagation();
    const { ecommerce } = this.state;
    await fetcherApi.delete(urlTools.constructRoute("api/profile/Ecommerce/paymentCard/" + cardId));
    ecommerce.paymentCards = ecommerce.paymentCards.filter(function (card) {
      return card.id !== cardId
    });
    ecommerce.deletedPaymentCardsIds.push(parseInt(cardId));
    this.setState({ ecommerce });
  }

  onSetAsDefault = async (e, cardId, rowNumber) => {

    event.preventDefault();
    event.stopPropagation();
    await fetcherApi.post(urlTools.constructRoute("api/profile/Ecommerce/paymentCard/" + cardId));
    const { ecommerce } = this.state;
    ecommerce.paymentCards.forEach(function (item) {
      item.isDefault = false;
    });
    ecommerce.paymentCards[rowNumber].isDefault = true;
    this.setState({
      ecommerce
    });
  }

  render() {
    const { ecommerce, options, maxCarPlatesAllowed,
    } = this.state;

    const platesGrid = [];
    const paymentCardsGrid = [];

    if (ecommerce !== null) {
      for (var i = 0; i < ecommerce.carNumberPlates.length; i += 1) {
        platesGrid.push(<CarPlatesComponent key={i} carPlate={ecommerce.carNumberPlates[i]} onChange={this.onChange} removeCarPlate={this.onRemoveCarPlate} />);
      };

      for (var i = 0; i < ecommerce.paymentCards.length; i += 1) {
        paymentCardsGrid.push(<PaymentCardComponent key={i} rowNumber={i} card={ecommerce.paymentCards[i]} removeCard={this.onRemoveCard} setAsDefault={this.onSetAsDefault} />);
      };
    }

    return ecommerce !== null ? (
      <React.Fragment>
        <div>
          <div>
            {(this.state.showModal) ? <Modal onSave={this.addCarPlate} hideModal={this.hideModal.bind(this)} /> : null}
          </div>

          <div className="container page-container ecommerce-page">
            <AutoHideableTitle text={'ecommerce_page_title'} />
            <div className="card card-full-width">
              <div className="card-content">
                <div className="page-description-row">
                  <p>
                    <TranslationText text='ecommerce_page_info_title' />
                  </p>
                  <p>
                    <TranslationText text='ecommerce_page_info' />
                  </p>
                </div>
                <hr />
                <div className="row">

                  <ParentPlatesComponent canAddPlates={ecommerce.carNumberPlates.length === maxCarPlatesAllowed} onAddNew={this.showModal} >
                    {platesGrid}
                  </ParentPlatesComponent>

                  <ParentPaymentCardsComponent setAsDefault={this.onSetAsDefault} removeCard={this.onRemoveCard} >
                    {paymentCardsGrid}
                  </ParentPaymentCardsComponent>

                  <div className="column-desktop-4 column-tablet-12">
                    <div className="full-width">
                      <h2>
                        <TranslationText text='ecommerce_page_pickup_store_title' />
                      </h2>
                      <div className="form-field searchable-list">
                        <div id="react-select-search-root">
                          <div className="select-search">
                            <div className="select-search__value">
                              <SelectSearch value={ecommerce.favouriteEcomStore} onChange={this.onDropDownChange} options={options} search filterOptions={fuzzySearch} name="search" placeholder={i18n.t('ecommerce_page_empty_drop_down_text')} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row profile-footer">
                  <div className="column-medium-9 column-desktop-8 column-tablet-12">
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
        <Footer countrySelectionEnabled={false} legalEntity={this.props.initialData.legalEntityId} />
      </React.Fragment>

    ) : <Spinner />
  }
}

export default withTranslation()(ECommerce);

const ParentPlatesComponent = props => (
  <div className="column-desktop-4 column-tablet-12">
    <div className="full-width">
      <h2>
        <TranslationText text='ecommerce_page_plates_title' />
      </h2>
      {props.children}
      <button onClick={props.onAddNew} data-target="#add-new-car" disabled={props.canAddPlates} className="open-modal button -secondary full-width -with-right-icon">
        <TranslationText text='ecommerce_page_add_car_number_btn' />
        <svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="#a12971" strokeWidth="2"><path d="m5 12h14" /><path d="m12 19v-14" /></g></svg>
      </button>
    </div>
  </div>
);

const ParentPaymentCardsComponent = props => (
  <div className="column-desktop-4 column-tablet-12">
    <div className="full-width">
      <h2>
        <TranslationText text='ecommerce_page_default_payment_method' />
      </h2>
      {props.children}
    </div>
  </div>
);

const CarPlatesComponent = props =>

  <div className="ecommerce-item">
    <div>{props.carPlate.numberPlate}</div>
    <a onClick={(e) => props.removeCarPlate(e, props.carPlate.id)} className="dispose" href="#">
      <svg height="24" viewBox="0 0 20 24" width="20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="#a4afb3" strokeWidth="2" transform="translate(.327869 1)"><path d="m17.2173913 2.86956522-.7747826 15.49565218v.0000001c-.1020274 2.0380273-1.7855057 3.6373365-3.826087 3.6347856h-6.10739127-.00000016c-2.04058131.0025491-3.72405957-1.5967582-3.82608696-3.6347856l-.77-15.49565228" /><path d="m0 2.869565h19.130435" /><path d="m6.934783 6.695652.47826 10.521739" /><path d="m12.195652 6.695652-.478261 10.521739" /><path d="m5.73913043 2.86956539.52130435-1.55913061-.00000005.00000017c.259314-.78131565.98938782-1.30912435 1.81260869-1.31043478h2.97956518.0000001c.8232209.00131066 1.5532956.52911913 1.8126087 1.31043478l.5260869 1.55913044" /></g></svg>                            </a>

  </div>;

const PaymentCardComponent = props =>

  <div className="ecommerce-item">
    <div>
      <div className="payment-card">
        <div className={props.card.typePicture}></div>
        <div className="payment-card-title">{props.card.description}</div>
      </div>
      {!props.card.isDefault ? (
        <a href="#" onClick={(e) => props.setAsDefault(e, props.card.id, props.rowNumber)} >
          <TranslationText text='ecommerce_page_set_as_default_btn' />
        </a>
      ) : <div className="sublabel">
        <TranslationText text='ecommerce_page_default_payment_method' />
      </div>
      }
    </div>
    <a onClick={(e) => props.removeCard(e, props.card.id, props.rowNumber)} className="dispose" href="#">
      <svg height="24" viewBox="0 0 20 24" width="20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="#a4afb3" strokeWidth="2" transform="translate(.327869 1)"><path d="m17.2173913 2.86956522-.7747826 15.49565218v.0000001c-.1020274 2.0380273-1.7855057 3.6373365-3.826087 3.6347856h-6.10739127-.00000016c-2.04058131.0025491-3.72405957-1.5967582-3.82608696-3.6347856l-.77-15.49565228" /><path d="m0 2.869565h19.130435" /><path d="m6.934783 6.695652.47826 10.521739" /><path d="m12.195652 6.695652-.478261 10.521739" /><path d="m5.73913043 2.86956539.52130435-1.55913061-.00000005.00000017c.259314-.78131565.98938782-1.30912435 1.81260869-1.31043478h2.97956518.0000001c.8232209.00131066 1.5532956.52911913 1.8126087 1.31043478l.5260869 1.55913044" /></g></svg>                            </a>
  </div>