import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer.jsx"
import TranslationText from "../../components/Translation/TranslationText.jsx";
import RimiLogoHeaderButton from "../../../components/components/HeaderComponents/RimiLogoHeaderButton.jsx";
import AutoHideableHeader from "../../../components/components/HeaderComponents/AutoHideableHeader.jsx";

class AccountFound extends Component {

  state = {
    pageTitleText: "account_found_page_title",
    pageInfoText: "account_found_page_info",
    buttonText: "account_found_page_login_btn"
  };

  componentDidMount() {
    const locationState = this.props.location.state;
    if (locationState?.fromProfile) {
      this.setState({
        pageTitleText: "card_replaced_page_title",
        pageInfoText: "card_replaced_page_info",
        buttonText: "card_replacement_page_return_btn"
      });
    }
  }

  handleClick = _ => {
    this.props.hideNavBar(false);
  }

  render() {

    return (
      <React.Fragment>
        <div className="container">
          <div className="card">
            <AutoHideableHeader>
              <RimiLogoHeaderButton onClick={this.handleClick.bind(this)} to="/profileInformation" />
            </AutoHideableHeader>
            <div className="card-content">
              <div className="single-message">
                <h1>
                  <TranslationText text={this.state.pageTitleText} />
                </h1>
                <p>
                  <TranslationText text={this.state.pageInfoText} />
                </p>
                <div className="form-button full-width">
                  <Link className="button" onClick={this.handleClick.bind(this)} to="/profileInformation">
                    <TranslationText text={this.state.buttonText} />
                  </Link>
                </div>
              </div>
            </div>

            <footer className="card-footer no-border"></footer>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>
    );
  }
}

export default AccountFound;


