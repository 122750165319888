import { Redirect } from "react-router-dom/cjs/react-router-dom.js";
import * as constants from "../constants/constants.jsx";

export default {
  getBaseUrl() {
    return "/";
  },
  constructRoute(path) {
    const baseUrl = this.getBaseUrl();
    const sanitizedPath = getSanitizedPath(path);

    return baseUrl + sanitizedPath;
  },
  getQueryParamValue(queryString, paramKey) {
    const decodedQueryString = decodeURIComponent(queryString);

    return new URLSearchParams(decodedQueryString).get(paramKey);
  },
  getReturnUrlQueryParamValue(queryString, paramKey) {
    const decodedQueryString = decodeURIComponent(queryString);
    const returnUrl = new URLSearchParams(decodedQueryString).get(constants.RETURN_URL_KEY);

    const urlParts = returnUrl?.split("?");
    if (urlParts?.length !== 2) {
      return null;
    }

    const innerQueryString = "?" + urlParts[1];
    return this.getQueryParamValue(innerQueryString, paramKey);
  },
  getDefaultRedirect(initialData) {
    let redirectUrl = '';
    switch (true) {
      case initialData.isConsentsFlow:
        redirectUrl = initialData.businessLogin ? "/ConsentsAcceptanceB2b" : "/ConsentsAcceptance";
        break;
      case initialData.isUserLoggedIn:
        redirectUrl = "/profileInformation";
        break;
      default:
        redirectUrl = "/login";
        break;
    }

    return <Redirect to={redirectUrl + "?" + constants.RETURN_URL_KEY + "=" + window.location.pathname} />
  }
}

const getSanitizedPath = (path) => path.replace(/^\/+/, ""); // This regex matches all forward slashes at the beginning of the string.