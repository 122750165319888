import * as constants from "../constants/constants.jsx";
import urlTools from "../tools/urlTools.jsx";

const AppContextService = {
  isMobileAppOrigin: queryString => {
      const clientId = urlTools.getQueryParamValue(queryString, constants.CLIENT_ID_KEY);
      const mobApp = urlTools.getQueryParamValue(queryString, constants.MOB_APP_KEY)
          ?? urlTools.getReturnUrlQueryParamValue(queryString, constants.MOB_APP_KEY);

      const isMobileAppOrigin = clientId === constants.CLIENT_ID_MOBILE
          || mobApp?.toLowerCase() === constants.TRUE;

      return isMobileAppOrigin;
  }
};

export default AppContextService;