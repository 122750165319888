import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Footer from "../../components/Footer.jsx";
import InputText from "../../components/InputText.jsx";
import fetcherApi from '../../components/FetcherApi.jsx';
import LegalEntityFunctions from "../../components/LegalEntityFunctions.jsx";
import ConsentLinks from "../../components/Consents/ConsentLinks.jsx";
import * as constants from "../../constants/constants.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import { Trans, withTranslation } from "react-i18next";
import RimiLogoHeaderButton from "../../components/HeaderComponents/RimiLogoHeaderButton.jsx";
import AutoHideableHeader from "../../components/HeaderComponents/AutoHideableHeader.jsx";
import StepBackHeader from "../../components/HeaderComponents/StepBackHeader.jsx";
import validator from "../../components/FieldValidator.jsx";
import { DateInput } from '../../components/DateInput.jsx';
import urlTools from "../../tools/urlTools.jsx";

class EnterEmail extends Component {

  state = {
    confirmed: false,
    error: false,
    email: "",
    errorText: "",
    confirmed: false
  };

  onChange = e => {
    e.value = validator.blockSpaceSymbol(e.value);
    this.setState({ email: e.value, errors: {}, error: false, errorText: '' });
  };

  confirm = async (e) => {
    e.preventDefault();
    if (this.state.email === null || this.state.email === undefined || this.state.email === "") {
      this.setState({
        error: true,
        errorText: "empty_email_error_message"
      });
      return;
    }

    if (this.isBirthdateValidated() && (this.state.birthDate === null || this.state.birthDate === undefined || this.state.birthDate === "")) {
      this.setState({
        error: true,
        errorText: "enter_email_invalid_birth_date_error"
      });
      return;
    }

    const result = (await fetcherApi.post(this.getApiCall(), this.getApiCallPayload())).response;

    if (result.data.success) {
      this.setState({ confirmed: true })
    } else if (result.data.error === "email_incorrect_format") {
      this.setState(
        { error: true, errorText: "account_found_but_no_email_page_in_login_empty_email_field_error" });
    } else if (result.data.error === "duplicate_email") {
      this.setState({ error: true, errorText: "account_found_but_no_email_page_in_login_duplicate_email_error" });
    } else if (result.data.error === "email_placeholer") {
      this.setState({ error: true, errorText: "account_found_but_no_email_page_incorrect_email_error" });
    } else if (result.data.error === "enter_email_invalid_birth_date_error") {
      this.setState({ error: true, errorText: "enter_email_invalid_birth_date_error" });
    } else {
      this.setState({ error: true, errorText: "missing_email_rate_limit_error" });
    }
  };

  isBirthdateValidated = () => !this.props.location.state.isBusiness && !this.props.location.state.fromVerificationCode;

  getApiCall = () => {
    return this.isBirthdateValidated()
      ? urlTools.constructRoute("api/Registration/" + LegalEntityFunctions.getLegalEntityId(localStorage.getItem(constants.SELECTED_COUNTRY)) + "/SendPwdResetVerificationLinkBirthDateValidated")
      : urlTools.constructRoute("api/Registration/" + LegalEntityFunctions.getLegalEntityId(localStorage.getItem(constants.SELECTED_COUNTRY)) + "/SendPwdResetVerificationLink");
  }

  getResendApiCall = () => urlTools.constructRoute("api/Registration/" + LegalEntityFunctions.getLegalEntityId(localStorage.getItem(constants.SELECTED_COUNTRY)) + "/resendPwdResetVerificationLink/" + this.state.email);

  getApiCallPayload = () => ({
    email: this.state.email,
    card: this.props.location.state.cardPrefix + this.props.location.state.cardNumber,
    birthDate: this.isBirthdateValidated() ? this.state.birthDate : undefined
  })

  getBackButtonLink() {
    if (this.props.location.state.fromVerificationCode !== undefined && this.props.location.state.fromVerificationCode !== null
      && this.props.location.state.fromVerificationCode) {
      return '/passwordrecovery/verificationcode';
    }
    else if (this.props.location.state.remindEmailFlow) {
      return '/passwordrecovery/RemindEmail/';
    }
    else {
      return '/registration/HasAccount/EnterExistingCard';
    }
  }

  onBirthDateChange = value => {
    this.setState({ birthDate: value, errors: {}, error: false, errorText: '' });
  };

  render() {

    return (this.state.confirmed
      ? <Redirect to={{ pathname: '/VerificationEmailSent', state: { apiCall: this.getResendApiCall() } }} />
      : (<React.Fragment>
        <div className="container">
          <div className="card">
            <AutoHideableHeader>
              <RimiLogoHeaderButton initialData={this.props.initialData} />
              <StepBackHeader
                pathName={this.getBackButtonLink()}
                buttonText={'step_back_button_label'} />
            </AutoHideableHeader>
            <div className={this.state.error ? ("card-content") : ("card-content no-padding-bottom")}>
              <h1>
                <Trans i18nKey='account_found_but_no_email_page_title'>
                  We didn't find any <b>e-mail associated with this account</b>
                </Trans>
              </h1>
              <p><TranslationText text={'account_found_but_no_email_page_info'} /></p>

              <div className="form-group">
                <div className="form-row">
                  <div className={!this.state.error ? "form-field" : "form-field -has-error"}>

                    <label htmlFor="email" className="form-field__label"><TranslationText text='email_field_label' /></label>
                    <InputText
                      onChange={this.onChange}
                      prop="email"
                      value={this.state.email}
                      className="form-field__input"
                      placeholder="example@gmail.com"
                    />
                  </div>
                </div>
                {
                  !this.props.location.state.isBusiness && !this.props.location.state.fromVerificationCode ?
                    <div className="form-row">
                      <div className={!this.state.error ? "form-field" : "form-field -has-error"}>
                        <label htmlFor="birthDate" className="form-field__label">
                          <TranslationText text='enter_email_birth_date_input_label' />
                        </label>
                        <DateInput
                          initialDateString={this.state.birthDate}
                          onChange={this.onBirthDateChange}
                          errorMessage={this.state.errorText}
                        />
                      </div>
                    </div>
                    : (
                      <div className="form-row">
                        <div className="sublabel error-msg show">
                          <span>
                            <TranslationText text={this.state.errorText} />
                          </span>
                        </div>
                      </div>
                    )
                }
              </div>
              <div className="form-button full-width">
                <Link className="button" onClick={this.confirm.bind(this)} to='/#'><TranslationText text='send_verification_email_button_label' />
                </Link>
              </div>
              <hr className="spacer" />


            </div>
            <footer className="card-footer no-border">
              <div className="footer-text">
                <ConsentLinks />
              </div>
            </footer>

          </div>
        </div>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>)
    );
  }
}

export default withTranslation()(EnterEmail);


