import Tippy from "@tippyjs/react";
import FetcherApi from "components/components/FetcherApi";
import FieldValidator from "components/components/FieldValidator";
import Footer from "components/components/Footer";
import AutoHideableTitle from "components/components/HeaderComponents/AutoHideableTitle";
import InputText from "components/components/InputText";
import NotificationSuccess from "components/components/Notifications/NotificationSuccess";
import { PasswordRuleChecklistOverlay } from "components/components/PasswordRuleChecklist/PasswordRuleChecklistOverlay";
import TranslationText from "components/components/Translation/TranslationText";
import { WRONG_PASSWORD } from "components/constants/errorCodes";
import { PASSWORD_CHANGED } from "components/constants/notificationTypes";
import { EyeSvg } from "components/icons/EyeSvg";
import { QuestionMarkCircled } from "components/icons/QuestionMarkCircledSvg";
import { useRef, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

export function PasswordChange(props) {
	const [form, setForm] = useState({});
	const [errors, setErrors] = useState({});
	const [formMasks, setFormMasks] = useState({
		currentPassword: true,
		newPassword: true,
		repeatPassword: true
	});

	const notificationPopupRef = useRef({});

	const onMaskChange = key => {
		setFormMasks({ ...formMasks, [key]: !formMasks[key] });
	}

	const onChange = event => {
		setForm({ ...form, [event.prop]: event.value });
		setErrors({ ...errors, [event.prop]: undefined });
	}

	const onSave = async () => {
		notificationPopupRef.current.closeClicked();
		setErrors({});
		const isFormValid = validateForm();
		if (!isFormValid) {
			return;
		}

		const httpResponse = await FetcherApi.put("/api/Password", form);

		handleSaveResponse(httpResponse);
	}

	const validateForm = () => {
		const isCurrentPasswordValid = !!form.currentPassword;
		if (!isCurrentPasswordValid) {
			errors.currentPassword = "password_change_page_current_password_tooltip";
		}

		const isNewPasswordValid = FieldValidator.passwordSatisfiesRequirements(form.newPassword);
		if (!isNewPasswordValid) {
			errors.newPassword = form.newPassword
				? "add_your_data_page_pwd_rules_error"
				: "password_change_page_new_password_required_error";
		}

		const isRepeatPasswordValid = form.newPassword === form.repeatPassword;
		if (!isRepeatPasswordValid) {
			errors.repeatPassword = "profile_information_page_pwd_not_match_error";
		}

		const isFormValid = isCurrentPasswordValid && isNewPasswordValid && isRepeatPasswordValid;
		if (!isFormValid) {
			setErrors({ ...errors });
		}

		return isFormValid;
	}

	const handleSaveResponse = httpResponse => {
		const result = httpResponse.response.data;
		if (result.success) {
			setForm({});
			notificationPopupRef.current.showPopup(PASSWORD_CHANGED);
		} else if (result.error === WRONG_PASSWORD) {
			setErrors({ ...errors, currentPassword: "password_change_page_current_password_incorrect_error" });
		} else {
			setErrors({
				...errors,
				newPassword: result.error === "password_already_used_previously" ? result.error : "unexpected_error"
			})
		}
	}

	return (
		<>
			<div className="container page-container">
				<Link className="link-button -with-left-icon" to="/profileInformation">
					<svg
						enableBackground="new 0 0 24 24"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="m15 6-6 6 6 6"
							fill="none"
							stroke="#a12971"
							strokeWidth="2"
						/>
					</svg>
					<TranslationText text="back_to_profile_btn" />
				</Link>
				<AutoHideableTitle text="password_change_page_title" />
				<div className="card card-full-width">
					<div className="card-content">
						<div className="row row-center">
							<div className="column-desktop-4 column-tablet-12 column-mobile-12 form-group">
								<div className="form-row">
									<div className={`form-field ${errors.currentPassword ? "-has-error" : ""}`}>
										<label className="form-field__label">
											<TranslationText text="password_change_page_current_password_label" />
											<Tippy content={<span>
												<TranslationText text="password_change_page_current_password_tooltip" />
											</span>}>
												<span className="help-tip">
													<QuestionMarkCircled />
												</span>
											</Tippy>
										</label>
										<div className="form-field-container">
											<InputText
												id="current-password"
												prop="currentPassword"
												value={form.currentPassword}
												onChange={onChange}
												type={formMasks.currentPassword ? "password" : "text"}
											/>
											<span
												tabIndex="-1"
												onClick={_ => onMaskChange("currentPassword")}
												className={`form-field__card-icon togglePassword -pointer ${!formMasks.currentPassword ? "show" : ""}`}>
												<EyeSvg />
											</span>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.currentPassword} />
											</span>
										</div>
									</div>
									<div className={`form-field ${errors.newPassword ? "-has-error" : ""}`}>
										<label className="form-field__label">
											<TranslationText text="password_change_page_new_password_label" />
										</label>
										<PasswordRuleChecklistOverlay password={form.newPassword}>
											<div className="form-field-container">
												<InputText
													id="new-password"
													onChange={onChange}
													prop="newPassword"
													value={form.newPassword}
													type={formMasks.newPassword ? "password" : "text"}
												/>
												<span
													tabIndex="-1"
													onClick={_ => onMaskChange("newPassword")}
													className={`form-field__card-icon togglePassword -pointer ${!formMasks.newPassword ? "show" : ""}`}>
													<EyeSvg />
												</span>
											</div>
										</PasswordRuleChecklistOverlay>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.newPassword} />
											</span>
										</div>
									</div>
									<div className={`form-field ${errors.repeatPassword ? "-has-error" : ""}`}>
										<label className="form-field__label">
											<TranslationText text="password_change_page_repeat_password_label" />
										</label>
										<div className="form-field-container">
											<InputText
												id="repeat-password"
												onChange={onChange}
												prop="repeatPassword"
												value={form.repeatPassword}
												type={formMasks.repeatPassword ? "password" : "text"}
											/>
											<span
												tabIndex="-1"
												onClick={_ => onMaskChange("repeatPassword")}
												className={`form-field__card-icon togglePassword -pointer ${!formMasks.repeatPassword ? "show" : ""}`}>
												<EyeSvg />
											</span>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.repeatPassword} />
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr />
						<div className="row button-row">
							<div className="column-medium-3 column-desktop-4 column-tablet-12">
								<button
									onClick={_ => onSave()}
									className="button full-width"
								>
									<TranslationText text='password_change_page_save_button' />
								</button>
								<NotificationSuccess ref={notificationPopupRef}></NotificationSuccess>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer countrySelectionEnabled={false} legalEntity={props.initialData.legalEntityId} />
		</>
	);
}