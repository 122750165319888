import React, { Component } from "react";
import InputText from "../components/InputText.jsx";
import "regenerator-runtime/runtime";
import fetcherApi from '../components/FetcherApi.jsx';
import Spinner from '../profile/Information/Spinner.jsx';
import SuccessPopup from "../components/Success.jsx";
import TranslationComponent from "../components/TranslationComponent.jsx";
import Footer from "../../components/components/Footer.jsx";
import * as constants from "../constants/constants.jsx";
import TranslationText from "../components/Translation/TranslationText.jsx";
import ConfirmationModalDialog from "../components/ConfirmationModalDialog.jsx";
import AutoHideableTitle from "../components/HeaderComponents/AutoHideableTitle.jsx";
import LegalEntityFunctions from "../components/LegalEntityFunctions.jsx";
import { CardTypeCodes } from "./CardTypeCodes.jsx";
import urlTools from "../tools/urlTools.jsx";
import * as errorCodes from "../constants/errorCodes.jsx";

class MyRimiGroup extends Component {

  state = {
    newMember: {},
    houseHolds: null,
    errors: [],
    dirtyfields: {},
    showSuccess: false,
    cardtypeId: "",
    houseHoldCount: 0,
    loggedInMemberStatus: "",
    hasLoggedInUserMultipleInvites: false,
    showPopup: "",
    showConfirmPopup: "",
    showRemovalConfirmPopup: false,
    memberId: null,
    memberStatus: ""
  };

  async componentDidMount() {
    let legalEntity = LegalEntityFunctions.getCountryName(this.props.initialData.legalEntityId);
    let country = localStorage.getItem(constants.SELECTED_COUNTRY);

    this.setState({
      newMember: {
        cardPrefix: "9440385200" +
          this.props.initialData.cardPrefixes
            .find(prefix => prefix.key === (country ? country : legalEntity)).value,
        cardNumber: ""
      }
    });

    const data = (await fetcherApi.get(urlTools.constructRoute("api/profile/MyRimiGroup/household"))).response.data;
    this.setState({
      houseHolds: data.resultObject.houseHolds.houseHoldMembers,
      loggedInMemberStatus: data.resultObject.houseHolds.loggedInMemberStatus,
      cardtypeId: data.resultObject.ownercardTypeId,
      houseHoldCount: data.resultObject.houseHolds.houseHoldCount,
      hasLoggedInUserMultipleInvites: data.resultObject.houseHolds.hasLoggedInUserMultipleInvites
    })
  }

  onChange = e => {
    const { newMember } = this.state;
    newMember[e.prop] = e.value.replace(/[^0-9]/g, '');
    this.setState({ newMember, errors: [] });
  };

  hasErrors = () => {
    const { errors } = this.state;

    for (var error in errors) {
      if (errors[error] && errors[error].length) {
        return true;
      }
    }
    return false;
  };

  translateAddHouseholdMemberError(errorMessage) {
    console.log(errorMessage)
    switch (errorMessage) {
      case "badCardNumber":
        return ["my_rimi_group_page_incorrect_card_error"];
      case "hhrbothinhh":
        return ["my_rimi_group_page_incorrect_card_error"];
      case "hhrrequestorin":
        return ["my_rimi_group_page_cant_create_error"];
      case "invalidAccountInactiveCode":
        return ["my_rimi_group_page_incorrect_card_error"];
      case "allreadyAdded":
        return ["my_rimi_group_page_incorrect_card_error"];
      case "selfAdd":
        return ["my_rimi_group_page_incorrect_card_error"];
      case "cantInviteEmployee":
        return ["my_rimi_group_page_employee_cant_be_invited"];
      case "cardIsAllreadyInOtherHouseHold":
        return ["my_rimi_group_page_incorrect_card_error"];
      case errorCodes.RATE_LIMIT_ERROR:
        return ["household_add_member_rate_limit_reached"];
    }

    return "unexpected_error";
  }

  addMember = async e => {
    e.preventDefault();
    const { newMember, errors } = this.state;

    let filteredOwner = this.state.houseHolds.filter(function (household) {
      return household.card.fullCardNumber === newMember.cardPrefix + newMember.cardNumber
        && household.acceptRejectButton === true;
    }).length > 0;

    errors['onAdd'] = [];
    errors['cardPrefix'] = [];
    errors['cardNumber'] = [];

    if (newMember.cardPrefix.length < 11) {
      errors['cardPrefix'] = ["card_replacement_page_incorrect_card_number_error"];
    }
    else if (newMember.cardNumber.length < 8) {

      errors['cardNumber'] = ["card_replacement_page_incorrect_card_number_error"];
    }
    else if (this.state.loggedInMemberStatus === 'pending' && !filteredOwner) {
      errors['onAdd'] = ["my_rimi_group_page_cant_create_error"];
    }
    else if (this.state.cardtypeId === 1 && this.state.houseHoldCount === 1) {
      errors['onAdd'] = ["my_rimi_group_page_employee_error"];
    }
    else if (this.state.houseHoldCount === 4) {
      errors['onAdd'] = ["my_rimi_group_page_max_members_reached"];
    }
    else {
      const result = (await fetcherApi.put(urlTools.constructRoute("api/profile/MyRimiGroup/household/" + this.state.cardtypeId), {
        card: {
          cardPrefix: newMember.cardPrefix,
          cardNumber: newMember.cardNumber,
        }
      })).response;

      if (!result.data.success) {
        errors['onAdd'] = this.translateAddHouseholdMemberError(result.data.error);
      }
      else {
        const houseHolds = result.data.resultObject;
        this.setState({
          houseHolds: houseHolds.houseHoldMembers,
          showSuccess: !filteredOwner,
          houseHoldCount: houseHolds.houseHoldCount,
          loggedInMemberStatus: houseHolds.loggedInMemberStatus,
        });
      }
    }

    this.setState({
      errors,
      newMember: {
        cardPrefix: "9440385200" +
          this.props.initialData.cardPrefixes
            .find(prefix => prefix.key === localStorage.getItem(constants.SELECTED_COUNTRY)).value,
        cardNumber: ""
      }
    });
  };

  onRemoveMember = async (e) => {
    const id = parseInt(e);

    if (this.state.memberStatus === 'owner') {
      const { errors } = this.state;
      errors['onRemove'] = ["my_rimi_group_page_remove_owner_error"];
      this.setState({ errors });
    }
    else {
      const result = (await fetcherApi.delete(urlTools.constructRoute("api/profile/MyRimiGroup/household/" + id))).response;

      if (result.data.success) {
        this.setState({
          houseHolds: result.data.resultObject.houseHoldMembers,
          houseHoldCount: result.data.resultObject.houseHoldCount,
          loggedInMemberStatus: result.data.resultObject.loggedInMemberStatus,
          showRemovalConfirmPopup: false
        });
      }
      else {
        console.log(result.data);
      }
    }
  }

  memberRemovalConfirmation = async (household) => {
    this.setState({ memberId: household.id, memberStatus: household.status });
    this.showRemovalConfirmPopup(event);
  }

  confirmRejection = async (e) => {
    const batchOrderNumber = parseInt(e);
    let result = (await fetcherApi.post(urlTools.constructRoute("api/profile/MyRimiGroup/reject/" + batchOrderNumber))).response;

    if (result.data.success) {
      this.setState({
        houseHolds: result.data.resultObject.houseHoldMembers,
        houseHoldCount: result.data.resultObject.houseHoldCount,
        showPopup: "",
        hasLoggedInUserMultipleInvites: result.data.resultObject.hasLoggedInUserMultipleInvites
      });
    }
    else {
      console.log(result.data);
    }
  };

  confirmAcception = async (e) => {
    const batchOrderNumber = parseInt(e);
    const result = (await fetcherApi.post(urlTools.constructRoute("api/profile/MyRimiGroup/accept/" + batchOrderNumber))).response;

    if (result.data.success) {
      this.setState({
        houseHolds: result.data.resultObject.houseHoldMembers,
        houseHoldCount: result.data.resultObject.houseHoldCount,
        loggedInMemberStatus: result.data.resultObject.loggedInMemberStatus,
        showConfirmPopup: "",
        hasLoggedInUserMultipleInvites: result.data.resultObject.hasLoggedInUserMultipleInvites
      });
    }
    else {
      console.log(result.data);
      //errors['onAdd'] = [result.data];
    }
  }

  onReject = async (e) => {
    e.preventDefault();
    this.setState({ showPopup: true });
  }

  onAccept = async (e) => {
    e.preventDefault();
    this.setState({ showConfirmPopup: true });
  }

  hideSuccess = () => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showSuccess: false })
  }

  hidePopup = () => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showPopup: false })
  }

  hideConfirmPopup = () => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showConfirmPopup: "" });
  }

  showRemovalConfirmPopup = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showRemovalConfirmPopup: true })
  }

  hideRemovalConfirmPopup = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showRemovalConfirmPopup: false })
  }

  render() {
    const { newMember, houseHolds, errors } = this.state;
    const children = [];
    if (houseHolds !== null) {
      if (houseHolds.length > 0) {
        for (var i = 0; i < houseHolds.length; i += 1) {
          if (houseHolds[i].isVisible) {
            children.push(<ChildComponent
              key={i}
              household={houseHolds[i]}
              showPopup={this.state.showPopup}
              hidePopup={this.hidePopup}
              hideConfirmPopup={this.hideConfirmPopup}
              reject={this.onReject}
              confirm={this.onAccept}
              onRejectConfirm={this.confirmRejection}
              onConfirmAcception={this.confirmAcception}
              showConfirmPopup={this.state.showConfirmPopup}
              showRemovalConfirmPopup={this.showRemovalConfirmPopup}
              removeMember={this.onRemoveMember}
              removePopup={this.state.showRemovalConfirmPopup}
              memberRemovalConfirmation={this.memberRemovalConfirmation}
            />);
          }
        };
      }
    }

    return houseHolds !== null ? (
      <React.Fragment>

        <div>
          {(this.state.showSuccess) ? <SuccessPopup hideSuccess={this.hideSuccess.bind(this)} /> : null}
        </div>
        {this.state.showRemovalConfirmPopup ? (
          <div className="container page-container club-page">
            <ConfirmationModalDialog
              titleText={"remove_hh_member_popup_title"}
              bodyText={"remove_hh_member_popup_info"}
              yesButtonText={"remove_hh_member_popup_yes"}
              noButtonText={"remove_hh_member_popup_no"}
              onYesButtonClick={() => this.onRemoveMember(this.state.memberId)}
              hidePopup={() => this.hideRemovalConfirmPopup(event)}
            />
          </div>
        ) : <div></div>}
        <div className="container page-container">
          <AutoHideableTitle text={'my_rimi_group_page_title'} />
          <div className="card card-full-width">
            <div className="card-content">
              <div className="page-description-row">
                <p>
                  <TranslationText text={
                    this.state.cardtypeId === CardTypeCodes.EMPLOYEE ? 'my_rimi_group_page_info_employee'
                      : 'my_rimi_group_page_info'} />
                </p>
              </div>

              <hr className="no-margin-top" />

              <div className="row group-row loyalty-card">

                <div className="column-desktop-6 column-tablet-6 column-mobile-12">
                  <div className="full-width">
                    <label htmlFor="card_number_last_part" className="form-field__label">
                      <TranslationText text='my_rimi_group_page_card_number_field_title' />
                    </label>
                    <div className="loyalty-card-input">
                      <div className={(errors['cardPrefix'] !== undefined && errors['cardPrefix'].length > 0) ||
                        (errors['onAdd'] !== undefined && errors['onAdd'].length > 0) ? "form-field  -has-error" : "form-field"}>

                        <InputText
                          onChange={this.onChange}
                          prop="cardPrefix"
                          value={newMember.cardPrefix}
                          maxLength="11"
                          className="form-field__input"
                          type="tel"
                        />

                      </div>
                      <div className={errors['cardNumber'] !== undefined && errors['cardNumber'].length > 0 ||
                        (errors['onAdd'] !== undefined && errors['onAdd'].length > 0) ? "form-field card-number-last-part -has-error" : "form-field card-number-last-part"}>

                        <InputText
                          onChange={this.onChange}
                          prop="cardNumber"
                          value={newMember.cardNumber}
                          maxLength="8"
                          className="form-field__input"
                          placeholder="00000000"
                          type="tel"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column-desktop-3 column-tablet-12"></div>
                <div className="column-desktop-3 column-tablet-12">
                  <button
                    type="submit"
                    onClick={this.addMember}
                    className="button full-width"
                  >
                    <TranslationText text='my_rimi_group_page_send_invitation_btn' />
                  </button>

                </div>

                <div className="column-desktop-6 column-tablet-12">
                  <p>
                    <TranslationText text='my_rimi_group_page_invitations_info' />
                  </p>
                </div>

                <div className="form-row">
                  <div className="sublabel error-msg show">
                    <span>
                      <TranslationText text={errors.cardPrefix} />
                      <TranslationText text={errors.cardNumber} />
                      <TranslationText text={errors.onAdd} />
                      <TranslationText text={errors.onRemove} />
                    </span>
                  </div>
                </div>
              </div>

              <hr />
              <ParentComponent>
                {children}
              </ParentComponent>
            </div>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} legalEntity={this.props.initialData.legalEntityId} />
      </React.Fragment>
    ) : <Spinner />
  }
}

export default MyRimiGroup;
const ParentComponent = props => (

  <div className="household-members group-row">
    <h2><TranslationText text='my_rimi_group_page_group_members_title' /></h2>
    <div className="row">

      <div className="column-desktop-12">
        {props.children}
      </div>
    </div>
  </div>
);

const ChildComponent = props => (
  <React.Fragment>
    <div className="row padded-row">
      <div className="column-desktop-3 column-tablet-6 column-mobile-6">
        <div className="form-field">
          <label htmlFor="member1" className="form-field__label">
            <TranslationText text={props.household.displayCardInsteadName
              ? 'my_rimi_group_page_card_field_label'
              : 'my_rimi_group_page_name_field_label'}>
            </TranslationText>
          </label>
          {props.household.displayCardInsteadName ?
            (<p>{props.household.card.fullCardNumber}</p>)
            :
            (<p>{props.household.name}</p>)
          }
        </div>
      </div>
      <div className="column-desktop-3 column-tablet-6 column-mobile-6">
        <div className="form-field">
          <label htmlFor="status_member1" className="form-field__label">
            <TranslationText text='my_rimi_group_page_status_field_title' />
          </label>
          <p className={'status ' + props.household.status}>
            <TranslationText text={TranslationComponent.TranslateHouseHoldStatus(props.household.status)} />
          </p>
        </div>
      </div>
      {props.household.acceptRejectButton ? (
        <React.Fragment>
          {props.showPopup ? (
            <div className="container page-container club-page">
              <ConfirmationModalDialog
                confirmationInputParameter={props.household.batchOrderNumber}
                titleText={"household_reject_popup_are_you_sure"}
                bodyText={"household_reject_popup_info"}
                yesButtonText={"household_reject_popup_page_yes_btn"}
                noButtonText={"household_reject_popup_page_no_btn"}
                onYesButtonClick={props.onRejectConfirm.bind(this)}
                hidePopup={props.hidePopup.bind(this)}
              />
            </div>
          ) : null}
          {props.showConfirmPopup ? (
            <div className="container page-container club-page">
              <ConfirmationModalDialog
                confirmationInputParameter={props.household.batchOrderNumber}
                titleText={"household_accept_popup_are_you_sure"}
                bodyText={"household_accept_popup_info"}
                yesButtonText={"household_accept_popup_page_yes_btn"}
                noButtonText={"household_accept_popup_page_no_btn"}
                onYesButtonClick={props.onConfirmAcception.bind(this)}
                hidePopup={props.hideConfirmPopup.bind(this)}
              />
            </div>
          ) : null}

          <div className="column-desktop-3 column-tablet-12">
            <button
              type="button"
              name={props.household.status}
              onClick={props.reject}
              value={props.household.name}
              className="button -secondary full-width"
            >
              <TranslationText text='my_rimi_group_page_reject_btn' />
            </button>
          </div>
          <div className="column-desktop-3 column-tablet-12">
            <button
              type="button"
              name={props.household.status}
              batchordernumber={props.household.batchOrderNumber}
              onClick={props.confirm}
              value={props.household.name}
              className="button -primary full-width"
            >
              <TranslationText text='my_rimi_group_page_accept_btn' />
            </button>
          </div>
        </React.Fragment>
      ) : (<React.Fragment />)}
      {props.household.canRemove ?
        (
          <React.Fragment>
            <div className="column-desktop-3 column-tablet-12">

            </div>
            <div className="column-desktop-3 column-tablet-12">
              <button
                type="button"
                name={props.household.status}
                id={props.household.id}
                onClick={() => props.memberRemovalConfirmation(props.household)}
                value={props.household.name}
                className="button -secondary full-width"
              >
                <TranslationText text='my_rimi_group_page_remove_btn' />
              </button>
            </div>
          </React.Fragment>

        ) : (<div></div>)}
    </div>
  </React.Fragment>)