export const COUNTRY_CHANGED = "countryChanged"
export const SELECTED_COUNTRY = "selectedCountry"
export const IS_MOBILE_APP_STORAGE_KEY = "isMobileApp"
export const LANGUAGE_CHANGED = "languageChanged"
export const SELECTED_LANGUAGE = "i18nextLng"
export const TRUE = "true"
export const RETAIL = "RETAIL"
export const MOB_APP_KEY = "mobApp";
export const CLIENT_ID_KEY = "client_id";
export const CLIENT_ID_MOBILE = "mobileapp";
export const RETURN_URL_KEY = "ReturnUrl"; // internal URL (/spa/...)
export const REDIRECT_URL_KEY = "RedirectUrl"; // external URL (https://...)
export const OIDC_QUERY_KEY = "OidcQueryUrl";
export const HANDSHAKE_SESSION_STORAGE = "handshake";
export const CARD_NUMBER_PREFIX_START = "9440385200";
export const REGISTRATION_URL_KEY = "registration";
export const COUNTRY_URL_KEY = "country";
export const BUSINESS_URL_KEY = "business";
export const OIDC_QUERY_BEGINNING = "?ReturnUrl=/connect/authorize/callback";
export const GTM_USER_LANGUAGE_KEY = "user-language";
