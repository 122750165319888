import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import RimiLogoHeaderButton from "../../components/HeaderComponents/RimiLogoHeaderButton.jsx";
import AutoHideableHeader from "../../components/HeaderComponents/AutoHideableHeader.jsx";

class AccountFoundLogin extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="container">
					<div className="card">
						<AutoHideableHeader>
							<RimiLogoHeaderButton initialData={this.props.initialData} />
						</AutoHideableHeader>
						<div className="card-content">
							<div className="single-message">
								<h1><TranslationText text='card_replaced_page_title' /></h1>
								<p><TranslationText text='card_replaced_page_info' /></p>
								<div className="form-button full-width">
									<Link className="button" to={{ pathname: '/login' }}><TranslationText text='account_found_page_login_btn' />
									</Link>
								</div>
							</div>
						</div>
						<footer className="card-footer no-border"></footer>
					</div>
				</div>
				<Footer countrySelectionEnabled={false} />
			</React.Fragment>
		);
	}
}

export default AccountFoundLogin;


