export function isUpperCase(character) {
	return character
		&& character === character.toUpperCase()
		&& character !== character.toLowerCase();
}

export function isLowerCase(character) {
	return character
		&& character === character.toLowerCase()
		&& character !== character.toUpperCase();
}

export function containsDigit(text) {
	return /\d/.test(text);
}

export function isSpecialSymbol(character) {
	return !isUpperCase(character) && !isLowerCase(character) && !containsDigit(character);
}