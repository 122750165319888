const ErrorMessagesService = {
  getConvertedVerificationCodeError: (backendErrorCode) => {
    switch (backendErrorCode) {
      case 'activationCodeInvalid':
        return 'card_replacement_activation_code_page_invalid_code_error';
      case 'expiredActivationCode':
        return 'card_replacement_activation_code_page_expired_code_error';
      case 'activationCodeRetryLimit':
        return 'card_replacement_activation_code_page_code_retry_limit_error';
      default:
        return 'card_replacement_activation_code_page_unexpected_error';
    }
  },
};

export default ErrorMessagesService;
