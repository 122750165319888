import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import InputText from "../../components/InputText.jsx";
import fetcherApi from '../../components/FetcherApi.jsx';
import Footer from "../../components/Footer.jsx";
import ConsentLinks from "../../components/Consents/ConsentLinks.jsx";
import * as constants from "../../constants/constants.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import { Trans, withTranslation } from 'react-i18next';
import RimiLogoHeaderButton from "../../components/HeaderComponents/RimiLogoHeaderButton.jsx";
import StepBackHeader from "../../components/HeaderComponents/StepBackHeader.jsx";
import AutoHideableHeader from "../../components/HeaderComponents/AutoHideableHeader.jsx";
import urlTools from "../../tools/urlTools.jsx";

class EnterExistingCard extends Component {
  _isMounted = false;
  state = {
    card: {
      cardPrefix: "",
      cardNumber: ""
    },
    errors: [],
    confirmedEnterEmail: false,
    confirmedEnterData: false,
    confirmedEnterEmailNoAccount: false,
    isBusiness: false,
    isPhysicalCardRegistration: false
  };

  componentDidMount() {
    const { card } = this.state;
    const selectedCountry = localStorage.getItem(constants.SELECTED_COUNTRY);
    card.cardPrefix = constants.CARD_NUMBER_PREFIX_START + this.props.initialData.cardPrefixes.find(cp => cp.key === selectedCountry).value;

    this._isMounted = true;
    this.setState({ card });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState = (state, callback) => {
      return;
    }
  }

  onChange = e => {
    const { card, errors } = this.state;
    errors['onConfirm'] = [];
    card[e.prop] = e.value.replace(/[^0-9]/g, '');
    if (this._isMounted) {
      this.setState({ card, errors });
    }
  };

  confirm = async e => {
    const { card, errors } = this.state;

    e.persist();
    if (card.cardPrefix.length < 11 || card.cardNumber.length < 8) {
      errors['onConfirm'] = [this.props.remindEmailFlow ? "remind_email_page_empty_number_field_error" : "card_replacement_page_incorrect_card_number_error"];
    }
    else {
      const result = (await fetcherApi.post(urlTools.constructRoute("api/RegistrationCode/validate"), {
        cardPrefix: card.cardPrefix,
        cardNumber: card.cardNumber
      })).response;

      if (result.data.success) {
        let accountInactiveCode = result.data.resultObject.accountInactivecode;
        let hasEmail = result.data.resultObject.hasEmail;
        this.setState({ isBusiness: result.data.resultObject.isBusiness });

        if (accountInactiveCode === 'CONFIRMED' && hasEmail === false) {
          this.setState({ confirmedEnterEmail: true, confirmed: true });
        }
        else if (accountInactiveCode === 'CONFIRMED' && hasEmail === true) {
          if (this.props.remindEmailFlow) {
            this.setState({ confirmed: true });
          } else { 
            errors['onConfirm'] = ['account_found_page_info'];
          }
        }
        else if (accountInactiveCode === 'PENDING_REG') {
          this.setState({ confirmedEnterEmailNoAccount: true, confirmed: true, isPhysicalCardRegistration: true });
        }
      }
      else {
        if (result.data.response?.data === "replacingCardWithInitialStatus") {
          errors['onConfirm'] = ['enter_existing_card_page_initial_card_error'];
        }
        else if (result.data.error === "rate_limit_reached") {
          errors['onConfirm'] = ['enter_existing_card_page_rate_limit_reached'];
        }
        else if (result.data.error === "cardIsVirtual") {
          errors['onConfirm'] = ['enter_existing_card_virtual_card_entered_error']
        }
        else {
          errors['onConfirm'] = ['enter_existing_card_page_card_not_found_error'];
        }
      }
    }
    if (this._isMounted) {
      this.setState({ errors });
    }
  };

  getRedirectionLink() {
    const { confirmedEnterData, confirmedEnterEmailNoAccount, confirmedEnterEmail } = this.state;

    if (this.props.remindEmailFlow) {
      if (confirmedEnterEmail) {
        return '/registration/HasAccount/EnterEmail';
      }
      return '/registration/HasAccount/EnterEmailWithHint';
    }

    else if (confirmedEnterData)
      return '/registration/HasAccount/YourData';
    else if (confirmedEnterEmailNoAccount)
      return '/registration/NoAccount/CreateNewMyRimiAccount';
    else if (confirmedEnterEmail)
      return '/registration/HasAccount/EnterEmail';
  }

  render() {
    const { card, errors, confirmed } = this.state;

    return (
      <React.Fragment>
        <div className="container">
          <div className="card">
            <AutoHideableHeader>
              <RimiLogoHeaderButton initialData={this.props.initialData} />
              {
                this.props.remindEmailFlow
                  ? (
                    <StepBackHeader
                      pathName="/login"
                      buttonText="login_button_label"
                      iconBeforeText={<svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="#a12971" strokeWidth="2" transform="translate(5 4)"><path d="m3.8 5.6v-5.6h7.7c1.2 0 2.1.9 2.1 2.1v11.8c0 1.2-.9 2.1-2.1 2.1h-7.7v-5.6" /><path d="m0 8h9" /><path d="m6.1 10.9 2.9-2.9-2.9-3" /></g></svg>}
                    />
                  ) : (
                    <StepBackHeader
                      pathName="/registration/ExistingCard"
                      buttonText="step_back_button_label"
                    />
                  )
              }
            </AutoHideableHeader>
            <div className="card-content no-padding-bottom">
              <h1>
                <Trans i18nKey={this.props.remindEmailFlow
                  ? 'remind_email_page_title'
                  : 'enter_existing_card_page_title'}>
                  Enter last 8 digits of <b>new My Rimi ID</b>
                </Trans>
              </h1>

              <div className="form-group">
                <div className="form-row rimi-card-back">
                  <img className="rimi-card-back-image" src={require("assets/img/rimi-card-back.jpg")} alt="Mans Rimi" />
                  <div className="card-numbers">
                    <div className="form-col">
                      <div className="form-field">

                        <InputText
                          prop="cardPrefix"
                          className="form-field__input"
                          value={card.cardPrefix}
                          onChange={this.onChange}
                          maxLength="11"
                          type="tel"
                        />
                      </div>
                    </div>
                    <div className="form-col">
                      <div className="form-field">
                        <InputText
                          prop="cardNumber"
                          className="form-field__input"
                          value={card.cardNumber}
                          onChange={this.onChange}
                          maxLength="8"
                          type="tel"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sublabel error-msg show">
                  <span>
                    {
                      (errors.onConfirm !== undefined && errors.onConfirm.length > 0)
                        ? <TranslationText text={errors.onConfirm[0]} />
                        : null
                    }
                  </span>
                </div>

              </div>

              <div className="form-button full-width">

                {!confirmed ? (
                  <button
                    type="submit"
                    onClick={this.confirm}
                    className="button"
                  >
                    <TranslationText text={this.props.remindEmailFlow
                      ? 'remind_email_page_confirm_btn'
                      : 'confirm_button_label'} />
                  </button>
                ) :
                  (<Redirect
                    to={{
                      pathname: this.getRedirectionLink(),
                      state: {
                        cardPrefix: card.cardPrefix,
                        cardNumber: card.cardNumber,
                        remindEmailFlow: this.props.remindEmailFlow,
                        isBusiness: this.state.isBusiness,
                        isPhysicalCardRegistration: this.state.isPhysicalCardRegistration
                      }
                    }} />)
                }
              </div>
              <div className="form-button full-width">
                <Link className="button -secondary" to={{ pathname: '/login' }}>
                  <TranslationText text={this.props.remindEmailFlow
                    ? 'remind_email_page_return_btn'
                    : 'return_to_registration_page'} />
                </Link>
              </div>
              <hr className="spacer" />
            </div>
            <footer className="card-footer no-border">
              <div className="footer-text">
                <ConsentLinks />
              </div>
            </footer>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(EnterExistingCard);


