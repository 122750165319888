import React, { Component } from "react";
import Decline from "../Decline.jsx";
import ConsentLinks from "../Consents/ConsentLinks.jsx";
import Footer from "../Footer.jsx";
import TranslationText from "../Translation/TranslationText.jsx";
import { withTranslation, Trans } from 'react-i18next';
import AuthService from "components/services/AuthService.js";
import { GaEventActionConstants } from "components/tools/GaEvents/GaEventActionConstants.jsx";
import webTools from "components/tools/webTools.js";
import FetcherApi from "../FetcherApi.jsx";
import urlTools from "components/tools/urlTools.jsx";
import { pushAccountEvent } from "components/tools/GaEvents/GaEventSender.jsx";
import PropTypes from "prop-types";
import { MISSING_PROFILE_DATA_ERROR } from "components/constants/errorCodes.jsx";
import { withRouter } from "react-router-dom/cjs/react-router-dom.js";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";

class ConsentsAcceptance extends Component {

	state = {
		showDecline: false,
		errors: {},
		consentsAccepted: false,
		isLoading: false
	}

	componentDidMount() {
		this.props.hideNavBar(true);
	}

	returnToLogin = () => {
		AuthService.logout();
	}

	hideDecline = () => {
		this.setState({ showDecline: false })
	}

	showDeclinePopup = async e => {
		e.preventDefault();

		this.setState({ showDecline: true });
	}

	handleSave = async (event) => {
		event.preventDefault();
		const { consentsAccepted, errors } = this.state;

		if (!consentsAccepted) {
			errors.consents = "add_your_data_page_no_consents_error";
			this.setState({ errors });
			return;
		}

		this.setState({ isLoading: true });

		const route = this.props.acceptanceRoute;
		const result = (await FetcherApi.post(urlTools.constructRoute(route))).response;

		if (result.data.success) {
			pushAccountEvent(GaEventActionConstants.UserLoginSuccess);
			webTools.reloadToRoute();
		} else if (result.data.error === MISSING_PROFILE_DATA_ERROR) {
			this.props.history.push(this.props.dataStepRoute, {
				payload: result.data.resultObject
			});
		}

		this.setState({ isLoading: false });
	}

	onConsentsAcceptanceChange = () => {
		const { consentsAccepted, errors } = this.state;
		errors.consents = undefined;

		this.setState({ consentsAccepted: !consentsAccepted, errors });
	}

	render() {
		const { errors } = this.state;
		return <React.Fragment> <div>

			<div className="container privacy-page">

				<div>
					{(this.state.showDecline) ? <Decline oldConsents={this.props.oldConsents} returnToLogin={this.returnToLogin.bind(this)} hideDecline={this.hideDecline.bind(this)} /> : null}
				</div>

				<div>
					<div className="card">
						<div className="card-content">
							<div className="single-message">
								<div className="icon">
									<svg height="108" viewBox="0 0 90 108" width="90" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" transform="translate(2 2)"><path d="m42.9565217 104s-42.9565217-15.2947826-42.9565217-59.5806957v-36.50626082l42.9565217-7.91304348 42.9565218 7.91304348v36.50626082c0 44.6996522-42.9565218 59.5806957-42.9565218 59.5806957z" stroke="#a12971" strokeWidth="3" /><path d="m42.956522 20.347826v31.652174" stroke="#a12971" strokeWidth="3" /><path d="m46 64c0 1.656-1.3425 3-3 3s-3-1.344-3-3c0-1.6545 1.3425-3 3-3s3 1.3455 3 3" fill="#a12971" /></g></svg>                    </div>
								<h1>
									<TranslationText text={this.props.oldConsents
										? 'update_consents_popup_page_title'
										: 'missing_consents_popup_page_title'} />
								</h1>
							</div>

							<hr />

							<div className="scrollable-content">
								<h3>
									<p>
										<Trans i18nKey={this.props.oldConsents
											? 'update_consents_popup_page_info_title'
											: 'missing_consents_popup_page_info_title'}
											components={{ bullets: <ul />, bullet: <li /> }}>
											We have made changes to the Terms and Conditions:
											<bullets>
												<bullet>1st change</bullet>
												<bullet>2nd change</bullet>
												<bullet>3rd change</bullet>
												<bullet>4th change</bullet>
											</bullets>
										</Trans>
									</p>
								</h3>
								<p>
									<Trans i18nKey={this.props.oldConsents
										? 'update_consents_popup_page_info'
										: 'missing_consents_popup_page_info'}>
										Consents page info.
									</Trans>
								</p>
								<div>
									<ConsentLinks translationKey={this.props.oldConsents
										? "update_consents_popup_page_consents_links"
										: "missing_consents_popup_page_consents_links"} />
								</div>
							</div>

							<div className="form-group -required">
								<div className="form-row">
									<div className={`form-field -checkbox ${errors.consents ? "-has-error" : ""}`}>
										<div className="form-check">
											<input className="form-check-input" onChange={this.onConsentsAcceptanceChange} checked={this.state.consentsAccepted} type="checkbox" id="user_agreement" />
											<label className="form-check-label" htmlFor="user_agreement">
												<ConsentLinks translationKey="consent_agreement_text" />
												<span></span>
											</label>
										</div>
										<div className="sublabel error-msg show">
											<span>
												<TranslationText text={errors.consents} />
											</span>
										</div>
									</div>
								</div>
							</div>

							<div className="form-button full-width">
								<button onClick={this.showDeclinePopup} className="button -secondary">
									<span>
										<TranslationText text={this.props.oldConsents
											? "update_consents_popup_page_not_accept"
											: 'missing_consents_popup_page_not_agree_btn'} />
									</span>
								</button>
								<Link
									className={`modal-close button -secondary ${this.state.isLoading ? "-disabled" : ""}`}
									to="#"
									onClick={this.handleSave}
								>
									<TranslationText text={this.props.oldConsents
										? "update_consents_popup_page_accept_btn"
										: 'missing_consents_popup_page_agree_btn'} />

								</Link>
							</div>
						</div>
						<div className={(errors['onConsents'] !== undefined && errors['onConsents'].length > 0)
							? "form-field -has-error" : "form-field"}>
							<label className="sublabel error-msg show"><TranslationText text={errors['onConsents']} /></label>
						</div>
					</div>
				</div>
			</div>
		</div>
			<Footer countrySelectionEnabled={false} />
		</React.Fragment>


	}
}
export default withRouter(withTranslation()(ConsentsAcceptance));

ConsentsAcceptance.propTypes = {
	acceptanceRoute: PropTypes.string.isRequired,
	dataStepRoute: PropTypes.string.isRequired,
	hideNavBar: PropTypes.func.isRequired,
	oldConsents: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired
};