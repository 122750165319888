import { PasswordRuleChecklistRow } from "./PasswordRuleChecklistRow";
import PropTypes from "prop-types";
import TranslationText from "../Translation/TranslationText";
import PasswordValidator from "components/tools/validation/PasswordValidator";

export function PasswordRuleChecklist(props) {
	const {
		isMinLengthRuleSatisfied,
		isUppercaseLetterRuleSatisfied,
		isLowercaseLetterRuleSatisfied,
		isDigitRuleSatisfied,
		isSpecialSymbolRuleSatisfied
	} = PasswordValidator.getRuleSatisfactionResult(props.password);

	return (
		<div>
			<label>
				<TranslationText text="password_rule_checklist_title" />
			</label>
			<div className="password-rule-checklist">
				<PasswordRuleChecklistRow
					isRuleSatisfied={isMinLengthRuleSatisfied}
					ruleTranslationKey="password_rule_checklist_min_length_rule"
				/>
				<PasswordRuleChecklistRow
					isRuleSatisfied={isUppercaseLetterRuleSatisfied}
					ruleTranslationKey="password_rule_checklist_uppercase_letter_rule"
				/>
				<PasswordRuleChecklistRow
					isRuleSatisfied={isLowercaseLetterRuleSatisfied}
					ruleTranslationKey="password_rule_checklist_lowercase_letter_rule"
				/>
				<PasswordRuleChecklistRow
					isRuleSatisfied={isDigitRuleSatisfied}
					ruleTranslationKey="password_rule_checklist_digit_rule"
				/>
				<PasswordRuleChecklistRow
					isRuleSatisfied={isSpecialSymbolRuleSatisfied}
					ruleTranslationKey="password_rule_checklist_special_symbol_rule"
				/>
				{
					!props.neutralRulesDisabled ? (
						<PasswordRuleChecklistRow
							isRuleNeutral={true}
							ruleTranslationKey="password_rule_checklist_not_used_before_rule"
						/>
					) : null
				}

			</div>
		</div>
	);
}

PasswordRuleChecklist.propTypes = {
	password: PropTypes.string,
	neutralRulesDisabled: PropTypes.bool
};