import React, { useEffect, useRef, useState } from 'react';
import InputText from './InputText.jsx';
import CountryService from '../tools/localization/CountryService.jsx';
import DateFunctions from "./DateFunctions.jsx";
import DateInputService from '../services/DateInputService.jsx';
import TranslationText from './Translation/TranslationText.jsx';

const dayLength = 2;
const monthLength = 2;
const yearLength = 4;

export function DateInput(props) {
  const isYYYYMMDDLayout = CountryService.getCountry() === "lt";

  const dayInputRef = useRef(null);
  const monthInputRef = useRef(null);
  const yearInputRef = useRef(null);

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const [dayInputCount, setDayInputCount] = useState(0);
  const [monthInputCount, setMonthInputCount] = useState(0);
  const [yearInputCount, setYearInputCount] = useState(0);

  useEffect(() => {
    if (props.initialDateString) { return; }
    if (!day && !month && !year) { return; }

    const datePartsParsable = !!DateInputService.constructDate(day, month, year);
    if (!datePartsParsable) { return; }

    yearChanged('');
    monthChanged('');
    dayChanged('');
  }, [props.initialDateString]);

  useEffect(() => {
    if (!props.initialDateString) { return; }
    if (day || month || year) { return; }

    setInitialDate(props.initialDateString);
  }, [day, month, year, props.initialDateString]);

  const setInitialDate = (initialDateString) => {
    const date = new Date(initialDateString);

    const day = date.getDate().toString();
    const sanitizedDay = DateInputService.getSanitizedValue(day, dayLength);
    setDay(sanitizedDay);

    const month = (date.getMonth() + 1).toString();
    const sanitizedMonth = DateInputService.getSanitizedValue(month, monthLength);
    setMonth(sanitizedMonth);

    const year = date.getFullYear().toString();
    const sanitizedYear = DateInputService.getSanitizedValue(year, yearLength);

    setYear(sanitizedYear);
  };

  const dayChanged = (inputTextChangeEvent) => {
    const updatedInputCount = DateInputService.getUpdatedInputCount(day, inputTextChangeEvent.value, dayInputCount);
    setDayInputCount(updatedInputCount);

    const sanitizedValue = DateInputService.getSanitizedValue(inputTextChangeEvent.value, dayLength, updatedInputCount);

    setDay(sanitizedValue);

    finalizeDayChange(sanitizedValue, updatedInputCount);
  };

  const monthChanged = (inputTextChangeEvent) => {
    const updatedInputCount = DateInputService.getUpdatedInputCount(month, inputTextChangeEvent.value, monthInputCount);
    setMonthInputCount(updatedInputCount);

    const sanitizedValue = DateInputService.getSanitizedValue(inputTextChangeEvent.value, monthLength, updatedInputCount);

    setMonth(sanitizedValue);

    finalizeMonthChange(sanitizedValue, updatedInputCount);
  };

  const yearChanged = (inputTextChangeEvent) => {
    const updatedInputCount = DateInputService.getUpdatedInputCount(year, inputTextChangeEvent.value, yearInputCount);
    setYearInputCount(updatedInputCount);

    const sanitizedValue = DateInputService.getSanitizedValue(inputTextChangeEvent.value, yearLength, updatedInputCount);

    setYear(sanitizedValue);

    finalizeYearChange(sanitizedValue, updatedInputCount);
  };

  const finalizeDayChange = (sanitizedValue, inputCount) => {
    if (inputCount === dayLength) {
      if (!isYYYYMMDDLayout) {
        monthInputRef.current.focus();
      }
    }

    publishDateChange(sanitizedValue, month, year);
  };

  const finalizeMonthChange = (sanitizedValue, inputCount) => {
    if (inputCount === monthLength) {
      if (isYYYYMMDDLayout) {
        dayInputRef.current.focus();
      } else {
        yearInputRef.current.focus();
      }
    }

    publishDateChange(day, sanitizedValue, year);
  };

  const finalizeYearChange = (sanitizedValue, inputCount) => {
    if (inputCount === yearLength) {
      if (isYYYYMMDDLayout) {
        monthInputRef.current.focus();
      }
    }

    publishDateChange(day, month, sanitizedValue);
  };

  const publishDateChange = (day, month, year) => {
    const date = DateInputService.constructDate(day, month, year);
    const isoMidnight = DateFunctions.getIsoMidnight(date);

    props.onChange && props.onChange(isoMidnight);
  };

  const hasError = () => {
    return props.errorMessage !== undefined && props.errorMessage.length > 0;
  }

  return (
    <>
      <div id={props.id} className={`form-row -multipart-date ${hasError() ? '-has-error' : ''}`}>
        <div className={`form-field date-input ${isYYYYMMDDLayout ? 'years' : 'days'}`}>
          <InputText
            type="tel"
            prop="day"
            disabled={props.readOnly}
            ref={isYYYYMMDDLayout ? yearInputRef : dayInputRef}
            value={isYYYYMMDDLayout ? year : day}
            onChange={isYYYYMMDDLayout ? yearChanged : dayChanged}
            placeholder={isYYYYMMDDLayout ? 'YYYY' : 'DD'}
          />
        </div>
        <div className="form-field date-input months">
          <InputText
            ref={monthInputRef}
            type="tel"
            prop="month"
            disabled={props.readOnly}
            value={month}
            onChange={monthChanged}
            placeholder="MM"
          />
        </div>
        <div className={`form-field date-input ${!isYYYYMMDDLayout ? 'years' : 'days'}`}>
          <InputText
            prop="year"
            type="tel"
            disabled={props.readOnly}
            ref={!isYYYYMMDDLayout ? yearInputRef : dayInputRef}
            value={!isYYYYMMDDLayout ? year : day}
            onChange={!isYYYYMMDDLayout ? yearChanged : dayChanged}
            placeholder={!isYYYYMMDDLayout ? 'YYYY' : 'DD'}
          />
        </div>
      </div>
      {
        hasError() ? (
          <div className="form-row">
            <div className="sublabel error-msg show">
              <span>
                <TranslationText text={props.errorMessage} options={props.errorMessageTranslationOptions} />
              </span>
            </div>
          </div>
        ) : null
      }
    </>
  );
}
