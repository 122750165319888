import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Spinner from './Information/Spinner.jsx';
import ConfirmationNoRedirect from "../components/EmailVerification/ConfirmationNoRedirect.jsx";

class EmailVerifiedOnEmailChange extends Component {

  state = {
    confirmed: false,
    linkExpired: false,
    loaded: false
  };

  componentDidMount() {
    let emailVerificationFailed = this.props.initialData.emailVerificationFailed;
    if (emailVerificationFailed) {
      this.setState({ linkExpired: true });
    }
    else {

      this.setState({
        loaded: true
      });
      setTimeout(() => {
        this.setState({ confirmed: true });
      }, 1000)
    }
  }

  render() {
    if (this.state.linkExpired) {
      return (<Redirect to={{ pathname: '/registration/HasAccount/EmailExpired' }} />)
    }
    else if (this.state.loaded) {
      return (<React.Fragment>
        <ConfirmationNoRedirect initialData={this.props.initialData} infoText="email_verify_page_no_redirect" titleText="email_verified_page_title" />
      </React.Fragment>
      );
    }
    else
      return <Spinner />
  }
}

export default EmailVerifiedOnEmailChange;




