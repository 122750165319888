import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import { PasswordRuleChecklist } from "./PasswordRuleChecklist";
import { cloneElement, useEffect, useRef, useState } from "react";

export function PasswordRuleChecklistOverlay(props) {
	const childRef = useRef(null);
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const passwordInputElement = childRef.current.querySelector("input");

		passwordInputElement.addEventListener("focus", _ => setIsVisible(true));
		passwordInputElement.addEventListener("blur", _ => setIsVisible(false));
	}, [])

	return (
		<Tippy
			className="password-rule-checklist-tippy"
			visible={isVisible}
			content={<PasswordRuleChecklist password={props.password} neutralRulesDisabled={props.neutralRulesDisabled} />}
			popperOptions={{
				modifiers: [{
					name: 'offset',
					options: { offset: [65, 20] }
				}]
			}}
		>
			{
				cloneElement(props.children, { ref: childRef })
			}
		</Tippy>
	);
}

PasswordRuleChecklistOverlay.propTypes = {
	password: PropTypes.string,
	children: PropTypes.node.isRequired,
	neutralRulesDisabled: PropTypes.bool
};