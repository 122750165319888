import React, { Component, createRef } from "react";
import fetcherApi from '../components/FetcherApi.jsx';
import "regenerator-runtime/runtime";
import Tippy from '@tippyjs/react';
import Spinner from '../profile/Information/Spinner.jsx';
import Footer from "../../components/components/Footer.jsx";
import NotificationSuccess from "../components/Notifications/NotificationSuccess.jsx";
import TranslationText from "../components/Translation/TranslationText.jsx";
import AutoHideableTitle from "../components/HeaderComponents/AutoHideableTitle.jsx";
import urlTools from "../tools/urlTools.jsx";

class BusinessCommunication extends Component {

  constructor(props) {
    super(props);
    this.notificationPopupRef = createRef();
  }

  state = {
    errors: {},
    dirtyfields: {},
    communicationChannels: {},
    initialCommunicationChannels: {}
  };

  setRedirectBack = () => {
    this.setState({
      redirectBack: true
    })
  }

  async componentDidMount() {
    this._isMounted = true;
    const communicationChannels = (await fetcherApi.get(urlTools.constructRoute("api/B2bProfile/CommunicationChannels/communications"))).response.data.resultObject;
    const initialCommunicationChannels = JSON.parse(JSON.stringify(communicationChannels));

    this.setState({
      communicationChannels,
      initialCommunicationChannels
    })
  }

  hasErrors = () => {
    const { errors } = this.state;

    for (var error in errors) {
      if (errors[error] && errors[error].length) {
        return true;
      }
    }
    return false;
  };

  handleChange = e => {
    const { communicationChannels } = this.state;
    communicationChannels[e.target.value] = e.target.checked;
    console.log(communicationChannels)
    this.setState({ communicationChannels });
  };

  save = async e => {
    e.preventDefault();
    const { communicationChannels, initialCommunicationChannels } = this.state;
    if (communicationChannels.sms === initialCommunicationChannels.sms &&
      communicationChannels.email === initialCommunicationChannels.email) {
      console.log('no changes found, skipping update.');
      return;
    }

    const communicationChannelsUpdated = (await fetcherApi.post(urlTools.constructRoute("api/B2bProfile/CommunicationChannels/communications"), JSON.stringify(communicationChannels))).response.data.resultObject;
    this.notificationPopupRef.current.showPopup();

    this.setState({
      communicationChannels: communicationChannelsUpdated,
      initialCommunicationChannels: JSON.parse(JSON.stringify(communicationChannelsUpdated))
    })
  };

  render() {

    const { communicationChannels } = this.state;
    return communicationChannels != null ? (
      <React.Fragment>
        <div className="container page-container">
          <AutoHideableTitle text={'b2b_profile_comm_channels_page_title'} />
          <div className="card card-full-width">
            <div className="card-content">
              <div className="page-description-row">
                <p>
                  <TranslationText text='b2b_profile_comm_channels_page_info_title' />
                </p>
                <p>
                  <TranslationText text='b2b_profile_comm_channels_page_info' />
                </p>
              </div>

              <hr />

              <div className="row communication-row">

                <div className="column-desktop-6 column-tablet-12">
                  <div>
                    <div className="form-group">
                      <div className="form-row">
                        <h2 className="help">
                          <TranslationText text='b2b_profile_comm_channels_page_agree_title' />

                          <Tippy content={
                            <TranslationText text='b2b_profile_comm_channels_page_comm_channels_info_msg' />
                          }>
                            <span className="help-tip">
                              <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" transform="translate(1 1)"><g stroke="currentColor" strokeWidth="2"><path d="m11.9497609 2.0502513c2.7336704 2.73367044 2.7336704 7.16583913 0 9.8994913-2.73367047 2.7336704-7.16583916 2.7336704-9.89949133 0-2.73367044-2.73367043-2.73367044-7.16583912 0-9.8994913 2.73367043-2.73367043 7.16583913-2.73367043 9.89949133 0" /><path d="m7 8.82608696v-.48695653c0-.63913043.33478261-1.24782608.91304348-1.58260869.88260869-.51739131 1.18695652-1.61304348.66956522-2.49565217-.51739131-.8826087-1.61304348-1.18695653-2.49565218-.66956522-.54782609.33478261-.91304348.91304348-.91304348 1.58260869" /></g><path d="m7.82533029 10.0601891c.43297264.4329726.43297264 1.1349602 0 1.5679334-.43297265.4329726-1.13496021.4329726-1.5679334 0-.43297265-.4329727-.43297265-1.1349602 0-1.5679334.43297264-.43297268 1.1349602-.43297268 1.5679334 0" fill="#a4afb3" /></g></svg>
                            </span>
                          </Tippy>
                        </h2>
                      </div>
                      <div className="form-row">
                        <div className="form-field -checkbox">
                          <div className="form-check">
                            <input type="checkbox"
                              checked={communicationChannels.email || false}
                              onChange={this.handleChange}
                              value='email'
                              id="info_email"
                              className="form-check-input"
                            />
                            <label className="form-check-label" htmlFor="info_email">
                              <TranslationText text='b2b_profile_comm_channels_page_email_box' />                                                            <span></span>
                            </label>
                          </div>
                          <div className="form-check">
                            <input type="checkbox"
                              checked={communicationChannels.sms || false}
                              onChange={this.handleChange}
                              value='sms'
                              className="form-check-input"
                              id="info_phone"
                            />
                            <label className="form-check-label" htmlFor="info_phone">
                              <TranslationText text='b2b_profile_comm_channels_page_phone_box' />
                              <span></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="column-medium-3 column-desktop-6 column-tablet-12">
                  <div className="form-button full-width">
                    <hr className="spacer" />
                    <hr className="spacer" />
                    <button
                      type="submit"
                      onClick={this.save}
                      disabled={this.hasErrors()}
                      className="button"
                    >
                      <TranslationText text='b2b_profile_comm_channels_page_save_btn' />
                    </button>
                    <NotificationSuccess ref={this.notificationPopupRef}></NotificationSuccess>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>
    ) : <Spinner />
  }
}

export default BusinessCommunication;