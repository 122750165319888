import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Footer from "../../components/Footer.jsx";
import InputText from "../../components/InputText.jsx";
import fetcherApi from '../../components/FetcherApi.jsx';
import LegalEntityFunctions from "../../components/LegalEntityFunctions.jsx";
import SendVerificationCode from "../../components/Buttons/SendVerificationCode.jsx";
import TryAgainFooterButtons from "../../components/Buttons/TryAgainFooterButtons.jsx";
import ConsentLinks from "../../components/Consents/ConsentLinks.jsx";
import * as constants from "../../constants/constants.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import { Trans, withTranslation } from 'react-i18next';
import RimiLogoHeaderButton from "../../components/HeaderComponents/RimiLogoHeaderButton.jsx";
import AutoHideableHeader from "../../components/HeaderComponents/AutoHideableHeader.jsx";
import StepBackHeader from "../../components/HeaderComponents/StepBackHeader.jsx";
import validator from "../../components/FieldValidator.jsx";
import urlTools from "../../tools/urlTools.jsx";
import EmailAndPhoneValidator from "../../components/EmailAndPhoneValidator.jsx";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

class CreateNewMyRimiAccount extends Component {

  state = {
    confirmed: false,
    error: false,
    email: "",
    errorText: "",
    confirmed: false,
    isProcessing: false,
    selectedCountry: "",
    beginRecaptchaValidation: undefined
  };

  componentDidMount() {
    this.setState({ selectedCountry: LegalEntityFunctions.getLegalEntityId(localStorage.getItem(constants.SELECTED_COUNTRY)) });
  }

  onChange = e => {
    e.value = validator.blockSpaceSymbol(e.value);
    this.setState({
      email: e.value,
      error: false,
      errorText: ''
    });
  };

  beginRecaptchaValidation = () => {
    this.setState({
      beginRecaptchaValidation: !this.state.beginRecaptchaValidation,
      isProcessing: true
    });
  }

  completeRecaptchaValidation = (recaptchaToken) => {
    this.confirm(recaptchaToken);
  }

  confirm = async (recaptchaToken) => {
    const emailStructureError = EmailAndPhoneValidator.getEmailStructureError(this.state.email);
    if (emailStructureError) {
      this.setState({
        error: true,
        errorText: emailStructureError,
        isProcessing: false
      });

      return;
    }

    const requestSettings = { recaptchaToken };
    const url = this.props.location.state?.isPhysicalCardRegistration
      ? urlTools.constructRoute('api/RegistrationCode/send-code-physical/' + this.state.email)
      : urlTools.constructRoute('api/RegistrationCode/send-code-virtual/' + LegalEntityFunctions.getLegalEntityId(localStorage.getItem(constants.SELECTED_COUNTRY)) + '/' + this.state.email);

    const httpResponse = await fetcherApi.post(url, null, requestSettings);
    const result = httpResponse.response;

    if (result.data.success) {
      this.setState({ confirmed: true, isProcessing: false });
    }
    else {
      if (result.data.error === "verification_resend_limit_reached") {
        this.setState({ error: true, errorText: "create_new_account_page_email_limit_error" });
      } else if (result.data.error === "duplicate_email") {
        this.setState({ error: true, errorText: "create_new_my_rimi_account_page_existing_email_error" });
      } else if (result.data.error === "email_incorrect_format") {
        this.setState({ error: true, errorText: "account_found_but_no_email_page_in_login_empty_email_field_error" });
      } else if (result.data.error === "email_placeholer") {
        this.setState({ error: true, errorText: "account_found_but_no_email_page_incorrect_email_error" });
      } else {
        this.setState({ error: true, errorText: result.data.error });
      }
      this.setState({ isProcessing: false });
    }
  }

  renderButtons() {
    if (!this.state.error && !this.state.confirmed) {
      return <SendVerificationCode confirm={this.beginRecaptchaValidation} />
    }
    else if (!this.state.confirmed) {
      return <TryAgainFooterButtons confirm={this.beginRecaptchaValidation} isProcessing={this.state.isProcessing} />
    }
  }

  renderBottom() {
    if (!this.state.error) {
      return (<React.Fragment>
        <footer className="card-footer no-border">
          <div className="footer-text">
            <ConsentLinks />
          </div>
        </footer>
      </React.Fragment>)
    }
    else if (!this.state.confirmed) {
      return <React.Fragment><footer className="card-footer">
        <div className="form-button full-width">
          <Link className="button -secondary" to={{ pathname: '/login' }}><TranslationText text='login_button_label' />
          </Link>
        </div>
        <div className="footer-text">
          <ConsentLinks />
        </div>
      </footer></React.Fragment>
    }
  }

  render() {
    return <React.Fragment>
      <div className="container">
        <div className="card">
          <AutoHideableHeader>
            <RimiLogoHeaderButton initialData={this.props.initialData} />
            <StepBackHeader
              pathName={'/registration/ExistingCard'}
              buttonText={'step_back_button_label'} />
          </AutoHideableHeader>
          <div className={this.state.error ? ("card-content") : ("card-content no-padding-bottom")}>
            <h1>
              <Trans i18nKey='create_new_my_rimi_account'>
                Create new <b>My Rimi account</b>
              </Trans>
            </h1>
            {!this.state.confirmed ?
              <React.Fragment>
                <p><TranslationText text='create_new_my_rimi_account_page_info' /></p>
              </React.Fragment>
              :
              null
            }
            {this.state.confirmed ? (
              <Redirect to={{
                pathname: '/VerificationCodeLogin',
                state: {
                  email: this.state.email,
                  rateLimitError: 'enter_existing_card_page_rate_limit_reached',
                  resendUrl: 'api/RegistrationCode/resend-email',
                  returnUrl: 'registration/NoAccount/CreateNewMyRimiAccount',
                  titleTranslation: 'verification_code_login_title',
                  infoMessageTranslation: 'verification_code_login_info_message',
                  didNotReceiveMessageTranslation: 'verification_code_did_not_receive_message',
                  usesCustomRedirect: true,
                  customRedirectRoute: "/registration/HasAccount/YourData",
                  customCodeConfirmUrl: urlTools.constructRoute("api/RegistrationCode/verify-email"),
                  isPhysicalCardRegistration: this.props.location.state?.isPhysicalCardRegistration
                }
              }} />) :
              (<div className="form-group">
                <div className="form-row">
                  <div className={!this.state.error ? "form-field" : "form-field -has-error"}>

                    <label htmlFor="email" className="form-field__label"><TranslationText text='email_field_label' /></label>
                    <InputText
                      onChange={this.onChange}
                      prop="email"
                      value={this.state.email}
                      className="form-field__input"
                      placeholder="example@gmail.com"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="sublabel error-msg show">
                    <span>{<TranslationText text={this.state.errorText} />}</span>
                  </div>
                </div>
              </div>)
            }
            {
              this.state.beginRecaptchaValidation !== undefined
                ? <GoogleReCaptcha onVerify={this.completeRecaptchaValidation} refreshReCaptcha={this.state.beginRecaptchaValidation} />
                : null
            }
            {this.renderButtons()}
          </div>
          {this.renderBottom()}
        </div>
      </div>
      <Footer countrySelectionEnabled={false} />
    </React.Fragment>
  }
}

export default withTranslation()(CreateNewMyRimiAccount);


