import React, { Component } from "react";
import TranslationText from "../Translation/TranslationText.jsx";

class TryAgainReturnToLogin extends Component {

  Login = () => {
    this.props.Login();
  }

  resetInitialState = () => {
    this.props.resetInitialState();
  }

  render() {
    return (<React.Fragment>
      <div className="form-button full-width">
        <button
          type="submit"
          onClick={this.Login}
          className="button"
        >
          <TranslationText text='try_again_button_label' />
        </button>
      </div>

      <div className="form-button full-width">
        <button
          onClick={this.resetInitialState}
          className="button -secondary"
        >
          <TranslationText text='return_to_login_page' />
        </button>
      </div>
      <hr className="spacer" />
    </React.Fragment>)
  }
}
export default TryAgainReturnToLogin;