import React, { Component, createRef } from "react";
import InputText from "../../components/InputText.jsx";
import validator from "../../components/FieldValidator.jsx";
import "regenerator-runtime/runtime";
import fetcherApi from '../../components/FetcherApi.jsx';
import Spinner from '../../profile/Information/Spinner.jsx';
import { Link } from "react-router-dom";
import Footer from "../../components/Footer.jsx"
import ConfirmationModalDialog from "../../components/ConfirmationModalDialog.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import i18n from "translations/i18n.jsx";
import { withTranslation } from "react-i18next";
import AutoHideableTitle from "../../components/HeaderComponents/AutoHideableTitle.jsx";
import { DateInput } from '../../components/DateInput.jsx';
import urlTools from "../../tools/urlTools.jsx";
import NotificationSuccess from '../../components/Notifications/NotificationSuccess.jsx';
import * as constants from "../../constants/notificationTypes.jsx";


class PetsClub extends Component {
  constructor(props) {
    super(props);
    this.notificationPopupRef = createRef();
  }
  state = {
    newPet: {},
    dirtyfields: {},
    pets: null,
    errors: {},
    petTypesTexts: [],
    validations: {},
    language: "En",
    showPopup: false,
    petId: 0,
    showConfirmPopup: false
  };

  async componentDidMount() {
    const result = (await fetcherApi.get(urlTools.constructRoute("api/profile/Clubs/PetsClub/" + this.props.initialData.legalEntityId + "/pets"))).response.data;
    this.setState({
      pets: result.resultObject.pets,
      validations: result.resultObject.validations,
      petTypesTexts: result.resultObject.petTypes.eN_Texts,
    })
  }

  showPopup = () => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showPopup: true })
  }

  showConfirmPopup = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showConfirmPopup: true })
  }

  hidePopup = () => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showPopup: false })
  }

  hideConfirmPopup = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showConfirmPopup: false })
  }

  leaveClub = async (e) => {

    const { pets } = this.state;

    if (pets !== null && pets.length > 0) {
      const result = (await fetcherApi.delete(urlTools.constructRoute("api/profile/Clubs/PetsClub/" + this.props.initialData.legalEntityId + "/pets")).response);
      this.setState({ pets: [], errors: {} });
    }
    this.setState({ showPopup: false })
  };

  findArrayElementByTitle(texts, key) {
    if (key === 0) {
      return '';
    }
    let petType = texts.find((element) => {
      return element.key === key;
    });

    return i18n.t(this.getPetTypesTranslation(petType.value));
  }

  onBirthDateChange = value => {
    const { newPet, errors } = this.state
    errors['birthDate'] = [];
    newPet['birthDate'] = value;

    this.setState({ newPet, errors });
  }

  onChange = async e => {

    const { newPet, errors } = this.state
    let value = e.value;

    errors['name'] = [];
    value = validator.blockUnwantedSymbols(e.value);
    newPet[e.prop] = value;

    this.setState({ newPet });
  };

  hasErrors = () => {
    const { errors } = this.state;

    for (var error in errors) {
      if (errors[error] && errors[error].length) {
        return true;
      }
    }
    return false;
  };

  addPet = async e => {
    this.notificationPopupRef.current.closeClicked();

    const { pets, newPet, errors, validations } = this.state;
    e.preventDefault();

    errors['birthDate'] = validator.validateBirthDate(newPet.birthDate, validations);
    errors['name'] = validator.validateNameBeforeSubmit(newPet.name, pets, validations);

    this.setState({ errors })

    if (!this.hasErrors()) {

      const result = (await fetcherApi.put(urlTools.constructRoute("api/profile/Clubs/PetsClub/" + this.props.initialData.legalEntityId + "/pets"), {
        name: newPet.name,
        birthDate: newPet.birthDate,
        petCodeId: newPet.petTypeId
      })).response.data;
      if (result.success) {
        let joined = pets.concat(
          result.resultObject
        );
        this.setState({ pets: joined, newPet: { petTypeId: result.resultObject.petCodeId } })
        this.notificationPopupRef.current.showPopup(constants.DATA_SAVED);
      }
      else if (result.error === "maxPetsReached") {
        errors['name'] = ["pets_club_page_max_pets_error"];
        this.setState({ errors });
      }
    }
  };

  onDropDownChange = e => {
    const { newPet } = this.state;
    newPet.petTypeId = e.target.value;
    this.setState({ newPet });
  };

  createSelectItems() {
    const petTypesArray = this.state.petTypesTexts;
    let items = [];
    for (let i = 0; i < petTypesArray.length; i++) {
      let label = petTypesArray[i].value;
      let localizationKey = this.getPetTypesTranslation(label);
      let key = petTypesArray[i].key;
      items.push(<option key={label} value={key}>{i18n.t(localizationKey)}</option>);
    }
    return items;
  }

  getPetTypesTranslation(label) {
    let translationKey = '';
    switch (label) {
      case 'Dog':
        translationKey = 'pets_club_page_dog_type';
        break;
      case 'Cat':
        translationKey = 'pets_club_page_cat_type';
        break;
      default:
        translationKey = 'pets_club_page_other_type';
        break;
    }
    return translationKey;
  }

  onRemovePet = async (e) => {

    const { pets } = this.state;
    const id = parseInt(e);

    await fetcherApi.delete(urlTools.constructRoute("api/profile/Clubs/PetsClub/" + this.props.initialData.legalEntityId + "/pets/" + id));

    var joined = pets.filter(function (pet) {
      return pet.id !== id
    });
    this.setState({ pets: joined, errors: {}, showConfirmPopup: false });

  };

  showRemovalConfirmation = async (id) => {
    this.setState({ petId: id });
    this.showConfirmPopup(event);
  }

  render() {
    const { newPet, pets, errors, petTypesTexts, language } = this.state;
    const children = [];
    if (pets !== null) {
      for (var i = 0; i < pets.length; i += 1) {
        children.push(<ChildComponent
          key={i}
          rowNumber={i}
          pet={pets[i]}
          language={language}
          errors={errors}
          getPetTypeText={this.findArrayElementByTitle.bind(this)}
          petTypeTexts={petTypesTexts}
          removePet={this.onRemovePet}
          petId={this.state.petId}
          showConfirmPopup={this.showConfirmPopup}
          showRemovalConfirmation={this.showRemovalConfirmation} />);
      };
    }

    return pets === null ? (<Spinner />)
      : (
        <React.Fragment>
          <div className="container page-container club-page">
            <div>
              {(this.state.showPopup) ?
                <ConfirmationModalDialog
                  titleText={"pets_club_popup_page_are_you_sure"}
                  bodyText={"pets_club_popup_page_info"}
                  yesButtonText={"pets_club_popup_page_yes_btn"}
                  noButtonText={"pets_club_popup_page_no_btn"}
                  onYesButtonClick={this.leaveClub.bind(this)}
                  hidePopup={this.hidePopup.bind(this)} />
                : null}
            </div>

            {(this.state.showConfirmPopup) ?
              <div>
                <ConfirmationModalDialog
                  titleText={"remove_pet_popup_title"}
                  bodyText={"remove_pet_popup_info"}
                  yesButtonText={"remove_pet_popup_yes"}
                  noButtonText={"remove_pet_popup_no"}
                  onYesButtonClick={() => this.onRemovePet(this.state.petId)}
                  hidePopup={() => this.hideConfirmPopup(event)} />
              </div>
              : <div></div>}


            <Link className="link-button -with-left-icon" to={{ pathname: '/clubs' }}><svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m15 6-6 6 6 6" fill="none" stroke="#a12971" strokeWidth="2" /></svg>
              <TranslationText text='back_to_clubs_btn_label' />
            </Link>
            <AutoHideableTitle text={'pets_club_page_title'} />
            <div className="card card-full-width">
              <div className="card-content">
                <div className="club-header">
                  <div className="icon">
                    <img src={require("assets/svg/pet-club.svg").default} alt="pet-club" />
                  </div>
                  <div className="page-description-row">
                    <p>
                      <TranslationText text='pets_club_page_info_title' />
                    </p>
                    <p>
                      <TranslationText text='pets_club_page_info' />
                    </p>
                  </div>
                </div>
                <hr className="no-margin-top" />
                <div className="group-row">
                  <div className="row">
                    <div className="column-desktop-3 column-tablet-6 column-mobile-12">
                      <div className={errors['name'] !== undefined && errors['name'].length > 0 ? "form-field  -has-error" : "form-field"} >
                        <label htmlFor="name" className="form-field__label">
                          <TranslationText text='pets_club_page_name_field_title' />
                        </label>
                        <InputText
                          onChange={this.onChange}
                          prop="name"
                          value={newPet.name}
                          maxLength="20"
                          placeholder={i18n.t('pets_club_page_name_hint')}
                        />
                      </div>
                      <div className="sublabel error-msg show"><span>
                        <TranslationText text={errors.name} />
                      </span></div>
                    </div>
                    <div className="column-desktop-3 column-tablet-6 column-mobile-12">
                      <div className={errors['birthDate'] !== undefined && errors['birthDate'].length > 0 ? "form-field  -has-error" : "form-field"} >
                        <label htmlFor="birth_date" className="form-field__label">
                          <TranslationText text='pets_club_page_birthday_field_title' />
                        </label>
                        <DateInput
                          initialDateString={newPet.birthDate}
                          onChange={this.onBirthDateChange}
                          errorMessage={errors.birthDate}
                        />
                      </div>
                    </div>

                    <div className="column-desktop-3 column-tablet-6 column-mobile-12">
                      <div className="form-field">
                        <label htmlFor="pet_type" className="form-field__label">
                          <TranslationText text='pets_club_page_type_title' />
                        </label>
                        <div className="form-field__select">
                          <select value={newPet.petTypeId} onChange={this.onDropDownChange} name="pet_type" id="pet_type" placeholder={i18n.t('pets_club_page_type_title')}>
                            {this.createSelectItems()}
                          </select>
                          <svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m22.7 12.7-6.7 6.6-6.7-6.6" fill="none" stroke="#a12971" strokeMiterlimit="10" strokeWidth="2" /></svg>                            </div>
                      </div>
                    </div>
                    <div className="column-desktop-3 column-tablet-12">
                      <button
                        type="submit"
                        onClick={this.addPet}
                        disabled={this.hasErrors()}
                        className="button full-width"
                      >
                        <TranslationText text='pets_club_page_add_btn' />
                      </button>
                      <NotificationSuccess ref={this.notificationPopupRef}></NotificationSuccess>
                    </div>
                  </div>
                </div>
                <hr />
                <ParentComponent>
                  {children}
                </ParentComponent>
                {pets.length > 0 ? (<React.Fragment>
                  <hr />
                  <div className="leave-club">
                    <p>
                      <a onClick={this.showPopup} href="#">
                        <TranslationText text='pets_club_page_leave_club_btn' />
                      </a>
                    </p>
                    <p>
                      <TranslationText text='pets_club_page_leave_club_info' />
                    </p>
                  </div>
                </React.Fragment>
                ) : (<React.Fragment></React.Fragment>)}
              </div>
            </div>
          </div>
          <Footer countrySelectionEnabled={false} />
        </React.Fragment >
      )
  }
}

export default withTranslation()(PetsClub);

const ParentComponent = props => (

  <div className="household-members group-row">
    <div className="row">
      <div className="column-desktop-12">
        {props.children}
      </div>
    </div>
  </div>
);

const ChildComponent = props =>
  <>
    <div className="row padded-row">
      <div className="column-desktop-3 column-tablet-6 column-mobile-12">
        <div className="form-field">
          <label htmlFor="member1" className="form-field__label">
            <TranslationText text='pets_club_page_name_field_title' />
          </label>
          <input
            style={{ color: '#878380', cursor: 'default' }}
            className="form-field__input"
            type="text"
            name="status_member2"
            id="status_member2"
            value={props.pet.name}
            disabled
            readOnly
          />
        </div>
      </div>
      <div className="column-desktop-3 column-tablet-6 column-mobile-12">
        <div className="form-field date-input">
          <label htmlFor="status_member2" className="form-field__label">
            <TranslationText text='pets_club_page_birthday_field_title' />
          </label>
          <DateInput
            initialDateString={props.pet.birthDate}
            readOnly={true}
          />
        </div>
      </div>
      <div className="column-desktop-3 column-tablet-12">
        <div className="form-field">
          <label htmlFor="pet_type1" className="form-field__label">
            <TranslationText text='pets_club_page_type_title' />
          </label>
          <input
            style={{ color: '#878380', cursor: 'default' }}
            className="form-field__input"
            type="text"
            name="pet_type1"
            id="pet_type1"
            value={props.getPetTypeText(props.petTypeTexts, parseInt(props.pet.petCodeId))}
            disabled
            readOnly
          />
        </div>
      </div>
      <div className="column-desktop-3 column-tablet-12">

        <button
          type="button"
          id={props.pet.id}
          onClick={() => props.showRemovalConfirmation(props.pet.id)}
          value={props.rowNumber}
          className="button -secondary full-width"
        >
          <TranslationText text='pets_club_page_remove_btn' />
        </button>

      </div>
    </div>
  </>
