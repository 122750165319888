import AppContent from "components/AppContent";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom";
import "assets/css/style.css";
import "assets/css/fonts.css";
import "assets/css/cookie_bot.css";
import "assets/js/cookie_bot.js";

export default function App() {
    return (
        <BrowserRouter basename="/spa">
            <AppContent />
        </BrowserRouter>
    );
}