import LegalEntityFunctions from "./LegalEntityFunctions.jsx";

export default {
  getNavigateBackUrl(initialData) {
    return initialData.referer ?? LegalEntityFunctions.getRedirectToWebLink(initialData);
  },

  getNavigateBackLocalizationText(initialData) {
    const referer = initialData.referer;
    if (!referer) {
      return "go_to_rimi_web_page";
    }

    const eShopUrls = [
      initialData.ecomBaseUrlLt,
      initialData.ecomBaseUrlLv,
      initialData.ecomBaseUrlEe
    ];

    const userCameFromEshop = eShopUrls.some(baseUrl => referer.toLowerCase().startsWith(baseUrl));
    if (userCameFromEshop) {
      return "return_to_ecom_page";
    }

    return "return_to_rimi_web_page";
  }
}