import React, { Component } from "react";
import TranslationText from "./Translation/TranslationText.jsx";

class Success extends Component {

  hideSuccess = () => {
    this.props.hideSuccess();
  }

  render() {
    return <React.Fragment>
      <div className="modal-container show">
        <div className="modal-backdrop"></div>
        <div className="modal-window-scrollspace">
          <div className="modal-window modal-show" id="error-modal">
            <div className="modal-content">
              <div className="single-message">
                <div className="icon">
                  <svg viewBox="0 0 214.96 214.96" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="#75af27" strokeMiterlimit="10" strokeWidth="7"><circle cx="107.48" cy="107.48" r="103.98" /><path d="m151.67 63-47.98 83.9-41.47-41.67" /></g></svg>                </div>
                <h2>
                  <b>
                    <TranslationText text='my_rimi_group_popup_title' />
                  </b>
                </h2>
                <p>
                  <TranslationText text='my_rimi_group_popup_info' />
                </p>
              </div>
              <div className="form-button full-width">
                <a onClick={this.hideSuccess} href="#" className="modal-close button">
                  <span>
                    <TranslationText text='my_rimi_group_popup_btn' />
                  </span>
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  }
}
export default Success;