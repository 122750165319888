import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import InputText from "../../components/InputText.jsx";
import Footer from "../../components/Footer.jsx";
import ConsentLinks from '../../components/Consents/ConsentLinks.jsx';
import * as constants from "../../constants/constants.jsx"
import TranslationText from "../../components/Translation/TranslationText.jsx";
import { Trans, withTranslation } from 'react-i18next';
import RimiLogoHeaderButton from "../../components/HeaderComponents/RimiLogoHeaderButton.jsx";
import StepBackHeader from "../../components/HeaderComponents/StepBackHeader.jsx";
import AutoHideableHeader from "../../components/HeaderComponents/AutoHideableHeader.jsx";
import CardReplacementValidation from '../../../components/components/CardReplacementValidation.jsx';
import urlTools from "../../tools/urlTools.jsx";
import * as cardStatusCodes from "../../constants/cardStatusCodes.jsx";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

class CardReplacementLogin extends Component {
  _isMounted = false;
  state = {
    card: {
      cardPrefix: "",
      cardNumber: ""
    },
    errors: [],
    confirmed: false,
    cardStatus: undefined,
    beginRecaptchaValidation: undefined
  };

  componentDidMount() {
    const { card } = this.state;
    const selectedCountry = localStorage.getItem(constants.SELECTED_COUNTRY);
    card.cardPrefix = constants.CARD_NUMBER_PREFIX_START + this.props.initialData.cardPrefixes.find(cp => cp.key === selectedCountry).value;

    this._isMounted = true;
    this.setState({ card });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState = (state, callback) => {
      return;
    }
  }

  onChange = e => {
    const { card, errors } = this.state;
    errors['onConfirm'] = [];
    card[e.prop] = e.value.replace(/[^0-9]/g, '');
    if (this._isMounted) {
      this.setState({ card, errors });
    }
  };

  beginRecaptchaValidation = () => {
    this.setState({ beginRecaptchaValidation: !this.state.beginRecaptchaValidation });
  }

  completeRecaptchaValidation = (recaptchaToken) => {
    this.confirm(recaptchaToken);
  }

  confirm = async recaptchaToken => {
    const { card, errors } = this.state;
    const validationResult = await CardReplacementValidation.validateCardReplacement(card, urlTools.constructRoute("api/Account/CardReplacement/"), recaptchaToken);
    if (validationResult.isValid) {
      this.setState({ confirmed: true, cardStatus: validationResult.cardStatus });
    } else {
      errors['onConfirm'] = validationResult.error;
      this.setState({ confirmed: false, confirmText: "try_again_button_label", activationCode: { code1: "0", code2: "0", code3: "0", code4: "0" } });
    }

    if (this._isMounted) {
      this.setState({ errors });
    }
  };

  render() {

    const { card, errors, confirmed, cardStatus } = this.state;

    return (
      <React.Fragment>
        <div className="container">
          <div className="card">
            <AutoHideableHeader>
              <RimiLogoHeaderButton initialData={this.props.initialData} />
              <StepBackHeader
                pathName={'/login'}
                buttonText={'step_back_button_label'} />
            </AutoHideableHeader>
            <div className="card-content no-padding-bottom">
              <h1>
                <Trans i18nKey='card_replacement_enter_last_digits'>
                  Enter last 8 digits of <b>new My Rimi ID</b>
                </Trans>
              </h1>

              <div className="form-group">
                <div className="form-row rimi-card-back">
                  <img className="rimi-card-back-image" src={require("assets/img/rimi-card-back.jpg")} alt="Mans Rimi" />
                  <div className="card-numbers">
                    <div className="form-col">
                      <div className="form-field">

                        <InputText
                          prop="cardPrefix"
                          className="form-field__input"
                          value={card.cardPrefix}
                          onChange={this.onChange}
                          maxLength="11"
                          type="tel"
                        />
                      </div>
                    </div>
                    <div className="form-col">
                      <div className="form-field">
                        <InputText
                          prop="cardNumber"
                          className="form-field__input"
                          value={card.cardNumber}
                          onChange={this.onChange}
                          maxLength="8"
                          type="tel"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sublabel error-msg show">
                  <span>
                    {
                      (errors.onConfirm !== undefined && errors.onConfirm.length > 0)
                        ? <TranslationText text={errors.onConfirm[0]} />
                        : null
                    }
                  </span>
                </div>

              </div>
              {
                this.state.beginRecaptchaValidation !== undefined
                  ? <GoogleReCaptcha onVerify={this.completeRecaptchaValidation} refreshReCaptcha={this.state.beginRecaptchaValidation} />
                  : null
              }
              <div className="form-button full-width">

                {!confirmed ? (
                  <button
                    type="submit"
                    onClick={this.beginRecaptchaValidation}
                    className="button"
                  >
                    <TranslationText text='confirm_button_label' />
                  </button>
                ) : (
                  cardStatus === cardStatusCodes.PENDING_REG
                    ? <Redirect to={{ pathname: '/AdditionalInformation/' }} />
                    : <Redirect to={{ pathname: '/VerificationCodeLogin/', state: { cardStatus } }} />
                )
                }
              </div>
              <div className="form-button full-width">
                <Link className="button -secondary" to={{ pathname: '/login' }}>
                  <TranslationText text='return_to_login_page' />
                </Link>
              </div>
              <hr className="spacer" />
            </div>
            <footer className="card-footer no-border">
              <div className="footer-text">
                <ConsentLinks />
              </div>
            </footer>
          </div>
        </div>
        <Footer countrySelectionEnabled={false} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(CardReplacementLogin);


