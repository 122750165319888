import React, { Component } from "react";
import * as constants from "../../constants/constants.jsx";
import { Trans, withTranslation } from 'react-i18next';
import i18n from "translations/i18n.jsx";
import legalEntityFunctions from "../LegalEntityFunctions.jsx";
import urlTools from "../../tools/urlTools.jsx";

class ConsentLinks extends Component {
  state = {
    selectedCountry: 'lv',
    translationKey: this.props.translationKey ?? "my_rimi_tc_and_pp_links",
    baseUrl: ""
  }

  async componentDidMount() {
    let selectedCountry = !!this.props.preselectedLegalEntityId ? legalEntityFunctions.getCountryName(this.props.preselectedLegalEntityId)
      : localStorage.getItem(constants.SELECTED_COUNTRY);

    this.setState({ selectedCountry, baseUrl: urlTools.getBaseUrl() });
    window.addEventListener(constants.COUNTRY_CHANGED, this.setCountry);
  }

  setCountry = () => {
    this.setState({ selectedCountry: localStorage.getItem(constants.SELECTED_COUNTRY) });
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    }
    window.removeEventListener(constants.COUNTRY_CHANGED, this.setCountry);
  }

  getPrivacyPolicyUrl() {
    return this.state.baseUrl + 'api/react/GetPrivacyPolicy?selectedCountry=' + this.state.selectedCountry + '&selectedLanguage=' + i18n.t('current_language_code') + "&business=" + !!this.props.business;
  }

  getTermsAndConditionsUrl() {
    return this.state.baseUrl + 'api/react/GetTermsAndConditions?selectedCountry=' + this.state.selectedCountry + '&selectedLanguage=' + i18n.t('current_language_code') + "&business=" + !!this.props.business;
  }

  render() {
    return (
      <p className="p">
        <Trans i18nKey={this.state.translationKey}>
          “My Rimi” Loyalty  program
          <a href={this.getPrivacyPolicyUrl()} target="_blank">Privacy Policy</a>
          and
          <a href={this.getTermsAndConditionsUrl()} target="_blank">Terms and Conditions</a>
        </Trans>
      </p>
    )
  }
}
export default withTranslation()(ConsentLinks);


