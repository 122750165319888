import urlTools from "components/tools/urlTools";
import React from 'react';
import { Route } from "react-router-dom";

const ConsentsRoute = props => (
  <Route
    path={props.path}
    component={() =>
      props.initialData.isConsentsFlow
        ? props.component
        : urlTools.getDefaultRedirect(props.initialData)
    }
  />
)

export default ConsentsRoute;