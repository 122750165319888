import React, { Component } from "react";
import { Link } from "react-router-dom";
import InputText from "../components/InputText.jsx";
import fetcherApi from '../components/FetcherApi.jsx';
import Footer from "../components/Footer.jsx";
import LegalEntityFunctions from "../components/LegalEntityFunctions.jsx";
import ConsentLinks from "../components/Consents/ConsentLinks.jsx";
import TryAgainFooterButtons from "../../components/components/Buttons/TryAgainFooterButtons.jsx";
import SendVerificationCode from "../components/Buttons/SendVerificationCode.jsx";
import * as constants from "../constants/constants.jsx";
import TranslationText from "../components/Translation/TranslationText.jsx";
import { Trans, withTranslation } from "react-i18next";
import EmailAndPhoneValidator from '../components/EmailAndPhoneValidator.jsx';
import RimiLogoHeaderButton from "../components/HeaderComponents/RimiLogoHeaderButton.jsx";
import AutoHideableHeader from "../components/HeaderComponents/AutoHideableHeader.jsx";
import StepBackHeader from "../components/HeaderComponents/StepBackHeader.jsx";
import validator from '../components/FieldValidator.jsx';
import urlTools from "../tools/urlTools.jsx";
import { withRouter } from "react-router-dom/cjs/react-router-dom.js";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

class CreateNewB2bAccount extends Component {

	state = {
		error: false,
		email: "",
		errorText: "",
		isProcessing: false,
		beginRecaptchaValidation: undefined
	};

	IsEmailValid() {
		const emailStructureError = EmailAndPhoneValidator.getEmailStructureError(this.state.email);
		if (emailStructureError) {
			this.setState({ error: true, errorText: emailStructureError });
			return false;
		}

		return true;
	}

	onChange = e => {
		if (e.prop === 'email') {
			e.value = validator.blockSpaceSymbol(e.value)
		}

		this.setState({ email: e.value, errors: {} });
	};

	beginRecaptchaValidation = () => {
		this.setState({
			beginRecaptchaValidation: !this.state.beginRecaptchaValidation,
			isProcessing: true
		});
	}

	completeRecaptchaValidation = (recaptchaToken) => {
		this.confirm(recaptchaToken);
	}

	confirm = async (recaptchaToken) => {
		if (this.IsEmailValid()) {
			const emailUnique = await EmailAndPhoneValidator.IsEmailUnique(this.state.email);

			if (!emailUnique.data.success) {
				this.setState({
					error: true, errorText: emailUnique.errorStandardOrRateLimit
				});
			}
			else if (emailUnique.data.resultObject.unque) {
				const url = urlTools.constructRoute("api/B2BRegistrationCode/send-code/" + LegalEntityFunctions.getLegalEntityId(localStorage.getItem(constants.SELECTED_COUNTRY)) + "/" + this.state.email);
				const requestSettings = { recaptchaToken };
				const result = (await fetcherApi.post(url, null, requestSettings)).response;

				if (result.data.success) {
					this.props.history.push("/VerificationCodeLogin", {
						email: this.state.email,
						rateLimitError: "enter_existing_card_page_rate_limit_reached",
						resendUrl: "api/B2BRegistrationCode/resend-code",
						returnUrl: "b2bregistration/CreateNewB2bAccount",
						titleTranslation: "verification_code_login_title",
						infoMessageTranslation: "verification_code_login_info_message",
						didNotReceiveMessageTranslation: "verification_code_did_not_receive_message",
						usesCustomRedirect: true,
						customRedirectRoute: "/b2bregistration/B2bData",
						customCodeConfirmUrl: urlTools.constructRoute("api/B2BRegistrationCode/verify-code")
					});

					return;
				} else {
					if (result.data.error === "verification_resend_limit_reached") {
						this.setState({ error: true, errorText: "create_new_account_page_email_limit_error" });
					} else if (result.data.error === "duplicate_email") {
						this.setState({ error: true, errorText: "create_b2b_account_page_existing_email_field_error" });
					} else {
						this.setState({ error: true, errorText: result.data.error });
					}
				}
			}
			else {
				this.setState({
					error: true, errorText: "create_b2b_account_page_existing_email_field_error"
				});
			}
		}

		this.setState({ isProcessing: false });
	}

	renderButtons() {
		return !this.state.error
			? <SendVerificationCode confirm={this.beginRecaptchaValidation} />
			: <TryAgainFooterButtons
				confirm={this.beginRecaptchaValidation}
				isProcessing={this.state.isProcessing}
				isB2bAccount={true}
			/>
	}

	renderBottom() {
		return !this.state.error
			? (
				<footer className="card-footer no-border">
					<ConsentLinks business={true} />
				</footer>
			)
			: (
				<footer className="card-footer">
					<div className="form-button full-width">
						<Link className="button -secondary" to={{ pathname: '/login' }}>
							<TranslationText text='login_button_label' />
						</Link>
					</div>
					<div className="footer-text">
						<ConsentLinks business={true} />
					</div>
				</footer>
			)
	}

	render() {

		return <React.Fragment>
			<div className="container">
				<div className="card">
					<AutoHideableHeader>
						<RimiLogoHeaderButton initialData={this.props.initialData} />
						<StepBackHeader
							pathName={'/login'}
							buttonText={'step_back_button_label'} />
					</AutoHideableHeader>
					<div className={this.state.error ? ("card-content") : ("card-content no-padding-bottom")}>
						<h1>
							<Trans i18nKey="b2b_email_verified_page_title">
								Create new <b>Business account</b>
							</Trans>
						</h1>
						<p>
							<TranslationText text="create_new_my_rimi_account_page_info" />
						</p>
						<div className="form-group">
							<div className="form-row">
								<div className={this.state.error ? "form-field -has-error" : "form-field"}>
									<label htmlFor="email" className="form-field__label">
										<TranslationText text='email_field_label' />
									</label>
									<InputText
										onChange={this.onChange}
										prop="email"
										value={this.state.email}
										className="form-field__input"
										placeholder="example@gmail.com"
									/>
								</div>
							</div>
							{
								this.state.beginRecaptchaValidation !== undefined
									? <GoogleReCaptcha onVerify={this.completeRecaptchaValidation} refreshReCaptcha={this.state.beginRecaptchaValidation} />
									: null
							}
							<div className="form-row">
								<div className="sublabel error-msg show">
									<span>
										<TranslationText text={this.state.errorText} />
									</span>
								</div>
							</div>
						</div>
						{this.renderButtons()}
					</div>
					{this.renderBottom()}
				</div>
			</div>
			<Footer countrySelectionEnabled={false} />
		</React.Fragment>
	}
}

export default withRouter(withTranslation()(CreateNewB2bAccount));


