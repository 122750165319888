import React, { Component } from "react";
import TranslationText from "../Translation/TranslationText.jsx";

class EmailVerificationSent extends Component {

  render() {
    return <React.Fragment>
      <br />
      <p>
        <TranslationText text='email_change_success_popup_info1' />
      </p>
      <br />
      <p>
        <TranslationText text='email_change_success_popup_info2' />
      </p>
    </React.Fragment>
  }
}

export default EmailVerificationSent;


